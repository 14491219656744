import { Component, Input, OnInit, OnChanges,SimpleChanges, SimpleChange } from '@angular/core';
import { Subscription, Observable } from "rxjs";

@Component({
  selector: 'app-stepper-box-header',
  templateUrl: './stepper-box-header.component.html',
  styleUrls: ['./stepper-box-header.component.scss']
})
export class StepperBoxHeaderComponent implements OnInit {
  selectedValue = 'car';
  items;
  selectedIndex:number;
  public eventsSubscription: Subscription;
  @Input() events: Observable<void>;
  constructor() { }

  ngOnInit(){
    this.eventsSubscription = this.events.subscribe((data) => this.selectData(data));
  }
  selectData(data){
    this.items = data.steps._results;
    this.selectedIndex = data.selectedIndex;
  }
  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }
  public selectHeader(val){
  }
}
