import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';

@Component({
	selector: 'app-quote-summary',
	templateUrl: './quote-summary.component.html',
	styleUrls: ['./quote-summary.component.scss']
})
export class QuoteSummaryComponent implements OnInit {
	@Input() quoteResponseCount: number;
	@Output() idvUpdated = new EventEmitter<number>();
	@Output() newPolicyChanged = new EventEmitter<string>();
	@Output() ownedByChange = new EventEmitter<string>();
	@Input() minIDV:any;
	@Input() maxIDV:any;

	private _crmObj: any;

	@Input()
	set crmObj(value) {
		this._crmObj = value;
	}
	get crmObj() {
		return this._crmObj;
	};

	constructor() {}

	ngOnInit(): void {
	}

	ngOnChanges(changes: SimpleChanges) {
	}

	public onTriggerNewPolicyUpdate(e): void {
		this.newPolicyChanged.emit(e);
	}

	public onTriggerOwnerChange(e): void {
		this.ownedByChange.emit(e)
	}

	public onTriggerIdvUpdate(e): void {
		this.idvUpdated.emit(e)
	}
}
