<div class="row mt-1">
	<mat-horizontal-stepper [linear]="true" labelPosition="bottom" (selectionChange)="emitToBoxHeader($event)"
		class="personal-info-splitter-form" #stepper>
		<ng-template matStepperIcon="number">
		</ng-template>
		<!-- Icon Overrides -->
		<ng-template matStepperIcon="vehicle">
			<mat-icon>commute</mat-icon>
		</ng-template>
		<ng-template matStepperIcon="vehicle">
			<mat-icon>commute</mat-icon>
		</ng-template>
		<ng-template matStepperIcon="policy">
			<mat-icon>insert_drive_file</mat-icon>
		</ng-template>

    <ng-template *ngIf="kycProcessApiFlag != null && !crmObj.kyc.isKycCompleted" matStepperIcon="kyc">
			<mat-icon>file_copy</mat-icon>
		</ng-template>

		<ng-template matStepperIcon="personal">
			<mat-icon>person</mat-icon>
		</ng-template>

    <ng-template matStepperIcon="communication">
			<mat-icon>mail</mat-icon>
		</ng-template>

		<ng-template matStepperIcon="extra">
			<mat-icon>add_to_photos</mat-icon>
		</ng-template>
		<mat-step *ngIf="!crmObj.motorProfile.isNew" state="registration" [completed]="registrationStepCompleted">
			<ng-template matStepLabel>
				<h3>Registration Info</h3>
			</ng-template>
			<app-registration-details [motorProfile]="crmObj.motorProfile"
				(nextButtonClicked)="registrationStepNextClicked($event)"></app-registration-details>
		</mat-step>
		<!--Previous Policy Info-->
		<mat-step *ngIf="!crmObj.motorProfile.isNew" [completed]="policyStepCompleted" state="policy">

			<ng-template matStepLabel>
				<h3>Policy Info</h3>
			</ng-template>
			<app-previous-insurance-details [crmObj]="crmObj"
				(nextButtonClicked)="previousInsuranceDetailsNextClicked($event)"></app-previous-insurance-details>
		</mat-step>
		<!-- vehicle info-->
		<mat-step state="vehicle" [completed]="motorInfoStepCompleted">
			<ng-template matStepLabel>
				<h3>Vehicle Info</h3>
			</ng-template>
			<app-motor-information-details [crmObj]="crmObj" [isRenewal]="isRenewal"
				(nextButtonClicked)="motorInfoStepNextClicked($event)"></app-motor-information-details>
		</mat-step>

    <!-- KYC Info-->
		<mat-step [completed]="kycStepCompleted"  *ngIf="kycProcessApiFlag != null && !crmObj.kyc.isKycCompleted" state="kyc">
			<ng-template matStepLabel>
				<h3>Kyc Info</h3>
			</ng-template>
      <app-kyc-details [crmObj]="crmObj" [kycApiFlag]="kycProcessApiFlag"
      (nextButtonClicked)="kycInfoStepNextClicked($event)">

      </app-kyc-details>
		</mat-step>

		<!-- Personal Info  [kycApiFlag]="kycProcessApiFlag"-->
		<mat-step [completed]="policyHolderStepCompleted" state="personal">
			<div *ngIf="crmObj.policyHolderInfo.policyholderType == ownerTypeList[0]">
				<ng-template matStepLabel>
					<h3>Personal Info</h3>
				</ng-template>
				<app-policy-holder-details [crmObj]="crmObj" (nextButtonClicked)="policyHolderStepNextClicked($event)">
				</app-policy-holder-details>
			</div>
			<div *ngIf="crmObj.policyHolderInfo.policyholderType == ownerTypeList[1]">
				<ng-template matStepLabel>
					<h3>Personal Info</h3>
				</ng-template>
				<app-company-information-details [crmObj]="crmObj"
					(nextButtonClicked)="companyInfoStepNextClicked($event)"></app-company-information-details>
			</div>
		</mat-step>

		<!-- Communication Info-->
		<mat-step [completed]="communicationInfoStepCompleted" state="communication">
			<ng-template matStepLabel>
				<h3>Address Info</h3>
			</ng-template>
			<app-communication-information-details [crmObj]="crmObj"
				(nextButtonClicked)="communicationInfoStepNextClicked($event)"></app-communication-information-details>
		</mat-step>

		<!-- Nominee Info-->
		<mat-step [completed]="extraStepCompleted" state="extra">
			<ng-template matStepLabel>
				<h3>Extra Info</h3>
			</ng-template>
			<app-nominee-information-details [crmObj]="crmObj" [lastStep]="lastStep"
				(nextButtonClicked)="nomineeDetailsStepNextClicked($event)"></app-nominee-information-details>
		</mat-step>

	</mat-horizontal-stepper>
	<div class="col pl-4 pb-4 bg-white" *ngIf="isAllStepsCompleted">
		<div class="row">
			<div class="form-group checkbox-inline" *ngIf="isAddons">
				<mat-checkbox color="primary" (change)="changeCheck($event)">
					Please confirm that you have taken below addons in your previous policy<br />
					<span *ngFor="let addon of addonsConfigList;let i=index">
						<span *ngIf="crmObj.extra.addons[addon]">{{addon}} <br /></span>
					</span>
				</mat-checkbox>
			</div>
			<div class="col">
				<!-- <div class="text-center p-2 invalid-feedback" *ngIf="isBlock">
					<span class="text-danger"><strong>Please confirm the checkbox before proceed</strong></span>
				</div> -->
				<div class="text-center pt-4 pb-4">
					<button class="btn btn-default btn-real btn-selected ml-2" id="purchasePlan" type="submit"
						(click)="onSubmit()" [disabled]="!isCheckBox">
						Fetch Kyc Details and Proceed To Purchase
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
