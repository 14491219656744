<div class="card mt-3">
	<h3 class="card-header text-heading mb-0">Motor Profile</h3>
	<div class="card-body">
		<form [formGroup]="motorInfoFormGroup" class="bg-white personal-info-form-section">
			<div class="row pb-3">
				<div class="col-sm-12 col-lg-6">
					<div class="form-group">
						<label for="engineNo" class="pb-1">Engine No</label>
						<input autocomplete="on" class="form-control" formControlName="engineNo" type="text"
							minlength="12" maxlength="18" placeholder="Enter Engine No" [ngClass]="{
                    'is-invalid': isSubmitted && form.engineNo.errors
                  }" />
						<div *ngIf="isSubmitted && form.engineNo.errors" class="invalid-feedback">
							<span class="text-danger" *ngIf="form.engineNo.errors.required">Engine No Is required</span>
							<span class="text-danger" *ngIf="form.engineNo.errors.pattern">Engine No must be a valid
								pattern</span>
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-lg-6">
					<div class="form-group">
						<label for="chassisNo" class="pb-1">Chassis No</label>
						<input autocomplete="on" class="form-control" formControlName="chassisNo" type="text"
							minlength="12" maxlength="18" placeholder="Enter Chassis No" [ngClass]="{
                    'is-invalid': isSubmitted && form.chassisNo.errors
                  }" />
						<div *ngIf="isSubmitted && form.chassisNo.errors" class="invalid-feedback">
							<span class="text-danger" *ngIf="form.chassisNo.errors.required">Chassis No Is
								required</span>
							<span class="text-danger" *ngIf="form.chassisNo.errors.pattern">Chassis No must be a valid
								pattern</span>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col p-4 mt-3">
					<div class="form-group body-copy-lg">
						<p class="text-center">
              <button class="btn btn-default btn-real" matStepperPrevious>Back</button>
							<button class="btn btn-default btn-real btn-selected ms-4" (click)="onSubmit()"
								id="purchasePlan">
								Next
							</button>
						</p>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
