<app-loader *ngIf="isLoading"></app-loader>
<div class="card">
  <h3 class="card-header text-heading mb-0">Choose Members to Insure</h3>
  <div class="card-body">
    <form (ngSubmit)="onSubmit()" [formGroup]="ageForm">
      <div class="row pb-3">
        <div class="col-12 pb-4">
          <ul class="member-list-comtainer">
            <li *ngFor="let item of memberList">
              <div [ngClass]="{'active':item.isSelected}" class="item-container text-heading"
                (click)="toggleSelection(item)">
                <i class="fa {{item.icon}} " aria-hidden="true"></i>
                <p class="m-0">{{item.title}} </p>
              </div>
              <div class="add-more" *ngIf="item.isSelected && (item.title === daughter || item.title === son)">
                <button type="button" class="btn btn-real btn-selected"
                  (click)="incrementDecrement('DEC',item)">-</button>
                <!-- <input class="form-control" readonly type="number" [(ngModel)]="item.total"
                  [ngModelOptions]="{standalone: true}" /> -->
                <span class="total-item">{{item.total}}</span>
                <!--[(ngModel)]="item.total"-->
                <button type="button" class="btn btn-real btn-selected"
                  (click)="incrementDecrement('INC',item)">+</button>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-12 text-center">
          <p *ngIf="isSubmitted && ageForm['controls'].insurerInfo.errors" class="text-bold text-danger">
            Please choose at least one member to proceed</p>
        </div>
        <div class="col-12 py-4 text-center">
          <!-- <button (click)="goBack()" class="btn btn-default btn-real mx-2">Previous</button> -->
          <button matStepperPrevious class="btn btn-default btn-real mx-2">Previous</button>
          <button class="btn btn-default btn-real btn-selected">Next</button>
        </div>
      </div>
    </form>
  </div>
</div>