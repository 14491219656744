import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  productType
} from "src/app/features/policy-config";

@Component({
  selector: 'app-insurance-type',
  templateUrl: './insurance-type.component.html',
  styleUrls: ['./insurance-type.component.scss']
})
export class InsuranceTypeComponent implements OnInit {
  // public isLoading:boolean = true;
  @Output() selectedInsurance = new EventEmitter<any>();
  public selectedValue: string = "";
  public productTypeObj: Array<any> = productType;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.selectedValue = data.insurance;
      this.selectedInsurance.emit(this.selectedValue);
      // setTimeout(() => {
      //   this.isLoading = false;
      // },100)
      // this.storeProductType();
    });
  }

  // storeProductType() {
  //   sessionStorage.setItem("productType", this.selectedValue);
  // }

}
