import { Injectable } from "@angular/core";
import { Leads } from "src/app/features/interfaces/leads";
import { ApiService } from "src/app/core/services/api.service";
import * as moment from 'moment';
import { LoggerService } from "src/app/core/logger/logger.service";
import {
	insuranceDuration, ncbPerArr, leadStatus, leadSource, productTypeObj, ownerTypeArr, policyTypeArr, SAODCUTOFDATE, RISKSTARTDATEONBREAKIN
} from "src/app/features/policy-config";
import {
	leadMotorProfileProperty, leadPolicyHolderProperty, leadCommonProperty, leadNewPolicyProperty, leadNcbProperty, leadCpaProperty, leadExtraProperty, leadNomineeProperty, leadPrePolicyProperty, leadTpPolicyProperty, leadPospProperty, LeadResponse, leadAddressProperty, leadPaymentProperty, leadKycProperty, leadInsurerProperty
} from "src/app/features/interfaces/leadResponse";

import { OrderModel } from "src/app/features/interfaces/order-model";

@Injectable({
	providedIn: "root",
})
export class CrmFlowService {
	insuranceDurationObj: any = insuranceDuration;
	leadStatusObj: any = leadStatus;
	leadSourceObj: any = leadSource;
	public todayDate: Date = new Date();

	public leadReq: Leads = {
		commonFields: null,
		policyHolderInfo: null,
    insurerInfo: null,
		newPolicyInfo: null,
		prePolicyInfo: null,
		motorProfile: null,
		ncbInfo: null,
		tpPolicyInfo: null,
		cpaInfo: null,
		nomineeInfo: null,
		address: null,
		pospInfo: null,
		paymentInfo: null,
		extra: null,
    kyc: null,
	}

	public orderData: OrderModel = {
		name: null,
		email: null,
		contact: null,
		make: null,
		model: null,
		variant: null,
		registrationNo: null,
		registrationDate: null,
		engineNo: null,
		chassisNo: null,
		fuel: null,
		cc: null,
		gvw: null,
		policyNo: null,
		proposalNo: null,
		crmId: null,
		pospId: null,
		agencyId: null,
		policyStartDate: null,
		policyEndDate: null,
		orderDate: null,
		productName: null,
		providerName: null,
		odPremium: null,
		tpPremium: null,
		paPremium: null,
		addonPremium: null,
		netPremium: null,
		tax: null,
		grossPremium: null
	}

	public motorProfile: leadMotorProfileProperty = {
		isNew: null,
		registrationNo: null,
		rtoLoc: null,
		make: null,
		model: null,
		variant: null,
		fuelType: null,
		cc: null,
		vehicleId: null,
		manufactureDate: null,
		purchaseDate: null,
		registrationDate: null,
		permitType: null,
		engineNo: null,
		chassisNo: null,
		isOwnerChanged: false
	}

	public ncbInfo: leadNcbProperty = {
		isNcb: true,
		currentNcb: "0.00",
		newNcb: "0.00",
	}

	public personalInfo: leadPolicyHolderProperty = {
		policyholderType: null,
		salutation: null,
		firstName: null,
		lastName: null,
		email: null,
		mobile: null,
		dob: null,
		gender: null,
		maritalStatus: null,
		companyName: null,
		companyGstNo: null,
    companyCin: null,
		panNo: null,
    aadhaarNo: null
	}

	public commonItems: leadCommonProperty = {
		leadSource: this.leadSourceObj.application,
		leadStatus: this.leadStatusObj.leadCreated,
		productType: null,
		productSubType: null,
	}

	public newPolicyItems: leadNewPolicyProperty = {
		insuranceProvider: null,
		newPolicyType: null,
		quoteId: null,
		idv: null,
		pincode: null,
		proposalNo: null,
		policyNo: null,
		riskStartDate: null,
		riskEndDate: null,
		isBreakin: false,
		isInspection: false,
		inspectionPin: null,
		idvType: null,
		workFlowId: null
	}

	public prePolicyObj: leadPrePolicyProperty = {
		isPreviousPolicy: true,
		policyType: null,
		riskStartDate: null,
		riskEndDate: null,
		insurerName: null,
		policyNo: null,
	}

  public insurerInfo: leadInsurerProperty = {
    age: null,
    gender: null,
    relationship: null,
    uniqueIdentifier: null
  }

	public leadExtra: leadExtraProperty = {
		addons: {
			zeroDepreciation: false,
			roadsideAssistance: false,
			engineProtection: false,
			ncbProtector: false,
			keyReplacement: false,
			consumables: false,
			dailyAllowance: false,
			returnToInvoice: false,
			tyreProtection: false,
			lossOfPersonalBelongings: false,
			rimProtection: false,
			electricalAccessories: "",
			nonElectricalAccessories: "",
			externalCng: "",
			paidDriver: "",
			unnamedPassenger: "",
			aaieDiscount: false,
			araiApproval: false,
			tppdDiscount: false,
			voluntaryDiscount: ""
		},
		quotationLink: "",
		reqPayload: {},
		respPayload: {},
		financierName: "",
		paymentLink: "",
		expiryTime: "",
		premiumBreakup: {},
		financierCity: "",
		financierPincode: "",
		isFinanced: false,
		aaimName: "",
		membershipNo: "",
		membershipExpiryDate: "",
		proposalExpiryDate: ""
	}

	public leadTpPolicy: leadTpPolicyProperty = {
		insurerName: "",
		policyNo: "",
		riskStartDate: "",
		riskEndDate: "",
	}

	public leadCpaPolicy: leadCpaProperty = {
		isCpa: true,
		isEcpa: false,
		isDl: true,
		insurerName: "",
		policyNo: "",
		riskStartDate: "",
		riskEndDate: ""
	}

  public leadAddress: leadAddressProperty = {
    sameAsRegisteredAddress: false,
    communication: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      pincode: "",
      country: ""
    },
    registered: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      pincode: "",
      country: ""
    }
  }

  public leadPaymentProfile: leadPaymentProperty = {
    odPremium: "",
    tpPremium: "",
    paPremium: "",
    addonsPremium: "",
    netPremium: "",
    taxAmount: "",
    grossPremium: "",
    paymentStatus: "",
    paymentResponse: ""
  }

  public leadNomineeProperty: leadNomineeProperty = {
    name: "",
    age: "",
    relation: "",
  }

  public leadPospProperty: leadPospProperty = {
    name: "",
    emailId: "",
    contact: "",
    uniqueId: "",
    aadharNo: "",
    panNo: "",
  }

  public leadKycProperty: leadKycProperty = {
    isKycCompleted: false,
    kycId: "",
    kycDocType:"",
    kycDocNumber:"",
    kycUrl: "",
    kycApiResponse: ""
  }

	constructor(
		private apiService: ApiService,
		private logger: LoggerService
	) { }

	public createLeadPayload(iptObj) {
		this.commonItems.productType = iptObj.productType;
    this.commonItems.productSubType = iptObj.productSubType;
    if(this.commonItems.productType != "health"){
      this.motorProfile.isNew = iptObj.isNew;
      this.motorProfile.make = iptObj.make;
      this.motorProfile.model = iptObj.model;
      this.motorProfile.variant = iptObj.variant;
      this.motorProfile.fuelType = iptObj.engine;
      this.motorProfile.registrationDate = this.setRegistrationDate(iptObj.registrationDate);
      this.motorProfile.cc = iptObj.cc;
      this.motorProfile.purchaseDate = this.motorProfile.registrationDate;
      this.motorProfile.manufactureDate = this.motorProfile.registrationDate;
      this.motorProfile.rtoLoc = this.setRtoCode(iptObj.rto);
      this.motorProfile.registrationNo = this.setNewRegistrationNo();
      this.motorProfile.isOwnerChanged = false;
      this.personalInfo.policyholderType = ownerTypeArr[0];
      this.setTpPolicyDates();
    }else{
      this.personalInfo.gender = iptObj.gender.toLowerCase()
    }

		this.personalInfo.email = iptObj.email.toLowerCase();
		this.personalInfo.mobile = iptObj.mobile;
		this.personalInfo.policyholderType = ownerTypeArr[0];
		this.setPreviousPolicyObject();
		this.setNewPolicyTypeObj();
		this.setNcbValues();
		this.leadReq.commonFields = this.commonItems;
    this.leadReq.policyHolderInfo = this.personalInfo;
    this.leadReq.newPolicyInfo = this.newPolicyItems;
		this.leadReq.motorProfile = this.motorProfile;
		this.leadReq.extra = this.leadExtra;
		this.leadReq.tpPolicyInfo = this.leadTpPolicy;
		this.leadReq.ncbInfo = this.ncbInfo;
		this.leadReq.cpaInfo = this.leadCpaPolicy;
    this.leadReq.address = this.leadAddress;
    this.leadReq.paymentInfo = this.leadPaymentProfile;
    this.leadReq.nomineeInfo = this.leadNomineeProperty;
    this.leadReq.pospInfo = this.leadPospProperty;
    this.leadReq.kyc = this.leadKycProperty;
    return this.leadReq;
	}

  public setInsuredDetails(): void {
    this.insurerInfo.relationship = null;
    this.insurerInfo.age = null;
    this.insurerInfo.uniqueIdentifier = null;
  }

	public setNcbValues(): void {
    if(this.commonItems.productType != "health"){
      if (!this.motorProfile.isNew) {
        let vehicleAge = Math.round(this.yearCountFrmDate(this.motorProfile.registrationDate, this.prePolicyObj.riskEndDate));
        vehicleAge = (vehicleAge > 6) ? 6 : vehicleAge;
        this.ncbInfo.isNcb = true;
        this.ncbInfo.currentNcb = ncbPerArr[vehicleAge - 1];
        this.ncbInfo.newNcb = this.calculateNewNcb(this.ncbInfo.currentNcb)
      }
    }else{
      this.ncbInfo.isNcb = false;
      this.ncbInfo.currentNcb = "0.00";
      this.ncbInfo.newNcb = "0.00";
    }
	}

	public setNewRegistrationNo() {
		if (this.motorProfile.isNew) {
			return `${this.motorProfile.rtoLoc}`
		} else {
			return `${this.motorProfile.rtoLoc}-${this.makeDummyRegistration()}`
		}
	}

	public setRegistrationDate(regDate) {
		if (this.motorProfile.isNew) {
			regDate = this.todayDate;
		}
		return this.convertDateFormat(regDate)
	}

	public updateQuotationDataToLead(crmObj: any, inputObj: any) {
    const quoteId = inputObj.quotationId.toString();
		const quotationIdList = quoteId.split("-");
		this.calculateAndUpdateRiskStartDate(crmObj, inputObj.providerName);
		crmObj["newPolicyInfo"]["riskStartDate"] = this.newPolicyItems.riskStartDate;
		crmObj["newPolicyInfo"]["riskEndDate"] = this.newPolicyItems.riskEndDate;
		crmObj["newPolicyInfo"]["insuranceProvider"] = inputObj.providerName;
		crmObj["newPolicyInfo"]["quoteId"] = quotationIdList[0];
		crmObj["newPolicyInfo"]["workFlowId"] = (quotationIdList[1]) ? quotationIdList[1] : null;
		crmObj["newPolicyInfo"]["idv"] = inputObj.vehicleIDV.defaultValue;
		crmObj["motorProfile"]["vehicleId"] = inputObj.motorProfile.vehicleId;
		crmObj["paymentInfo"]["odPremium"] = inputObj.premiumBreakup.ownDamageCover;
		crmObj["paymentInfo"]["tpPremium"] = inputObj.premiumBreakup.thirdPartyCover;
		crmObj["paymentInfo"]["paPremium"] = inputObj.premiumBreakup.paCover;
		crmObj["paymentInfo"]["addonsPremium"] = "0.00";
		crmObj["paymentInfo"]["netPremium"] = inputObj.netPremium;
		crmObj["paymentInfo"]["taxAmount"] = inputObj.totalTax;
		crmObj["paymentInfo"]["grossPremium"] = inputObj.grossPremium;
		crmObj["commonFields"]["leadStatus"] = leadStatus.quoteSelected;
		if (crmObj["newPolicyInfo"]["newPolicyType"] == policyTypeArr[2]) {
			crmObj["extra"]["addons"]["zeroDepreciation"] = false;
			crmObj["extra"]["addons"]["roadsideAssistance"] = false;
			crmObj["extra"]["addons"]["engineProtection"] = false;
			crmObj["extra"]["addons"]["ncbProtector"] = false;
			crmObj["extra"]["addons"]["keyReplacement"] = false;
			crmObj["extra"]["addons"]["consumables"] = false;
			crmObj["extra"]["addons"]["dailyAllowance"] = false;
			crmObj["extra"]["addons"]["returnToInvoice"] = false;
			crmObj["extra"]["addons"]["tyreProtection"] = false;
			crmObj["extra"]["addons"]["lossOfPersonalBelongings"] = false;
			crmObj["extra"]["addons"]["rimProtection"] = false;
			crmObj["extra"]["addons"]["electricalAccessories"] = null;
			crmObj["extra"]["addons"]["nonElectricalAccessories"] = null;
			crmObj["extra"]["addons"]["aaieDiscount"] = false;
			crmObj["extra"]["addons"]["araiApproval"] = false;
			crmObj["extra"]["addons"]["voluntaryDiscount"] = null;
		}
		return crmObj;
	}

	public calculateAndUpdateRiskStartDate(crmObj, providerName): void {
		if (crmObj.newPolicyInfo.newPolicyType == policyTypeArr[2] && crmObj.newPolicyInfo.isBreakin) {
			const riskStartDate = RISKSTARTDATEONBREAKIN[providerName]
			this.newPolicyItems.riskStartDate = this.formatDate(moment().add(riskStartDate, "d"));
			this.newPolicyItems.riskEndDate = this.formatDate(moment(this.newPolicyItems.riskStartDate).add(1, 'y').subtract(1, 'd'))
		} else {
			this.newPolicyItems.riskStartDate = crmObj.newPolicyInfo.riskStartDate;
			this.newPolicyItems.riskEndDate = crmObj.newPolicyInfo.riskEndDate;
		}
	}

	public calculateNewNcb(inputVal, isNcb = true) {
		if (!isNcb) {
			return ncbPerArr[0];
		} else {
			const preNcbIndex = ncbPerArr.indexOf(inputVal);
			const nextIndex = ncbPerArr.length == (preNcbIndex + 1) ? preNcbIndex : (preNcbIndex + 1);
			return ncbPerArr[nextIndex];
		}
	}

	// public setNewBusinessFlag() {
	// 	const isNewFlag = sessionStorage.getItem("isNewVehicle");
	// 	return (isNewFlag == "true") ? true : false
	// }

	public setPreviousPolicyObject() {
		if (!this.motorProfile.isNew) {
			this.setPreviousPolicyExpiryDate(null)
			this.setPreviousPolicyStartDate(null)
			this.setPreviousInsurerName(null)
			this.setPreviousPolicyNo(null)
			this.setPreviousPolicyType(null)
		}
		this.leadReq.prePolicyInfo = this.prePolicyObj;
	}

	public setPreviousPolicyExpiryDate(inputExpiryDate: any): void {
		const dateInput = (inputExpiryDate == null) ? this.todayDate : new Date(inputExpiryDate);
		this.prePolicyObj.riskEndDate = this.formatDate(dateInput);
	}

	public setPreviousPolicyExpiryDateOnPolicyExpired(days = 50): void {
		this.prePolicyObj.riskEndDate = this.formatDate(moment(this.todayDate).subtract(days, 'd'));
	}

	public setPreviousPolicyStartDateOnPolicyExpired(): void {
		this.setPreviousPolicyStartDate(this.prePolicyObj.riskEndDate);
	}

	public setPreviousPolicyStartDate(inputExpiryDate: any): void {
		const dateInput = (inputExpiryDate == null) ? this.todayDate : new Date(inputExpiryDate);
		this.prePolicyObj.riskStartDate = this.formatDate(moment(dateInput).subtract(1, "y").add(1, "d"));
	}

	public setPreviousInsurerName(insurerName: string): void {
		this.prePolicyObj.insurerName = insurerName;
	}

	public setPreviousPolicyNo(inputPolicyNo: string): void {
		this.prePolicyObj.policyNo = inputPolicyNo;
	}

	public setPreviousPolicyType(inputPolicyType: string): void {
		this.prePolicyObj.policyType = (inputPolicyType == null) ? policyTypeArr[0] : inputPolicyType;
	}

	public setNewPolicyTypeObj(): void {
		this.setNewPolicyType(null)
		this.setRiskStartDate();
		this.setRiskEndDate();
	}

	public setNewPolicyType(inputVal): void {
		if (this.motorProfile.isNew) {
			this.newPolicyItems.newPolicyType = policyTypeArr[0];
		} else if (this.commonItems.productType.toLowerCase() == productTypeObj.cv) {
			this.newPolicyItems.newPolicyType = (inputVal == null) ? policyTypeArr[2] : inputVal;
		} else if (this.motorProfile.registrationDate < SAODCUTOFDATE) {
			this.newPolicyItems.newPolicyType = (inputVal == null) ? policyTypeArr[2] : inputVal;
		} else {
			const vehicleAge = this.yearCountFrmDate(this.motorProfile.registrationDate, this.prePolicyObj.riskEndDate);
			if (this.commonItems.productType.toLowerCase() == productTypeObj.car && vehicleAge >= 3) {
				this.newPolicyItems.newPolicyType = (inputVal == null) ? policyTypeArr[2] : inputVal;
			} else if (this.commonItems.productType.toLowerCase() == productTypeObj.bike && vehicleAge >= 5) {
				this.newPolicyItems.newPolicyType = (inputVal == null) ? policyTypeArr[2] : inputVal;
			} else {
				this.newPolicyItems.newPolicyType = policyTypeArr[1];
			}
		}
	}

	public setNewPolicyTypeOnRegistrationDateChange(crmObj): void {
		if (crmObj.motorProfile.isNew) {
			this.newPolicyItems.newPolicyType = policyTypeArr[0];
		} else if (crmObj.commonFields.productType.toLowerCase() == productTypeObj.cv) {
			this.newPolicyItems.newPolicyType = (crmObj.newPolicyInfo.newPolicyType == policyTypeArr[1]) ? 'Comprehensive' : crmObj.newPolicyInfo.newPolicyType;
		} else if (crmObj.motorProfile.registrationDate < SAODCUTOFDATE) {
			this.newPolicyItems.newPolicyType = (crmObj.newPolicyInfo.newPolicyType == policyTypeArr[1]) ? 'Comprehensive' : crmObj.newPolicyInfo.newPolicyType;
		} else {
			const vehicleAge = this.yearCountFrmDate(crmObj.motorProfile.registrationDate, crmObj.prePolicyInfo.riskEndDate);
			if (crmObj.commonFields.productType.toLowerCase() == productTypeObj.car && vehicleAge >= 3) {
				this.newPolicyItems.newPolicyType = (crmObj.newPolicyInfo.newPolicyType == policyTypeArr[1]) ? 'Comprehensive' : crmObj.newPolicyInfo.newPolicyType;
			} else if (crmObj.commonFields.productType.toLowerCase() == productTypeObj.bike && vehicleAge >= 5) {
				this.newPolicyItems.newPolicyType = (crmObj.newPolicyInfo.newPolicyType == policyTypeArr[1]) ? 'Comprehensive' : crmObj.newPolicyInfo.newPolicyType;
			} else {
				this.newPolicyItems.newPolicyType = 'SAOD';
				this.setTpPolicyDatesOnRegistrationDateChange(crmObj);
			}
		}
	}

	public setTpPolicyDates(): void {
		if (this.newPolicyItems.newPolicyType == 'SAOD') {
			this.leadTpPolicy.riskStartDate = this.motorProfile.registrationDate;
			const d = this.insuranceDurationObj[this.commonItems.productType.toLowerCase()]
			this.leadTpPolicy.riskEndDate = this.formatDate(moment(this.motorProfile.registrationDate).add(d, 'y').subtract(1, 'd'))
		}
	}

	public setTpPolicyDatesOnRegistrationDateChange(obj): void {
		if (this.newPolicyItems.newPolicyType == 'SAOD') {
			this.leadTpPolicy.riskStartDate = obj.motorProfile.registrationDate;
			const d = this.insuranceDurationObj[obj.commonFields.productType.toLowerCase()]
			this.leadTpPolicy.riskEndDate = this.formatDate(moment(obj.motorProfile.registrationDate).add(d, 'y').subtract(1, 'd'))
		} else {
			this.leadTpPolicy.riskStartDate = null;
			this.leadTpPolicy.riskEndDate = null;
		}
	}

	public setRiskStartDate(policyExpiredFlag: boolean = false): void {
		let startDate;
		if (this.motorProfile.isNew) {
			startDate = this.formatDate(moment(this.motorProfile.registrationDate));
		} else if (policyExpiredFlag) {
			startDate = this.formatDate(moment(this.todayDate).add(2, 'd'));
		} else {
			startDate = this.formatDate(moment(this.prePolicyObj.riskEndDate).add(1, 'd'));
		}
		this.newPolicyItems.riskStartDate = startDate;
	}

	public setRiskEndDate(): void {
		let d = 1;
		if (this.motorProfile.isNew) {
			d = this.insuranceDurationObj[this.commonItems.productType.toLowerCase()]
		}
		this.newPolicyItems.riskEndDate = this.formatDate(moment(this.newPolicyItems.riskStartDate).add(d, 'y').subtract(1, 'd'))
	}

	public setNewPolicyItems(key, inputVal): void {
		this.newPolicyItems[key] = inputVal;
	}

	public yearCountFrmDate(fromDate: any, todate: any) {
		todate = (todate == null) ? this.todayDate : todate;
		fromDate = new Date(fromDate);
		todate = new Date(todate);
		let diffInTimeStamp = (todate.getTime() - fromDate.getTime()) / 1000;
		const diffIndays = diffInTimeStamp / (60 * 60 * 24);
		return Math.abs(diffIndays / 365.25);
	}


	public getRenewalInfo(leadObj, insuranceProvider){
		return this.apiService.getRenewalInfo(leadObj, insuranceProvider);
	}
	public updateApi(id: string, leadObj: any) {
		return this.apiService.updateLead(leadObj, id);
	}

	public getLeadDetails(id: string) {
		return this.apiService.getLeadById(id);
	}

	public setRtoCode(rtoInput) {
		let rtoArrayList = rtoInput.split("-")[0];
		return `${rtoArrayList.substr(0, 2)}-${rtoArrayList.substr(2, 2)}`;
	}

	public formatDate(date: any, dateFormat = "YYYY-MM-DD") {
		return moment(date).format(dateFormat);
	}

	public convertDateFormat(inputDate) {
		if (inputDate != null || inputDate != "") {
			return moment(inputDate).format(
				"YYYY-MM-DD"
			);
		} else {
			return null;
		}
	}

	public makeDummyRegistration() {
		if (this.motorProfile.isNew) {
			return "AB-1";
		} else {
			let atext = "";
			let ntext = "";
			let alphaText = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
			let numericText = "0123456789";

			for (var i = 0; i < 2; i++) {
				atext += alphaText.charAt(Math.floor(Math.random() * alphaText.length));
			}
			for (var i = 0; i < 4; i++) {
				ntext += numericText.charAt(
					Math.floor(Math.random() * numericText.length)
				);
			}
			return atext + "-" + ntext;
		}
	}

	public createOrderPayload(inputObj) {
		this.logger.info(inputObj)
		const dateFormat = "DD-MM-YYYY";
		this.orderData.crmId = inputObj.id;
		this.orderData.name = `${inputObj.policyHolderInfo.firstName} ${inputObj.policyHolderInfo.lastName}`;
		this.orderData.email = inputObj.policyHolderInfo.email;
		this.orderData.contact = inputObj.policyHolderInfo.mobile;
		this.orderData.proposalNo = inputObj.newPolicyInfo.proposalNo;
		this.orderData.policyNo = inputObj.newPolicyInfo.policyNo;
		this.orderData.policyStartDate = this.formatDate(inputObj.newPolicyInfo.riskStartDate, dateFormat);
		this.orderData.policyEndDate = this.formatDate(inputObj.newPolicyInfo.riskEndDate, dateFormat);
		this.orderData.productName = inputObj.commonFields.productType;
		this.orderData.make = inputObj.motorProfile.make;
		this.orderData.model = inputObj.motorProfile.model;
		this.orderData.variant = inputObj.motorProfile.variant;
		this.orderData.registrationNo = inputObj.motorProfile.registrationNo;
		this.orderData.registrationDate = this.formatDate(inputObj.motorProfile.registrationDate, dateFormat);
		this.orderData.engineNo = inputObj.motorProfile.engineNo;
		this.orderData.chassisNo = inputObj.motorProfile.chassisNo;
		this.orderData.fuel = inputObj.motorProfile.fuel;
		this.orderData.cc = inputObj.motorProfile.cc;
		this.orderData.gvw = inputObj.motorProfile.gvw;
		this.orderData.orderDate = this.formatDate(this.todayDate, dateFormat);
		this.orderData.providerName = inputObj.newPolicyInfo.insuranceProvider;
		this.orderData.pospId = (inputObj.pospInfo.uniqueId) ? inputObj.pospInfo.uniqueId : "Office";
		this.orderData.odPremium = inputObj.paymentInfo.odPremium;
		this.orderData.tpPremium = inputObj.paymentInfo.tpPremium;
		this.orderData.paPremium = inputObj.paymentInfo.paPremium;
		this.orderData.addonPremium = inputObj.paymentInfo.addonsPremium;
		this.orderData.netPremium = inputObj.paymentInfo.netPremium;
		this.orderData.tax = inputObj.paymentInfo.taxAmount;
		this.orderData.grossPremium = inputObj.paymentInfo.grossPremium;
		return this.orderData;
	}

	public sameDay(d2) {
		const d1 = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");
		return (d1 == d2) ? true : false;
	}

	public validateCutOffDate(addons, crmObj) {
		if (addons.defaultDisplay) {
			const age = addons.maxAge;
			const cutOfDate = this.formatDate(moment(crmObj.newPolicyInfo.riskStartDate).subtract(age.years, 'years').subtract(age.months, 'months').subtract(age.days, 'days').subtract(1, 'day'));
			const d1 = new Date(cutOfDate)
			const d2 = new Date(crmObj.motorProfile.registrationDate)
      return d1 < d2;
		} else {
			return false;
		}
	}

  public getCurrentTimestamp(){
    return moment().format("YYYY-MM-DD HH:mm:ss");
  }

}
