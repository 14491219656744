import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { policyTypeArr } from "src/app/features/policy-config"
import { CrmFlowService } from '../../../../services/crm-flow.service';

@Component({
	selector: 'app-new-policy-selection',
	templateUrl: './new-policy-selection.component.html',
	styleUrls: ['./new-policy-selection.component.scss']
})
export class NewPolicySelectionComponent implements OnInit {
	//@Input() crmObj;
	public policyTypeList: Array<any> = policyTypeArr;
	public modifiedPolicyList: Array<any>;
	public newPolicy: string;
	@Output() newPolicyChanged = new EventEmitter<string>();
	private _crmObj: any;

	@Input()
	set crmObj(value) {
		this._crmObj = value;
		//  this.selectedItem = this.crmObj.find(data => data);
		// this.isSubmitEnable =false;
		this.setPolicyType();
	}
	get crmObj() {
		return this._crmObj;
	};

	constructor(
		private logger: LoggerService,
		private crmFlow: CrmFlowService
	) { }

	ngOnInit(): void {
		this.newPolicy = this.crmObj.newPolicyInfo.newPolicyType;
		this.setPolicyType();
	}
	ngOnChanges(changes: SimpleChanges): void {
		if (changes[this.crmObj]) {
			this.newPolicy = this.crmObj.newPolicyInfo.newPolicyType;
			this.setPolicyType();

		}
	}

	public setPolicyType(): void {
		if (this.crmObj.commonFields.productType.toLowerCase() == "cv") {
			this.modifiedPolicyList = ["Comprehensive", "TP"];
		} else {
			if (this.newPolicy == this.policyTypeList[1]) {
				this.modifiedPolicyList = this.policyTypeList.slice(1, -1)
			} else if (this.crmObj.motorProfile.isNew) {
				this.modifiedPolicyList = ["Comprehensive"]
			} else if (this.crmObj.motorProfile.registrationDate < "2018-09-01") {
				this.modifiedPolicyList = ["Comprehensive", "TP"]
			} else if (this.crmObj.motorProfile.registrationDate > "2018-09-01") {
				const vehicleAge = this.crmFlow.yearCountFrmDate(this.crmObj.motorProfile.registrationDate, this.crmObj.prePolicyInfo.riskEndDate);
				if (this.crmObj.commonFields.productType.toLowerCase() == "car" && vehicleAge < 3) {
					this.modifiedPolicyList = this.policyTypeList.slice(1, -1)
				} else if (this.crmObj.commonFields.productType.toLowerCase() == "bike" && vehicleAge < 5) {
					this.modifiedPolicyList = this.policyTypeList.slice(1, -1)
				} else {
					this.modifiedPolicyList = ["Comprehensive", "TP"];
				}
			}
		}
	}

	public onNewPolicyChange(): void {
		this.newPolicyChanged.emit(this.newPolicy)
	}

}
