import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest } from "@angular/common/http";
import { map, catchError } from 'rxjs/operators';
import { of, Observable, throwError, BehaviorSubject } from "rxjs";
import { environment } from "../../../environments/environment"
import { encryptStorage } from '../services/encryptStorage';
import { LoggerService } from '../logger/logger.service';

@Injectable({
	providedIn: "root",
})
export class ApiService {
	private _apiUrl: string = `${environment.apiBaseUrl}`;
	public currentUser: Observable<any>;
	public currentUserSubject: BehaviorSubject<any>;
	private serviceName = environment.serviceName
	private header: any ;

	constructor(
		private httpClient: HttpClient,
		private logger: LoggerService
	) {
		this.currentUserSubject = new BehaviorSubject<any>(encryptStorage.getItem<any>('loggedUser'));

		this.currentUser = this.currentUserSubject.asObservable();
		this.setHeader();
	}

	public setHeader(): void {
		this.currentUser.subscribe((user) => {
			if (user != null && user != undefined) {
				const userData = JSON.parse(user);
				this.header = {
					headers: new HttpHeaders().set(
						'Authorization',
						`${userData.access}`
					),
				};
			}
		})
	}

	public getAPICall(path, header?): Observable<any> {
		return this.httpClient.get(path, header).pipe(
			map((Response) => Response),
			catchError((Response) => throwError(Response))
		);
	}

	public postAPICall(path, payload, header?): Observable<any> {
		return this.httpClient.post(path, payload, header).pipe(
			map((Response) => Response),
			catchError((Response) => throwError(Response))
		);
	}

	public putAPICall(path, payload, header?): Observable<any> {
		return this.httpClient.put(path, payload, header).pipe(
			map((Response) => Response),
			catchError((Response) => throwError(Response))
		);
	}

	public requestAPICall(payload, header?): Observable<any> {
		return this.httpClient.request(payload, header).pipe(
			map((Response) => Response),
			catchError((Response) => throwError(Response))
		);
	}

	public getNewToken(payload) {
		const path = `${this._apiUrl}${this.serviceName.refreshToken}`;
		return this.postAPICall(path, payload);
	}

	public createLead(payload) {
		const path = `${this._apiUrl}${this.serviceName.createLead}`;
		return this.postAPICall(path, payload);
	}

	public createDynamoLeads(payload) {
		const path = `${this._apiUrl}${this.serviceName.dynamoLeads}`;
		return this.postAPICall(path, payload);
	}

	public updateDynamoLeads(payload, leadId) {
		const path = `${this._apiUrl}${this.serviceName.dynamoLeads}/${leadId}`;
		return this.putAPICall(path, payload);
	}

	public getDynamoLeadById(leadId) {
		const path = `${this._apiUrl}${this.serviceName.dynamoLeads}/${leadId}`;
		return this.getAPICall(path);
	}

	public getDynamoLeads() {
		const path = `${this._apiUrl}${this.serviceName.dynamoLeads}`;
		return this.getAPICall(path);
	}

	public getQuotationLink(payload) {
		const path = `${this._apiUrl}${this.serviceName.generateSortUrl}`;
		return this.postAPICall(path, payload);
	}

	public sendPaymentLink(payload) {
		const path = `${this._apiUrl}${this.serviceName.sendPaymentLink}`;
		return this.postAPICall(path, payload);
	}

	public getRenewalInfo(payload, insuranceProvider) {
		const path = `${this._apiUrl}${insuranceProvider}/get-policy-info`;
		return this.postAPICall(path, payload);
	}

	public updateLead(payload, id) {
    const path = `${this._apiUrl}${this.serviceName.updateLead}${id}`;
		return this.putAPICall(path, payload);
	}

	public getLeadById(id) {
		const path = `${this._apiUrl}${this.serviceName.getLeadById}${id}`;
		return this.getAPICall(path);
	}

	public createOrder(payload) {
		const path = `${this._apiUrl}${this.serviceName.orders}`;
		return this.postAPICall(path, payload);
	}

	public orderList() {
		var header = {
			headers: new HttpHeaders()
				.set('Authorization', `Bearer ${JSON.parse(encryptStorage.getItem<any>('loggedUser')).access}`)
		}

		const path = `${this._apiUrl}${this.serviceName.orderList}`;
		return this.getAPICall(path, header);
	}

	public pospRequestList() {
		const path = `${this._apiUrl}${this.serviceName.pospLeads}`;
		return this.getAPICall(path, this.header);
	}

	public register(payload) {
		const path = `${this._apiUrl}${this.serviceName.register}`;
		return this.postAPICall(path, payload, this.header);
	}

	public updatePOSP(payload, id) {
		const path = `${this._apiUrl}${this.serviceName.pospLeads + '/'}${id}`;
		return this.putAPICall(path, payload, this.header);
	}

	public registeredUser() {
		const path = `${this._apiUrl}${environment.userPath}${this.serviceName.cognitoUser}`;
		return this.postAPICall(path, {}, this.header);
	}

	public createUser(payload) {
		const path = `${this._apiUrl}${this.serviceName.newUser}`;
		return this.postAPICall(path, payload, this.header);
	}

	public userExaminations(payload) {
		const path = `${this._apiUrl}${this.serviceName.userExaminations}`;
		return this.postAPICall(path, payload, this.header);
	}

	public getTrainingItems() {
		const path = `${this._apiUrl}${this.serviceName.trainingItem}`;
		return this.getAPICall(path, this.header);
	}

	public getUserTrainingHistoryDetails(userId) {
		const path = `${this._apiUrl}${this.serviceName.userTrainingHistory}${userId}`;
		return this.getAPICall(path, this.header);
	}

	public updateTrainingHistory(payload) {
		const path = `${this._apiUrl}${this.serviceName.userTrainingHistory}`;
		return this.postAPICall(path, payload, this.header);
	}

	public updateUserProfile(payload, userId) {
		const path = `${this._apiUrl}${this.serviceName.updateUser}${userId}`;
		return this.putAPICall(path, payload, this.header);
	}

	public getUserProfile(userId) {
		const path = `${this._apiUrl}${this.serviceName.updateUser}${userId}`;
		return this.getAPICall(path, this.header);
	}

	public updateLeadUserKyC(payload) {
		const path = `${this._apiUrl}${this.serviceName.leadUsersKyc}`;
		return this.postAPICall(path, payload);
	}

	// public getUserTrainingHistory(userId) {
	// 	var header = {
	// 		headers: new HttpHeaders()
	// 			.set('Authorization', `${JSON.parse(encryptStorage.getItem<any>('loggedUser')).access}`)
	// 	}

	// 	const path = `${this._apiUrl}${this.serviceName.userTrainingHistory}/${userId}`;
	// 	return this.getAPICall(path, header);
	// }

	public getVehicleMaster(payload) {
		const path = `${this._apiUrl}${this.serviceName.vehicleMaster}`;
		return this.postAPICall(path, payload, this.header);
	}


}
