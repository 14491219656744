<div class="card text--sm">
    <img src="/assets/images/our_partners/{{crmObj.newPolicyInfo.insuranceProvider}}.png" class="card-img-top" />
    <div class="card-body">
        <h3 class="card-header"><strong>Premium Summary</strong></h3>
        <ul class="list-group list-group-flush">
            <li class="list-group-item">IDV: <span class="pl-5">{{crmObj.newPolicyInfo.idv | currency:
                    "INR":"symbol"}}</span></li>
            <li class="list-group-item">Net Premium: <span class="pl-5">{{crmObj.paymentInfo.netPremium | currency:
                    "INR":"symbol"}}</span></li>
            <li class="list-group-item">Tax: <span class="pl-5">{{crmObj.paymentInfo.taxAmount | currency:
                    "INR":"symbol"}}</span></li>
            <li class="list-group-item">Gross Premium: <span class="pl-5">{{crmObj.paymentInfo.grossPremium | currency:
                    "INR":"symbol"}}</span></li>
        </ul>
        <h3 class="card-header mb-0 text-heading--sm">Quote Id: <span class="pl-5"><strong>{{crmObj.leadId}}</strong></span>
        </h3>
        <h3 class="card-header mb-0 text-heading--sm"><strong>{{ crmObj.motorProfile.make}} {{ crmObj.motorProfile.model}} {{
                crmObj.motorProfile.variant}} - {{ crmObj.motorProfile.fuelType}}</strong></h3>
        <h3 class="card-header mb-0 text-heading--sm">RTO: <span class="pl-5"><strong>{{ crmObj.motorProfile.rtoLoc}}</strong></span>
        </h3>
        <ul class="list-group list-group-flush">
            <li class="list-group-item">Product Type: <span class="pl-5">{{productTypeSelected}}</span></li>
            <li class="list-group-item">Policy Type: <span class="pl-5">{{crmObj.newPolicyInfo.newPolicyType}}</span>
            </li>
            <li class="list-group-item">Reg. Date: <span class="pl-5">{{crmObj.motorProfile.registrationDate |
                    date: "dd-MM-yyyy"}}</span></li>
            <li class="list-group-item">Risk Start Date: <span class="pl-5">{{crmObj.newPolicyInfo.riskStartDate | date:
                    "dd-MM-yyyy"}}</span></li>
        </ul>
    </div>
    <div class="card-body pt-0" *ngIf="!crmObj.motorProfile.isNew">
        <h3 class="card-header"><strong>Pre Policy Summary</strong></h3>
        <ul class="list-group list-group-flush">
            <li class="list-group-item">Policy Expired: <span
                    class="pl-5">{{crmObj.newPolicyInfo.isBreakin?"Yes":"No"}}</span></li>
            <li class="list-group-item" *ngIf="!crmObj.newPolicyInfo.isBreakin">Policy Type: <span
                    class="pl-5">{{crmObj.prePolicyInfo.policyType }}</span></li>
            <li class="list-group-item" *ngIf="!crmObj.newPolicyInfo.isBreakin">Risk End Date: <span
                    class="pl-5">{{crmObj.prePolicyInfo.riskEndDate | date:
                    "dd-MM-yyyy"}}</span></li>
        </ul>
    </div>
    <div class="card-body pt-0" *ngIf="crmObj.ncbInfo.isNcb">
        <h3 class="card-header"><strong>NCB Summary</strong></h3>
        <ul class="list-group list-group-flush">
            <li class="list-group-item">Previous Ncb %: <span class="pl-5">{{crmObj.ncbInfo.currentNcb}}</span></li>
            <li class="list-group-item">New Ncb %: <span class="pl-5">{{crmObj.ncbInfo.newNcb}}</span></li>
        </ul>
    </div>
</div>
