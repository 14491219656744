import { Component, OnInit, ElementRef } from '@angular/core';

@Component({
    selector: 'app-real-modal',
    templateUrl: './real-modal.component.html',
    styleUrls: ['./real-modal.component.scss']
})
export class RealModalComponent implements OnInit {
    constructor(private el: ElementRef) { }
    ngOnInit() {
        // when the backdrop is clicked the modal is closed.
        this.el.nativeElement.addEventListener('click', () => {
            this.close();
        });
    }

    close() {
        this.el.nativeElement.classList.remove('sshow');
        this.el.nativeElement.classList.add('hhidden');
    }
}