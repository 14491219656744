import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { LoggerService } from 'src/app/core/logger/logger.service';

@Component({
  selector: 'app-member-age',
  templateUrl: './member-age.component.html',
  styleUrls: ['./member-age.component.scss']
})
export class MemberAgeComponent implements OnInit {
  public numberSeq: Array<any> = [];
  public isSubmitted = false;
  public isLoading: boolean = true;
  dataSource = new BehaviorSubject<AbstractControl[]>([]);
  ageForm: FormGroup;
  @Input() inputObj
  @Output() nextButtonClicked = new EventEmitter<object>();

  constructor(
    private logger: LoggerService,
    private formBuilder: FormBuilder,
  ) {
    this.ageForm = this.createFormGroup();

  }

  ngOnInit(): void {
    const add = this.ageForm.get('age') as FormArray;

    this.inputObj.insurerInfo.forEach(element => {
      if (element.items) {
        element.items.forEach(element2 => {
          add.push(
            this.formBuilder.group({
              gender: element.gender,
              age: element2.age,
              relationship: element.relationship
            })
          );
        });
      } else {
        add.push(
          this.formBuilder.group(element)
        );
      }
    });


    setTimeout(() => {
      this.loadPage();
    }, 800);
  }

  public loadPage(): void {
    this.generateNumberSeq();
    this.isLoading = false;
  }

  getControls() {
    return (this.ageForm.get('age') as FormArray).controls;
  }

  createFormGroup() {
    return this.formBuilder.group({
      age: this.formBuilder.array([]),
    });
    // return this.formBuilder.group({
    // 	age: [
    // 		16,
    // 		[],
    // 	],
    // });
  }

  onSubmit() {
    this.isSubmitted = true;
    this.logger.info(this.ageForm.value);
    debugger;
    // return true;
    if (!this.isValid(this.ageForm.value.age)) {
      return;
    }

    // this.crmObj["insurerInfo"] = this.memberList;
    // this.callUpdateLeadApi(this.crmObj);
    // if (this.ageForm.invalid) {
    // 	return;
    // }
    this.nextButtonClicked.emit(this.ageForm.value);
  }

  isValid(data): any {
    // const ageData = this.memberList.filter(d => d.age === 0
    //   || d.age === "0" || d.age === null);
    // return ageData.length > 0 ? false : true;

    const ageData = data.filter(d => d.age === 0
      || d.age === "0" || d.age === null);
    return ageData.length > 0 ? false : true;
  }

  public generateNumberSeq(): void {
    for (let i = 18; i < 66; i++) {
      this.numberSeq.push(i);
    }
  }

  // updateView() {
  //   this.dataSource.next(this.ageForm.age.controls);
  // }

  // getAgeList(type): Array<any> {
  //   let arrList = [];
  //   if (type === 'adult') {
  //     for (let i = 18; i <= 100; i++) {
  //       arrList.push(i + ' Years');
  //     }
  //   } else {
  //     for (let i = 1; i <= 41; i++) {
  //       if (i < 12) {
  //         arrList.push(i + ' Months');
  //       } else {
  //         arrList.push((i - 11) + ' Years');
  //       }
  //     }
  //   }
  //   return arrList;
  // }

}
