<div>
	<div fxShow="true" fxHide.lt-sm="true">
		<app-quote-filter-sidebar [crmObj]="crmObj" (isCrmDataUpdated)="onCrmDataUpdated($event)"></app-quote-filter-sidebar>
	</div>
	<div fxShow="true" fxHide.gt-sm="true" ngClass.lt-sm="mt-4 text-center">
		<button mat-button (click)="sidebar.toggle()" class="btn btn-default btn-real btn-selected wrap-text">
			Click here to customize the result
		</button>
	</div>
	<mat-sidenav-container (backdropClick)="sidebar.close()">
		<mat-sidenav #sidebar (keydown.escape)="sidebar.close()" position="end" role="navigation"
			[fixedInViewport]="true" fixedTopGap="120">
			<app-quote-filter-sidebar [crmObj]="crmObj" (isCrmDataUpdated)="onCrmDataUpdated($event)" (sideBarClose)="sidebar.close()"></app-quote-filter-sidebar>
		</mat-sidenav>
		<mat-sidenav-content fxShow="true" fxHide.gt-sm="true">
		</mat-sidenav-content>
	</mat-sidenav-container>
</div>