<div class="sidebar" ngClass.lt-sm="sidebar-margin-sm" ngClass.gt-sm="sidebar-margin">
    <div class="row m-0">
        <!--fxShow="true" fxHide.gt-sm="true"-->
        <div class="col-12 bg-gray">
            <h2 class="h6 text-heading pt-2 display-inline-block">Advance Filter</h2>
            <button fxShow="true" fxHide.gt-sm="true" mat-icon-button (click)="close()"
                class="pull-right mat-icon-button close-button close-icon-filter">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 col-md-12">
            <ul class="list-group">
                <li class="list-group-item" *ngIf="!crmObj.motorProfile.isNew">
                    <div class="ms-2 me-auto">
                        <div class="text-heading">Registration Date <span class="edit-icon">
                                <i (click)="onRegistrationDateEdit()" *ngIf="!isRegistrationDateEdit"
                                    class="fa fa-pencil" aria-hidden="true"></i>
                            </span>
                        </div>
                        <p class="pt-1 mb-0 text--sm" *ngIf="!isRegistrationDateEdit">
                            {{crmObj.motorProfile.registrationDate | date: "dd-MM-yyyy"}}</p>
                        <p class="pt-1 mb-0 text--sm" *ngIf="isRegistrationDateEdit">
                            <mat-form-field class="w-100" appearance="outline">
                                <input matInput [matDatepicker]="regDatePicker" onkeydown="return false"
                                    placeholder="Registration Date" [(ngModel)]="registrationDate"
                                    [min]="minRegistrationDate" (ngModelChange)="onRegistrationDateChange($event)"
                                    [max]="maxRegistrationDate" />
                                <mat-datepicker-toggle matSuffix [for]="regDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #regDatePicker ngDefaultControl></mat-datepicker>
                            </mat-form-field>
                        </p>
                    </div>
                </li>
                <li class="list-group-item" *ngIf="!crmObj.motorProfile.isNew">
                    <mat-slide-toggle class="pr-5" (change)="onVehicleOwnerChange($event)" [(ngModel)]="isOwnerChanged">
                    </mat-slide-toggle>
                    <label class="text--sm" for="isOwnerChanged">Ownership Changed</label>
                </li>
                <li class="list-group-item" *ngIf="isDisplayCpaBox">
                    <mat-slide-toggle class="pr-5" (change)="onPACoverChange($event)" [(ngModel)]="isCpa">
                    </mat-slide-toggle>
                    <label class="text--sm" for="isCpa">Need PA Cover</label>
                </li>
                <li class="list-group-item" *ngIf="!isCpa">
                    <mat-slide-toggle class="pr-5" (change)="onDlChange($event)" [(ngModel)]="isDl"></mat-slide-toggle>
                    <label class="text--sm" for="isCpa">Having Valid DL</label>
                </li>
                <li class="list-group-item" *ngIf="!isCpa && isDl">
                    <mat-slide-toggle class="pr-5" aria-disabled="true" [(ngModel)]="isEcpa"></mat-slide-toggle>
                    <label class="text--sm" for="isCpa">Having CPA</label>
                </li>
            </ul>
        </div>
        <div class="col-sm-12 col-md-12 pt-2" *ngIf="isPreviousPolicyDisplayBox">
            <ul class="list-group">
                <li class="list-group-item">
                    <mat-slide-toggle class="pr-5" (change)="onPolicyExpired($event)" [(ngModel)]="isPolicyExpired">
                    </mat-slide-toggle>
                    <label class="text--sm" for="isPolicyExpired">Policy Expired</label>
                </li>
                <li class="list-group-item" *ngIf="!isPolicyExpired">
                    <div class="ms-2 me-auto">
                        <div class="text-heading">{{crmObj.newPolicyInfo.newPolicyType == 'SAOD' ? 'OD': 'Pre Policy'}}
                            Expire Date
                            <span class="edit-icon">
                                <i (click)="enableEdit()" *ngIf="!isPreviousPolicyDateEdit" class="fa fa-pencil"
                                    aria-hidden="true"></i>
                            </span>
                        </div>
                        <p class="pt-1 mb-0 text--sm" *ngIf="!isPreviousPolicyDateEdit">
                            {{crmObj.prePolicyInfo.riskEndDate | date: "dd-MM-yyyy"}}</p>
                        <p class="pt-1 mb-0 text--sm" *ngIf="isPreviousPolicyDateEdit">
                            <mat-form-field class="w-100" appearance="outline">
                                <input matInput [matDatepicker]="prePolicyDatePicker" onkeydown="return false"
                                    placeholder="Previous Policy Date" [(ngModel)]="riskEndDate"
                                    [max]="maxPreviousPolicyDate" [min]="minPreviousPolicyDate"
                                    (ngModelChange)="onPreviousPolicyDateChange($event)" />
                                <mat-datepicker-toggle matSuffix [for]="prePolicyDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #prePolicyDatePicker ngDefaultControl></mat-datepicker>
                            </mat-form-field>
                        </p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="col-sm-12 col-md-12 pt-2" *ngIf="isNcbDisplayBox">
            <ul class="list-group">
                <li class="list-group-item">
                    <mat-slide-toggle (change)="onNcbChange($event)" class="pr-5" [(ngModel)]="isNcbClaimed">
                    </mat-slide-toggle>
                    <label class="text--sm" for="isNcbClaimed">NCB Claim Taken</label>
                </li>
                <li class="list-group-item" *ngIf="!isNcbClaimed">
                    <div class="ms-2 me-auto">
                        <label class="text-heading">Existing NCB</label>
                        <select class="form-select form-select--sm" (ngModelChange)="onNcBValueChange($event)"
                            [ngModel]="selectedNcb">
                            <option *ngFor="let item of ncbList" value="{{item}}">{{item | number:'2.2-2'}}&nbsp;%
                            </option>
                        </select>
                    </div>
                </li>
            </ul>
        </div>
        <div class="col-sm-12 col-md-12 pt-2 pb-2">
            <div class="card list-max-height">
                <div class="card-header text-heading body-copy-sm">Addons
                    <a (click)="updateAddons();" class="edit-icon text-heading">
                        <i class="fa fa-refresh" aria-hidden="true"></i> Update
                    </a>
                </div>
                <ul class="list-group">
                    <li class="list-group-item" *ngFor="let items of additionalCoversList">
                        <mat-slide-toggle [(ngModel)]="items.selected" class="pr-5"></mat-slide-toggle>
                        <label class="text--sm" for="{{items.coverType}}">{{items.coverType}}</label>
                        <p *ngIf="items.selected && items.type == 'inputBox'">
                            <input min="{{items.minValue}}" max="{{items.maxValue}}" [value]="items.minValue"
                                step="{{items.steps}}" placeholder="Enter Cover Amount" type="number"
                                class="form-control" [(ngModel)]="items.coverAmount">
                        </p>
                        <p *ngIf="items.selected &&  items.type == 'dropdown'">
                            <select class="form-select form-select--sm" [(ngModel)]="items.coverAmount">
                                <option *ngFor="let item of items.list;let i = index;" [value]="item">{{item |
                                    currency:'INR'}}
                                </option>
                            </select>
                        </p>
                    </li>
                    <ng-container *ngFor="let items of accessoriesList">
                        <li class="list-group-item"
                            *ngIf="(items.accessoryId === '3' && crmObj.motorProfile.fuelType !== 'PETROL+CNG') || items.accessoryId === '1' || items.accessoryId === '2'">
                            <mat-slide-toggle [(ngModel)]="items.selected" class="pr-5"></mat-slide-toggle>
                            <label class="text--sm" for="{{items.accessoryType}}">{{items.accessoryType}}</label>
                            <p *ngIf="items.selected">
                                <input type="number" step="{{items.steps[crmObj.commonFields.productType]}}"
                                    min="{{items.minValue[crmObj.commonFields.productType]}}"
                                    max="{{items.maxValue[crmObj.commonFields.productType]}}"
                                    [value]="items.minValue[crmObj.commonFields.productType]"
                                    placeholder="Enter Cover Amount" class="form-control"
                                    [(ngModel)]="items.coverAmount">
                            </p>
                        </li>
                    </ng-container>
                    <li class="list-group-item" *ngFor="let items of addonsList">
                        <mat-slide-toggle [(ngModel)]="items.selected" class="pr-5"></mat-slide-toggle>
                        <label class="text--sm" for="{{items.addonType}}">{{items.addonType}}</label>
                    </li>
                    <li class="list-group-item" *ngFor="let items of extraList">
                        <span *ngIf="items.type == 'boolean'">
                            <mat-slide-toggle [(ngModel)]="items.selected" class="pr-5"></mat-slide-toggle>
                            <label class="text--sm" for="{{items.coverType}}">{{items.coverType}}</label>
                        </span>
                        <span *ngIf="items.type == 'dropdown'">
                            <label class="text--sm" for="{{items.coverType}}">{{items.coverType}}</label>
                            <select class="form-select form-select--sm" [(ngModel)]="items.coverAmount">
                                <option *ngFor="let item of vdList" [ngValue]="item">{{item
                                    | currency:'INR'}}</option>
                            </select>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
