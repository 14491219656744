import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { InsuranceComponent } from './components/insurance/insurance.component';
import { CompareQuotesComponent } from './components/compare-quotes/compare-quotes.component';
import { PaymentComponent } from './components/payment/payment.component';
import { InformationStepperComponent } from './components/information-stepper/information-stepper.component';

const routes: Routes = [
  {
    path: '',
    component: InsuranceComponent,
    data: { insurance: 'car' }
  },
  {
    path: 'car-insurance',
    component: InsuranceComponent,
    data: { insurance: 'car' }
  },
  {
    path: 'bike-insurance',
    component: InsuranceComponent,
    data: { insurance: 'bike' }
  },
  {
    path: 'health-insurance',
    component: InsuranceComponent,
    data: { insurance: 'health' }
  },
  {
    path: 'commercial-insurance',
    component: InsuranceComponent,
    data: { insurance: 'commercial' }
  },
  {
    path: 'compare-quotes',
    component: CompareQuotesComponent
  },
  {
    path: 'payment-confirmation',
    component: PaymentComponent
  },
  {
    path: 'personal-info',
    component: InformationStepperComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FeaturesRoutingModule { }
