import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-quote-filter-responsive-sidebar',
  templateUrl: './quote-filter-responsive-sidebar.component.html',
  styleUrls: ['./quote-filter-responsive-sidebar.component.scss']
})
export class QuoteFilterResponsiveSidebarComponent implements OnInit {
  @Input() public crmObj;
  @Output() public crmUpdated = new EventEmitter<boolean>()
  constructor() { }

  ngOnInit(): void {
  }

  public onCrmDataUpdated(event):void{
    this.crmUpdated.emit(event)
  }

}
