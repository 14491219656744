import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { memberList, daughter, son, self, faFemale, faMale, male, female } from 'src/app/features/health-insurance-config';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { policyHolderGenderArr } from 'src/app/features/policy-config';

@Component({
	selector: 'app-insured-selection',
	templateUrl: './insured-selection.component.html',
	styleUrls: ['./insured-selection.component.scss']
})
export class InsuredSelectionComponent implements OnInit {
	public isLoading: boolean = true;
	public memberList: Array<any> = memberList; //JSON.parse(JSON.stringify(memberList));
	public daughter: string = daughter;
	public son: string = son;
	ageForm: FormGroup;
	isSubmitted: boolean = false;
	genderList: Array<any> = policyHolderGenderArr;
	@Output() nextButtonClicked = new EventEmitter<object>();
	@Input() inputObj

	constructor(
		private formBuilder: FormBuilder,
		private logger: LoggerService,
	) { }

	ngOnInit(): void {
		this.ageForm = this.createFormGroup();
		setTimeout(() => {
			this.loadPage();
			this.toggleSelection(this.memberList[0]);
		}, 800);
	}

	public loadPage(): void {
		const icon = (this.inputObj.gender.toLowerCase() == this.genderList[0].toLowerCase()) ? faMale : faFemale;
		this.memberList[0].icon = icon;
		this.memberList[0].gender = (icon == faMale) ? male : female;
		this.memberList[1].icon = (icon == faMale) ? faFemale : faMale;
		this.memberList[1].gender = (icon == faMale) ? female : male;
		this.isLoading = false;
	}

	createFormGroup() {
		return this.formBuilder.group({
			insurerInfo: [[], [Validators.required]],
		});
	}

	get form() {
		return this.ageForm.controls;
	}

	isValid() {
		return this.memberList.filter(d => d.isSelected)?.length > 0 ? true : false;
	}

	onSubmit(): void {
		this.isSubmitted = true;
		if (this.ageForm.invalid) {
			return;
		}
		this.nextButtonClicked.emit(this.ageForm.value);
	}

	toggleSelection(item) {
		if (item.title != self) {
			item.isSelected = !item.isSelected;
		}
		const data = this.memberList.filter(d => d.isSelected);
		const insurerInfo: Array<any> = [];
		data.forEach(element => {
			insurerInfo.push(
				{ relationship: element.title, gender: element.gender, age: null, items: element.items });
		});
		this.ageForm.controls['insurerInfo'].setValue(insurerInfo);
	}

	incrementDecrement(type, item) {
		debugger;
		if (type === "INC") {
			if (item.total < 4) {
				item.total++;
				item.items.push({ age: null });
			}
		} else {
			if (item.total > 1) {
				item.total--;
				item.items.pop();
			}
		}
	}

}
