<div class="container">
	<app-loader *ngIf="isLoading"></app-loader>
	<div class="row mt-3 pb-3" *ngIf="isPolicyGenerated">
		<div class="col">
			<div class="row">
				<div class="col-lg-8 offset-lg-2">
					<div class="alert text-center pt-3" [ngClass]="{
						'alert-success': params.status == 1,
						'alert-danger': params.status == 0,
            'alert-warning': params.status == 9
					  }" role="alert">
						<h4 class="alert-heading">
							<i class="fa pr-10" [ngClass]="{
								'fa-check-circle': params.status == 1,
								'fa-times-circle': params.status == 0,
                'fa-info-circle': params.status == 9
							  }" aria-hidden="true"></i>{{params.status == 1? "Congratulations": params.status == 0?"Sorry!":"Kyc Pending" }}
						</h4>
						<p *ngIf="params.status == 1">Thank you for purchasing your insurance policy from <span
								class="alert-heading--normal">{{params.providerName}}</span></p>
						<p *ngIf="params.status == 0">Oops something went wrong! Connect with us with reference number if
							amount deducted.</p>
              <p *ngIf="params.status == 9">Payment is successful.
                <br/>Your policy cover will be valid only when you complete the KYC and the policy is issued.
              </p>
						<hr *ngIf="params.status">
						<p *ngIf="params.status == 1" class="mb-0 line-height--extra">A confirmation email has
							been sent to<br />
							<span class="alert-heading--normal">{{crmObj.policyHolderInfo.email}}</span>
							<br />
							<span class="pr-10">Policy No: <span
										class="alert-heading--normal pr-10">{{crmObj.newPolicyInfo.policyNo}}</span>
										<a class="pointer" (click)="downloadPolicy()"> <i class="fa fa-download pr-5"
											aria-hidden="true"></i>Download</a>
							</span>
						</p>

            <p *ngIf="params.status == 0" class="mb-0 line-height--extra">Oops something went wrong! Connect with us with reference number if
							amount deducted. <br />
							<span class="pr-10">Policy No: <span
										class="alert-heading--normal pr-10">{{crmObj.newPolicyInfo.policyNo}}</span>
										<!-- <a class="pointer" href="{{crmObj.kyc.kycUrl}}" target="_blank"> <i class="fa fa-cog pr-5"
											aria-hidden="true"></i>Click here to start Kyc Process</a> -->
							</span>
						</p>


            <p *ngIf="params.status == 9" class="mb-0 line-height--extra">
              <span class="text-bold">Finish your KYC to complete your purchase!</span><br />
							<!-- <span class="alert-heading--normal">{{crmObj.policyHolderInfo.email}}</span>
							<br /> -->
							<span class="pr-10">Policy No: <span
										class="alert-heading--normal pr-10">{{crmObj.newPolicyInfo.policyNo}}</span>
										<a class="pointer" href="{{crmObj.kyc.kycUrl}}" target="_blank"> <i class="fa fa-cog pr-5"
											aria-hidden="true"></i>Click here to start Kyc Process</a>
							</span>
              <br/>
              <span class="pr-10"><a class="pointer" (click)="validateKycStatus()"> <i class="fa fa-cog pr-5"
                aria-hidden="true"></i>Check the Policy Issue status, If you have completed your KYC</a></span>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- <div class="row mt-3 pb-3" *ngIf="!params.status">
		<div class="col">
			<div class="row">
				<div class="col-lg-8 offset-lg-2">
					<div class="alert text-center pt-3" [ngClass]="{
						'alert-success': params.status,
						'alert-danger': !params.status
					  }" role="alert">
						<h4 class="alert-heading">
							<i class="fa pr-10" [ngClass]="{
								'fa-check-circle': params.status,
								'fa-times-circle': !params.status
							  }" aria-hidden="true"></i>{{params.status? "Congratulations": "Sorry!" }}
						</h4>
						<p *ngIf="params.status">Thank you for purchasing your insurance policy from <span
								class="alert-heading--normal">{{params.providerName}}</span></p>
						<p *ngIf="!params.status">Oops something went wrong! Connect with us with reference number if
							amount deducted.</p>
					</div>
				</div>
			</div>
		</div>
	</div> -->
</div>
