<form #form action="{{ payment.paymentUrl }}" method="post">
	<div *ngIf="payment.providerName == 'tata-aig'">
		<input name="proposal_no" [hidden]="true" type="text" [(ngModel)]="payment.inputPayload.proposal_no" />
		<input name="src" type="text" [hidden]="true" [(ngModel)]="payment.inputPayload.src" />
	</div>
	<div *ngIf="payment.providerName == 'shriram-general'">
		<input name="PolicySysID" [hidden]="true" type="text" [(ngModel)]="payment.inputPayload.PolicySysID" />
		<input name="ReturnURL" type="text" [hidden]="true" [(ngModel)]="payment.inputPayload.ReturnURL" />
	</div>
	<div *ngIf="payment.providerName == 'hdfc-ergo'">
		<input name="appid" [hidden]="true" type="text" [(ngModel)]="payment.inputPayload.hdnappid" />
		<input name="trnsno" [hidden]="true" type="text" [(ngModel)]="payment.inputPayload.hdnTransactionNo" />
		<input name="amt" [hidden]="true" type="text" [(ngModel)]="payment.inputPayload.hdnamt" />
		<input name="subid" [hidden]="true" type="text" [(ngModel)]="payment.inputPayload.hdnsubid" />
		<input name="surl" [hidden]="true" type="text" [(ngModel)]="payment.inputPayload.hdnsurl" />
		<input name="furl" [hidden]="true" type="text" [(ngModel)]="payment.inputPayload.hdnfurl" />
		<input name="src" [hidden]="true" type="text" [(ngModel)]="payment.inputPayload.hdnsrc" />
		<input name="chksum" [hidden]="true" type="text" [(ngModel)]="payment.inputPayload.hdnchksum" />
    <input name="Additionalinfo1" [hidden]="true" type="text"  [(ngModel)]="payment.inputPayload.Additionalinfo1" />
	</div>
	<div *ngIf="payment.providerName == 'kotak-general'">
		<input name="key" [hidden]="true" type="text" [(ngModel)]="payment.inputPayload.key" />
		<input name="txnid" [hidden]="true" type="text" [(ngModel)]="payment.inputPayload.txnid" />
		<input name="amount" [hidden]="true" type="text" [(ngModel)]="payment.inputPayload.amount" />
		<input name="email" [hidden]="true" type="text" [(ngModel)]="payment.inputPayload.email" />
		<input name="surl" [hidden]="true" type="text" [(ngModel)]="payment.inputPayload.surl" />
		<input name="furl" [hidden]="true" type="text" [(ngModel)]="payment.inputPayload.hdnfurl" />
		<input name="lastname" [hidden]="true" type="text" [(ngModel)]="payment.inputPayload.lastname" />
		<input name="firstname" [hidden]="true" type="text" [(ngModel)]="payment.inputPayload.firstname" />
		<input name="curl" [hidden]="true" type="text" [(ngModel)]="payment.inputPayload.curl" />
		<input name="phone" [hidden]="true" type="text" [(ngModel)]="payment.inputPayload.phone" />
		<input name="productinfo" [hidden]="true" type="text" [(ngModel)]="payment.inputPayload.productinfo" />
		<input name="udf1" [hidden]="true" type="text" [(ngModel)]="payment.inputPayload.udf1" />
		<input name="udf2" [hidden]="true" type="text" [(ngModel)]="payment.inputPayload.udf2" />
		<input name="udf3" [hidden]="true" type="text" [(ngModel)]="payment.inputPayload.udf3" />
		<input name="udf4" [hidden]="true" type="text" [(ngModel)]="payment.inputPayload.udf4" />
		<input name="udf5" [hidden]="true" type="text" [(ngModel)]="payment.inputPayload.udf5" />
		<input name="hash" [hidden]="true" type="text" [(ngModel)]="payment.inputPayload.hash" />
	</div>
	<button type="submit" class="btn btn-block btn-danger btn-real btn-selected w-100 mt-3" (click)="form.submit()"
		[disabled]="disabledAgreement">
		Buy Now
	</button>
</form>
