import { Component, Input, EventEmitter, Output,OnInit,SimpleChanges } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';


@Component({
  selector: 'app-company-information-details',
  templateUrl: './company-information-details.component.html',
  styleUrls: ['./company-information-details.component.scss']
})
export class CompanyInformationDetailsComponent implements OnInit {
  @Input() crmObj;
  companyInfoFormGroup :FormGroup;
  @Output() nextButtonClicked: EventEmitter<any> =  new EventEmitter<any>();

  constructor(private _formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.createCompanyForm();
  }
  ngOnChanges(changes: SimpleChanges) {
    this.crmObj = changes.crmObj.currentValue;
    this.createCompanyForm();
  }
  private createCompanyForm():void{
    this.companyInfoFormGroup = this._formBuilder.group({
      companyName: [this.crmObj.policyHolderInfo.companyName, Validators.required],
      companyGstNo: [this.crmObj.policyHolderInfo.companyGstNo]
    });
  }


  public nextStep(evt):void{
    if (this.companyInfoFormGroup.invalid) {
			return;
		}
		this.nextButtonClicked.emit(this.companyInfoFormGroup.value);
  }
  
}
