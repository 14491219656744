export const memberList = [{
    title: 'Self',
    isSelected: true,
    icon: 'fa-male',
    gender: 'male',
    type: 'adult'
}, {
    title: 'Spouse',
    isSelected: false,
    icon: 'fa-female',
    gender: 'female',
    type: 'adult'
}, {
    title: 'Daughter',
    isSelected: false,
    gender: 'female',
    icon: 'fa-female',
    total: 1,
    items: [{ age: null }],
    type: 'child'
}, {
    title: 'Son',
    isSelected: false,
    gender: 'male',
    icon: 'fa-male',
    total: 1,
    items: [{ age: null }],
    type: 'child'
}, {
    title: 'Father',
    isSelected: false,
    gender: 'male',
    icon: 'fa-male',
    age: 0,
    type: 'adult'
}, {
    title: 'Mother',
    gender: 'female',
    isSelected: false,
    icon: 'fa-female',
    age: 0,
    type: 'adult'
}];

export const daughter = "Daughter";
export const son = "Son";
export const self = "Self";
export const spouse = "Spouse";
export const male = "Male";
export const female = "Female";
export const faFemale = "fa-female";
export const faMale = "fa-male";
export const father = "Father";
export const mother = "Mother";
