import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-insurance',
	templateUrl: './insurance.component.html',
	styleUrls: ['./insurance.component.scss'],
})
export class InsuranceComponent implements OnInit {
	selectedInsuranceType: string;
	public isPageAccessAllowed: boolean = true;
  constructor(
	) { }

	ngOnInit(): void {
  }

	onSelectedInsuranceReceived(item: any) {
    this.selectedInsuranceType = item;
	}
}
