import { Component, EventEmitter, Input, OnInit, Output, ViewChild, TemplateRef, ViewContainerRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PremiumBreakupComponent } from 'src/app/features/components/compare-quotes/premium-breakup/premium-breakup.component';

@Component({
	selector: 'app-quote-list',
	templateUrl: './quote-list.component.html',
	styleUrls: ['./quote-list.component.scss']
})
export class QuoteListComponent implements OnInit {
	quote: any;

	@Input() quoteList;
	@Output() QuoteSelected = new EventEmitter<any>();

	@ViewChild('modal') modal: TemplateRef<any>;
	@ViewChild('vc', { read: ViewContainerRef }) vc: ViewContainerRef;

	constructor(
		private modalService: NgbModal
	) { }

	ngOnInit(): void {
	}

	ngAfterViewInit() {
	}

	public selectPlan(quote): void {
		this.QuoteSelected.emit(quote);
	}

	public showNcbModal(quote): void {
		this.quote = quote;
		const modalRef = this.modalService.open(PremiumBreakupComponent, { size: 'lg' });
		modalRef.componentInstance.quote = quote;
	}

	public closeDialog(): void {
		this.vc.clear()
	}

}
