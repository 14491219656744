import { Injectable } from '@angular/core';
import { LoggerService } from "src/app/core/logger/logger.service";
import { policyTypeArr, productTypeObj } from "src/app/features/policy-config"

@Injectable({
	providedIn: 'root'
})
export class BreakInServiceService {
	public policyTypeArr: Array<any> = policyTypeArr;
	public productType: any = productTypeObj;

	constructor(
		private logger: LoggerService
	) { }

	public setInspectionFlag(crmObj) {
		let isInspection = true;
		if (crmObj.newPolicyInfo.newPolicyType == policyTypeArr[2]) {
			isInspection = false;
		} else if (crmObj.commonFields.productType == productTypeObj['bike']) {
			isInspection = false;
		}
		return isInspection;
	}

}