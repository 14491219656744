import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss']
})
export class PaymentFormComponent implements OnInit {
  @Input() payment: any;
  @Input() disabledAgreement : boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
