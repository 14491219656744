import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { MatStepper } from '@angular/material/stepper'
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { LeadResponse } from 'src/app/features/interfaces/leadResponse'
import { CrmFlowService } from 'src/app/features/services/crm-flow.service';
import { ownerTypeArr, addonsConfig } from 'src/app/features/policy-config';
import { KycService } from 'src/app/features/services/kyc.service';

@Component({
	selector: 'app-proposal-form',
	templateUrl: './proposal-form.component.html',
	styleUrls: ['./proposal-form.component.scss'],
	providers: [{
		provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
	}]
})
export class ProposalFormComponent implements OnInit {
	@Input() crmObj: any;
	public steps: string = "ProposalInput";
	@Output() proposalFromPurchaseButtonClicked: EventEmitter<any> = new EventEmitter<any>();
	public crmId: string;
	selectionChanged: Subject<any> = new Subject<any>();
	@ViewChild('stepper', { static: false }) stepper: MatStepper;
	public isAllStepsCompleted: boolean = false;
	public lastStep: any;
	public registrationStepCompleted: boolean = false;
	public motorInfoStepCompleted: boolean = false;
	public policyStepCompleted: boolean = false;
	public policyHolderStepCompleted: boolean = false;
	public communicationInfoStepCompleted: boolean = false;
  public kycStepCompleted: boolean = false;
	public extraStepCompleted: boolean = false;
	public ownerTypeList: Array<any> = ownerTypeArr;
	public addonsConfigList: Array<any>;
	public isCheckBox: boolean = false
	public isAddons: boolean = false;
	public isRenewal: boolean = false;
  public kycProcessApiFlag: any;

	changeStep(index: number) {
		this.stepper.selectedIndex = index;
	}
	constructor(
		private logger: LoggerService,
		private crmFlow: CrmFlowService,
    private kycService: KycService
	) { }

	ngOnInit(): void {
		this.getCrmId();
		setTimeout(() => {
			this.selectionChanged.next(this.stepper);
		}, 1000);
		this.addonsConfigList = addonsConfig[this.crmObj.commonFields.productType]
		this.checkAddonsFlag();
    this.getKycProcess();
	}


  public getKycProcess(): void {
    this.kycProcessApiFlag = this.kycService.validateKycProcess(this.crmObj.newPolicyInfo.insuranceProvider);
  }

	public checkAddonsFlag(): void {
		if (!this.crmObj.motorProfile.isNew) {
			for (const [key, value] of Object.entries(this.addonsConfigList)) {
				if (this.crmObj.extra.addons[value]) {
					this.isAddons = true;
				}
			}
		}
	}

	public getCrmId(): void {
		this.crmId = this.crmObj.leadId;
	}

	public emitToBoxHeader(evt): void {
		this.selectionChanged.next(this.stepper);
	}

	public registrationStepNextClicked(value): void {

		if (!this.crmObj.motorProfile.isNew) {
			this.crmObj["motorProfile"]["registrationNo"] = `${value.rtoLoc}-${value.rtoTwoDigit}-${value.rtoNumber}`;
		}
		setTimeout(() => {
			this.registrationStepCompleted = true;
			this.callUpdateLeadApi(this.crmObj);
		}, 400);
	}

	public motorInfoStepNextClicked(value): void {
		this.crmObj["motorProfile"]["chassisNo"] = value.chassisNo;
		this.crmObj["motorProfile"]["engineNo"] = value.engineNo;
		setTimeout(() => {
			this.motorInfoStepCompleted = true;
			this.callUpdateLeadApi(this.crmObj);
		}, 400);
	}

	public previousInsuranceDetailsNextClicked(value): void {
    this.crmObj["prePolicyInfo"]["isPreviousPolicy"] = value.isPreviousPolicy;
		this.crmObj["prePolicyInfo"]["policyType"] = value.previousPolicyType;
		this.crmObj["prePolicyInfo"]["insurerName"] = value.insurerName;
		this.crmObj["prePolicyInfo"]["policyNo"] = value.policyNo;
		this.crmObj["tpPolicyInfo"]["insurerName"] = value.tpInsurerName;
		this.crmObj["tpPolicyInfo"]["policyNo"] = value.tpPolicyNo;
		this.crmObj["tpPolicyInfo"]["riskStartDate"] = this.crmObj.tpPolicyInfo.riskStartDate;
    this.crmObj["tpPolicyInfo"]["riskEndDate"] = this.crmObj.tpPolicyInfo.riskEndDate;
    if (!value.isPreviousPolicy) {
			this.crmFlow.setPreviousPolicyExpiryDateOnPolicyExpired(100);
			this.crmFlow.setPreviousPolicyStartDateOnPolicyExpired();
			this.crmObj["prePolicyInfo"]["riskEndDate"] = this.crmFlow.prePolicyObj.riskEndDate;
			this.crmObj["prePolicyInfo"]["riskStartDate"] = this.crmFlow.prePolicyObj.riskStartDate;
			this.crmObj["ncbInfo"]["isNcb"] = false;
			this.crmObj["ncbInfo"]["currentNcb"] = 0;
			this.crmObj["ncbInfo"]["newNcb"] = 0;
		}
		setTimeout(() => {
			this.policyStepCompleted = true;
			this.callUpdateLeadApi(this.crmObj);
		}, 400);
	}

	public policyHolderStepNextClicked(value): void {
    this.crmObj["policyHolderInfo"]["firstName"] = value.firstName;
		this.crmObj["policyHolderInfo"]["lastName"] = value.lastName;
		this.crmObj["policyHolderInfo"]["email"] = value.email;
		this.crmObj["policyHolderInfo"]["mobile"] = value.mobile;
		this.crmObj["policyHolderInfo"]["gender"] = value.gender;
		this.crmObj["policyHolderInfo"]["panNo"] = value.panNo;
		this.crmObj["policyHolderInfo"]["maritalStatus"] = value.maritalStatus;
		this.crmObj["policyHolderInfo"]["dob"] = this.crmFlow.formatDate(value.dob);
    if(this.kycProcessApiFlag == null){
      this.crmObj["kyc"]["kycDocNumber"] = value.panNo;
      const kycDocType = this.kycService.getKycDoctypeId(this.crmObj.newPolicyInfo.insuranceProvider, "panNo");
      this.crmObj["kyc"]["kycDocType"] = kycDocType;
      this.crmObj["kyc"]["isKycCompleted"] = false;
    }
		setTimeout(() => {
			this.policyHolderStepCompleted = true;
			this.callUpdateLeadApi(this.crmObj);
		}, 400);
	}

	public companyInfoStepNextClicked(value): void {
		this.crmObj["policyHolderInfo"]["companyName"] = value.companyName;
		this.crmObj["policyHolderInfo"]["companyGstNo"] = value.companyGstNo;
		setTimeout(() => {
			this.policyHolderStepCompleted = true;
			this.callUpdateLeadApi(this.crmObj);
		}, 400);
	}

	public communicationInfoStepNextClicked(value): void {
    this.crmObj["address"]["registered"]["postalCode"] = value.postalCode;
		this.crmObj["address"]["registered"]["addressLine1"] = value.addressLine1;
		this.crmObj["address"]["registered"]["addressLine2"] = value.addressLine2;
		this.crmObj["address"]["registered"]["city"] = value.city;
		this.crmObj["address"]["registered"]["state"] = value.state;
		this.crmObj["address"]["registered"]["country"] = "India";
		this.crmObj["address"]["communication"]["postalCode"] = value.comPostalCode;
		this.crmObj["address"]["communication"]["addressLine1"] = value.comAddressLine1;
		this.crmObj["address"]["communication"]["addressLine2"] = value.comAddressLine2;
		this.crmObj["address"]["communication"]["city"] = value.comCity;
		this.crmObj["address"]["communication"]["state"] = value.comState;
		this.crmObj["address"]["sameAsRegisteredAddress"] = value.sameAsRegisteredAddress;
		setTimeout(() => {
			this.communicationInfoStepCompleted = true;
			this.callUpdateLeadApi(this.crmObj);
		}, 400);
	}

  public kycInfoStepNextClicked(value): void {
    this.logger.info(value);
    this.crmObj["kyc"]["isKycCompleted"] = value.isKycCompleted;
    this.crmObj["kyc"]["kycDocNumber"] = value.kycDocNumber;
    this.crmObj["kyc"]["kycId"] = value.kycId;
    this.crmObj["kyc"]["kycUrl"] = value.kycUrl;
    this.crmObj["kyc"]["kycApiResponse"] = value.kycApiResponse;

    if(value["registered"]){
      this.crmObj["address"]["registered"]["postalCode"] = value?.registered?.pincode;
      this.crmObj["address"]["registered"]["addressLine1"] = value?.registered?.addressLine1;
      this.crmObj["address"]["registered"]["addressLine2"] = value?.registered?.addressLine2;
      this.crmObj["address"]["registered"]["city"] = value?.registered?.city;
      this.crmObj["address"]["registered"]["state"] = value?.registered?.state;
      this.crmObj["address"]["registered"]["country"] = "India";
    }
    if(value["communication"]){
      this.crmObj["address"]["communication"]["postalCode"] = value?.communication?.pincode;
      this.crmObj["address"]["communication"]["addressLine1"] = value?.communication?.addressLine1;
      this.crmObj["address"]["communication"]["addressLine2"] = value?.communication?.addressLine2;
      this.crmObj["address"]["communication"]["city"] = value?.communication?.city;
      this.crmObj["address"]["communication"]["state"] = value?.communication?.state;
      this.crmObj["address"]["communication"]["country"] = "India";
    }

    this.crmObj["address"]["sameAsRegisteredAddress"] = value.sameAsRegisteredAddress;

    this.crmObj["policyHolderInfo"]["firstName"] = (value.salutation)?value.salutation:"";
    this.crmObj["policyHolderInfo"]["firstName"] = (value.firstName)?value.firstName:this.crmObj.policyHolderInfo.firstName;
    this.crmObj["policyHolderInfo"]["lastName"] = (value.lastName)?value.lastName:this.crmObj.policyHolderInfo.lastName;
    this.crmObj["policyHolderInfo"]["email"] = (value.email)?value.email: this.crmObj.policyHolderInfo.email;
    this.crmObj["policyHolderInfo"]["mobile"] = (value.mobile)?value.mobile: this.crmObj.policyHolderInfo.mobile;
    this.crmObj["policyHolderInfo"]["gender"] = (value.gender)?value.gender:this.crmObj.policyHolderInfo.gender;
    this.crmObj["policyHolderInfo"]["panNo"] = (value.panNo)?value.panNo:value.panNo;
    this.crmObj["policyHolderInfo"]["maritalStatus"] = (value.maritalStatus)?value.maritalStatus:this.crmObj.policyHolderInfo.maritalStatus;
    this.crmObj["policyHolderInfo"]["dob"] = (value.dob)?value.dob:this.crmFlow.formatDate(value.dob);

    this.logger.info("===> at ln 210")
    this.logger.info(this.crmObj)
    setTimeout(() => {
			this.kycStepCompleted = true;
			this.callUpdateLeadApi(this.crmObj);
		}, 400);
  }

	public nomineeDetailsStepNextClicked(value): void {
		this.crmObj["nomineeInfo"]["name"] = value.name;
		this.crmObj["nomineeInfo"]["age"] = value.age;
		this.crmObj["nomineeInfo"]["relation"] = value.relation;
		this.crmObj["cpaInfo"]["insurerName"] = value.insurerName;
		this.crmObj["cpaInfo"]["policyNo"] = value.policyNo;
		this.crmObj["cpaInfo"]["riskStartDate"] = value.riskStartDate;
		this.crmObj["extra"]["isFinanced"] = value.isFinanced;
		this.crmObj["extra"]["financierName"] = value.financierName;
		this.crmObj["extra"]["financierCity"] = value.financierCity;
		this.crmObj["extra"]["financierPincode"] = value.financierPincode;
		this.crmObj["extra"]["aaimName"] = value.aaimName;
		this.crmObj["extra"]["membershipNo"] = value.membershipNo;
		this.crmObj["extra"]["membershipExpiryDate"] = (value.membershipExpiryDate) ? this.crmFlow.formatDate(value.membershipExpiryDate) : null;
		setTimeout(() => {
			this.extraStepCompleted = true;
			if (!this.isAddons) {
				this.isCheckBox = true
			}
			this.callUpdateLeadApi(this.crmObj);
		}, 400);
	}

	public callGetRenewalInfoApi(crmObj): void {
		// let payload = {
		// 	"policyNo": crmObj.prePolicyInfo.policyNo,
		// 	"registrationNo": crmObj.motorProfile.registrationNo
		// }
		// let insuranceProvider = this.crmObj.newPolicyInfo.insuranceProvider;
		// this.crmFlow.getRenewalInfo(payload, insuranceProvider).subscribe(
		// 	(leadResponse: LeadResponse) => {
		// 		this.isRenewal = true;
		// 		this.updateRenewalDetails(leadResponse);
		// 	},
		// 	(error) => {
		// 		this.logger.info(error);
		// 	}
		// );
	}

	public updateRenewalDetails(leadResponse): void {
		this.crmObj["policyHolderInfo"]["firstName"] = leadResponse.policyHolderInfo.firstName;
		this.crmObj["policyHolderInfo"]["lastName"] = leadResponse.policyHolderInfo.lastName;
		this.crmObj["policyHolderInfo"]["email"] = leadResponse.policyHolderInfo.email;
		this.crmObj["policyHolderInfo"]["mobile"] = leadResponse.policyHolderInfo.mobile;
		this.crmObj["policyHolderInfo"]["gender"] = leadResponse.policyHolderInfo.gender ? leadResponse.policyHolderInfo.gender : null;
		this.crmObj["policyHolderInfo"]["maritalStatus"] = leadResponse.policyHolderInfo.maritalStatus ? leadResponse.policyHolderInfo.maritalStatus : null;
		this.crmObj["policyHolderInfo"]["dob"] = (leadResponse.policyHolderInfo.dob && leadResponse.policyHolderInfo.dob != "") ? this.crmFlow.formatDate(leadResponse.policyHolderInfo.dob) : null;
		if (leadResponse.nomineeInfo) {
			this.crmObj["nomineeInfo"]["name"] = leadResponse.nomineeInfo.name;
			this.crmObj["nomineeInfo"]["age"] = leadResponse.nomineeInfo.age;
			this.crmObj["nomineeInfo"]["relation"] = leadResponse.nomineeInfo.relation;
		}
		if (leadResponse.cpaInfo) {
			this.crmObj["cpaInfo"]["insurerName"] = leadResponse.cpaInfo.insurerName;
			this.crmObj["cpaInfo"]["policyNo"] = leadResponse.cpaInfo.policyNo;
			this.crmObj["cpaInfo"]["riskStartDate"] = leadResponse.cpaInfo.riskStartDate;
		}
		if (leadResponse.extra) {
			this.crmObj["extra"]["financierName"] = leadResponse.extra.financierName;
			this.crmObj["extra"]["sameAsRegisteredAddress"] = leadResponse.extra.sameAsRegisteredAddress;
		}
		this.crmObj["address"]["registered"]["postalCode"] = leadResponse.address.registered.postalCode;
		this.crmObj["address"]["registered"]["addressLine1"] = leadResponse.address.registered.addressLine1;
		this.crmObj["address"]["registered"]["addressLine2"] = leadResponse.address.registered.addressLine2;
		this.crmObj["address"]["registered"]["city"] = leadResponse.address.registered.city;
		this.crmObj["address"]["registered"]["state"] = leadResponse.address.registered.state;
		this.crmObj["address"]["registered"]["country"] = "India";
		this.crmObj["address"]["communication"]["postalCode"] = leadResponse.address.communication.postalCode;
		this.crmObj["address"]["communication"]["addressLine1"] = leadResponse.address.communication.addressLine1;
		this.crmObj["address"]["communication"]["addressLine2"] = leadResponse.address.communication.addressLine2;
		this.crmObj["address"]["communication"]["city"] = leadResponse.address.communication.city;
		this.crmObj["address"]["communication"]["state"] = leadResponse.address.communication.state;
		this.crmObj["motorProfile"]["chassisNo"] = leadResponse.motorProfile.chassisNo;
		this.crmObj["motorProfile"]["engineNo"] = leadResponse.motorProfile.engineNo;
		setTimeout(() => {
			this.policyStepCompleted = true;
			this.callUpdateLeadApi(this.crmObj);
		}, 400);
	}

	public callUpdateLeadApi(leadPayload): void {
    leadPayload["modifiedOn"] = this.crmFlow.getCurrentTimestamp();
		this.crmFlow.updateApi(this.crmId, leadPayload).subscribe(
			(leadResponse: LeadResponse) => {
				this.crmObj = leadResponse;
				this.goToNext();
			},
			(error) => {
				this.logger.info(error);
			}
		);
	}

	public goToNext(): void {
		this.stepper.selected.completed = true;
		this.stepper.selected.editable = true;
		if (this.stepper.steps.length == this.stepper.selectedIndex + 1) {
			this.isAllStepsCompleted = true;
		}
		this.setLastStep();
		this.stepper.next();
	}

	public setLastStep(): void {
		this.lastStep = (this.stepper.steps.last);
	}

	changeCheck(event) {
		this.isCheckBox = event.checked;
	}

	public onSubmit(): void {
		this.proposalFromPurchaseButtonClicked.emit(this.crmObj);
	}
}
