import { Component, Input, EventEmitter, Output, OnInit,SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { relationShip, ownerTypeArr, AAIMOBJ } from "src/app/features/policy-config";
import { CommonService } from 'src/app/features/services/common.service';
import * as moment from 'moment';
import { CrmFlowService } from '../../../../services/crm-flow.service';

@Component({
	selector: 'app-nominee-information-details',
	templateUrl: './nominee-information-details.component.html',
	styleUrls: ['./nominee-information-details.component.scss']
})

export class NomineeInformationDetailsComponent implements OnInit {
	@Input() crmObj;
	@Input() lastStep;
	public isDisable: boolean = false;
	extraFormGroup: FormGroup;
	public insurerData: Array<any> = [];
	public relationshipList: Array<any> = relationShip;
	public pHolder: Array<any> = ownerTypeArr;
	public numberSeq: Array<any> = [];
	public isFinanced: boolean = false;
	public isAutomobileAssociation: boolean = false;
	public isFetching: boolean = false;
	public pinCodeData: Array<any>;
	public minExpiryDate: string;
	public automobileObj: Array<any> = AAIMOBJ;
	@Output() nextButtonClicked: EventEmitter<any> = new EventEmitter<any>();

	constructor(
		private _formBuilder: FormBuilder,
		private commonService: CommonService,
		private crmFlow: CrmFlowService
	) { }

	ngOnInit(): void {
		this.getInsuranceData();
		this.createExtraForm();
		this.generateNumberSeq();
		this.setFinancedFlag()
		this.setAutomobileAssociationFlag();
		this.setMinExpiryDate();
		this.setFormValidation();
	}
	ngOnChanges(changes: SimpleChanges) {
        this.crmObj = changes.crmObj.currentValue;
		this.createExtraForm();
		this.setFormValidation();
	}

	public setMinExpiryDate(): void {
		const today = new Date();
		this.minExpiryDate = this.crmFlow.formatDate(moment(today).add(1, 'days'));
	}

	public generateNumberSeq(): void {
		for (let i = 18; i < 101; i++) {
			this.numberSeq.push(i);
		}
	}

	public setFinancedFlag(): void {
		this.isFinanced = this.crmObj.extra.isFinanced;
	}

	public setAutomobileAssociationFlag(): void {
		this.isAutomobileAssociation = this.crmObj.extra.addons.aaieDiscount;
	}

	public setFormValidation(): void {
		if ((this.crmObj.policyHolderInfo.policyholderType == this.pHolder[0]) && (!this.crmObj.cpaInfo.isEcpa)) {
			this.setNomineeValidation();
		} else if ((this.crmObj.policyHolderInfo.policyholderType == this.pHolder[0]) && (this.crmObj.cpaInfo.isEcpa)) {
			this.setCpaValidation();
		}
		if (this.isFinanced) {
			this.setFinancedValidation();
		}
		if (this.isAutomobileAssociation) {
			this.setAAIMValidation();
		}
	}

	public setFinancedValidation(): void {
		const financierName = this.extraFormGroup.get("financierName");
		const financierPincode = this.extraFormGroup.get("financierPincode");
		const financierCity = this.extraFormGroup.get("financierCity");
		financierName.setValidators([Validators.required]);
		financierPincode.setValidators([Validators.required]);
		financierCity.setValidators([Validators.required]);
		financierName.updateValueAndValidity();
		financierPincode.updateValueAndValidity();
		financierCity.updateValueAndValidity();
	}

	public setAAIMValidation(): void {
		const aaimName = this.extraFormGroup.get("aaimName");
		const membershipNo = this.extraFormGroup.get("membershipNo");
		const membershipExpiryDate = this.extraFormGroup.get("membershipExpiryDate");
		aaimName.setValidators([Validators.required]);
		membershipNo.setValidators([Validators.required]);
		membershipExpiryDate.setValidators([Validators.required]);
		aaimName.updateValueAndValidity();
		membershipNo.updateValueAndValidity();
		membershipExpiryDate.updateValueAndValidity();
	}

	public setNomineeValidation(): void {
		const name = this.extraFormGroup.get("name");
		const age = this.extraFormGroup.get("age");
		const relation = this.extraFormGroup.get("relation");
		name.setValidators([Validators.required]);
		age.setValidators([Validators.required]);
		relation.setValidators([Validators.required]);
		name.updateValueAndValidity();
		age.updateValueAndValidity();
		relation.updateValueAndValidity();
	}

	public setCpaValidation(): void {
		const insurerName = this.extraFormGroup.get("insurerName");
		const policyNo = this.extraFormGroup.get("policyNo");
		const riskStartDate = this.extraFormGroup.get("riskStartDate");
		insurerName.setValidators([Validators.required]);
		policyNo.setValidators([Validators.required]);
		riskStartDate.setValidators([Validators.required]);
		insurerName.updateValueAndValidity();
		policyNo.updateValueAndValidity();
		riskStartDate.updateValueAndValidity();
	}

	private createExtraForm(): void {
		this.extraFormGroup = this._formBuilder.group({
			name: [(this.crmObj.nomineeInfo?.name)?this.crmObj.nomineeInfo?.name: ""],
			age: [(this.crmObj.nomineeInfo?.age)?this.crmObj.nomineeInfo?.age:""],
			relation: [(this.crmObj.nomineeInfo?.relation)?this.crmObj.nomineeInfo?.relation:""],
			insurerName: [this.crmObj.cpaInfo.insurerName],
			policyNo: [this.crmObj.cpaInfo.policyNo],
			riskStartDate: [this.crmObj.cpaInfo.riskStartDate],
			isFinanced: [this.crmObj.extra.isFinanced],
			financierName: [this.crmObj.extra.financierName],
			financierCity: [this.crmObj.extra.financierCity],
			financierPincode: [this.crmObj.extra.financierPincode],
			aaimName: [this.crmObj.extra.aaimName],
			membershipNo: [this.crmObj.extra.membershipNo],
			membershipExpiryDate: [this.crmObj.extra.membershipExpiryDate],
		});
	}

	public nextStep(evt) {
		if (this.extraFormGroup.invalid) {
			return;
		}
		this.nextButtonClicked.emit(this.extraFormGroup.value);
		if (this.lastStep.state == 'extra') {
			this.isDisable = true;
		}
	}

	public getInsuranceData(): void {
		this.commonService.getInsurerDetails(this.crmObj.newPolicyInfo.insuranceProvider).subscribe((data) => {
			this.insurerData = data;
		})
	}

	public onFinanced(event): void {
		this.isFinanced = event.checked;
		const financierName = this.extraFormGroup.get("financierName");
		const financierCity = this.extraFormGroup.get("financierCity");
		const financierPincode = this.extraFormGroup.get("financierPincode");
		if (this.isFinanced) {
			financierName.setValidators([Validators.required]);
			financierCity.setValidators([Validators.required]);
			financierPincode.setValidators([Validators.required]);
		} else {
			financierName.setValidators(null);
			financierCity.setValidators(null);
			financierPincode.setValidators(null);
			this.extraFormGroup.patchValue({
				financierName: "",
				financierCity: "",
				financierPincode: ""
			})
		}
		financierName.updateValueAndValidity();
		financierPincode.updateValueAndValidity();
		financierCity.updateValueAndValidity();
	}

	public callPincodeAPI(): void {
		this.isFetching = true;
		const pinCode = this.extraFormGroup.controls["financierPincode"].value;
		if (pinCode.toString().length == 6) {
			this.commonService.getCityByPincode().subscribe((response) => {
				let pincodeList = response.filter((x) => x.Pincode == pinCode)
				this._resetStateAndCity(pincodeList);
			}, (error) => {
				this._resetStateAndCity([]);
			})
		}
	}

	private _resetStateAndCity(pincodeList: Array<any>): void {
		this.pinCodeData = pincodeList;
		this.isFetching = false;
		this.pathValueToInput();
	}

	private pathValueToInput(): void {
		this.extraFormGroup.patchValue({
			financierCity: (this.pinCodeData) ? this.pinCodeData[0].District : "",
		})
	}
}
