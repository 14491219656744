<div class="card mt-3">
	<h3 class="card-header text-heading mb-0">Registration Details</h3>
	<div class="card-body">
		<form [formGroup]="registrationDetailsFormGroup" class="bg-white personal-info-form-section">
			<div class="form-row pb-3" *ngIf="!motorProfile.isNew">
				<div class="col-sm-12 col-lg-6">
					<label for="rtoLoc" class="pb-1">Registration No</label>
					<div class="form-item__card-number">
						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<input class="form-control" formControlName="rtoLoc" type="text" readonly />
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<input autocomplete="on" class="form-control" formControlName="rtoTwoDigit"
										minlength="1" maxlength="2" type="text" placeholder="Enter Alphabet hash"
										[ngClass]="{
                    'is-invalid': isSubmitted && form.rtoTwoDigit.errors
                  }" />
									<div *ngIf="isSubmitted && form.rtoTwoDigit.errors" class="invalid-feedback">
										<span class="text-danger" *ngIf="form.rtoTwoDigit.errors.required">Registration
											No
											alphabet hash
											is required</span>
										<span class="text-danger" *ngIf="form.rtoTwoDigit.errors.pattern">Only Alphabets
											required</span>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<input autocomplete="on" class="form-control" placeholder="Enter registration no"
										formControlName="rtoNumber" maxlength="4" type="text" [ngClass]="{
                    'is-invalid': isSubmitted && form.rtoNumber.errors
                  }" />
									<div *ngIf="isSubmitted && form.rtoNumber.errors" class="invalid-feedback">
										<span class="text-danger" *ngIf="form.rtoNumber.errors.required">Registration No
											is required</span>
										<span class="text-danger" *ngIf="form.rtoNumber.errors.pattern">Registration No
											must be
											a
											valid number</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col p-4 mt-3">
					<div class="form-group body-copy-lg">
						<p class="text-center">
							<button class="btn btn-default btn-real btn-selected ms-4" (click)="onSubmit()"
								id="purchasePlan">
								Next
							</button>
						</p>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
