<form [formGroup]="kycFormGroup" class="bg-white personal-info-form-section">
<div class="card mt-3">
  <h3 class="card-header text-heading mb-0">Complete Your KYC</h3>
  <div *ngIf="kycApiFlag.isPOI">
    <div class="card-body">
        <div class="row pb-3">
          <div class="col-12">
            <mat-slide-toggle class="form-control" class="pr-5"
              formControlName="isKycCompleted" (change)="onKycNotExist($event)">
            </mat-slide-toggle><label for="isKycCompleted">{{kycCheckboxLabel}} {{crmObj.newPolicyInfo.insuranceProvider}}</label>
          </div>
        </div>
        <div class="row pb-3">
          <div class="col-sm-12 col-lg-6" *ngIf="isKycVerified">
            <mat-form-field class="w-100" appearance="outline">
              <input matInput formControlName="kycDocNumber" placeholder="Enter existing KYC Id with {{crmObj.newPolicyInfo.insuranceProvider}}"
              autocomplete="off" minlength="10" maxlength="10" />
              <mat-error *ngIf="
              kycFormGroup['controls'].kycDocNumber.invalid &&
              kycFormGroup['controls'].kycDocNumber.dirty
                ">
                  Policyholder Kyc Id Required
                </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-12 col-lg-6" *ngIf="crmObj.policyHolderInfo.policyholderType == ownerTypeArr[0]">
            <mat-form-field class="w-100" appearance="outline">
              <input matInput formControlName="panNo" placeholder="Enter Policyholder Pan No to Complete the kyc"
              autocomplete="off" minlength="10" maxlength="10"/>
              <mat-error *ngIf="
              kycFormGroup['controls'].panNo.invalid &&
              kycFormGroup['controls'].panNo.dirty
                ">
                  Policyholder Pan No Required
                </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-12 col-lg-6" *ngIf="crmObj.policyHolderInfo.policyholderType == ownerTypeArr[1]">
            <mat-form-field class="w-100" appearance="outline">
              <input matInput formControlName="cin" placeholder="Enter Policyholder CIN to Complete the kyc"
              autocomplete="off" minlength="21" maxlength="21"/>
              <mat-error *ngIf="
              kycFormGroup['controls'].cin.invalid &&
              kycFormGroup['controls'].cin.dirty
                ">
                  Policyholder CIN Required
                </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-12 col-lg-6">
            <mat-form-field class="w-100" appearance="outline">
              <input matInput [matDatepicker]="picker" onkeydown="return false" [max]="ownerDOBLimit"
                placeholder="Date Of Birth To complete the KYC" formControlName="dob" tabindex="2" autocomplete="new-dob" />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker ngDefaultControl></mat-datepicker>
              <mat-error *ngIf="
              kycFormGroup['controls'].dob.invalid &&
              kycFormGroup['controls'].dob.dirty
              ">
                Policyholder date of birth is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row pb-3">
          <div class="col-sm-12 col-lg-6">
            <a *ngIf="isButtonDisabled" class="btn btn-selected pointer" [ngClass]="{
              'btn--loading': isSubmitted
            }" (click)="generateKyc()"> Generate Kyc with {{crmObj.newPolicyInfo.insuranceProvider}}</a>
            <a *ngIf="isKycUrlGenerated" class="pointer btn btn-default text-bg-warning" href="{{kycUrl}}"> <i class="fa fa-cog pr-5"
              aria-hidden="true"></i>Click here to start Kyc Process</a>
          </div>
        </div>
        <div class="row">
          <div class="col p-4 mt-3">
            <div class="form-group body-copy-lg">
              <p *ngIf="isKycUrlGenerated" class="text-bold text-danger">
                Please Complete The Kyc to issue Policy</p>
              <p class="text-center" *ngIf="!isKycUrlGenerated">
                <button [disabled]="isButtonDisabled" class="btn btn-default btn-real btn-selected ms-4" (click)="onSubmit()"
                  id="purchasePlan">
                  Next
                </button>
              </p>
            </div>
          </div>
        </div>
    </div>
  </div>
  <div *ngIf="kycApiFlag.isPOA">
    <h3 class="card-header text-heading mb-0">Kyc For Proof Of Address</h3>
  </div>
</div>
</form>
