<div fxFlexFill>
  <app-header (sidenavToggle)="sidenav.toggle()"></app-header>
  <mat-sidenav-container (backdropClick)="sidenav.close()">
    <mat-sidenav #sidenav role="navigation" position="end" [fixedInViewport]="true" fixedTopGap="96">
      <app-sidenav-list (sidenavClose)="sidenav.close()"></app-sidenav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <main style="min-height:424px">
        <router-outlet></router-outlet>
      </main>
      <app-footer></app-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>