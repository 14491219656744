import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { productType } from 'src/app/features/policy-config';

@Component({
  selector: 'app-premium-breakup',
  templateUrl: './premium-breakup.component.html',
  styleUrls: ['./premium-breakup.component.scss']
})
export class PremiumBreakupComponent implements OnInit {
  @Input() quote;
  fixedTPCNG = 60;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  public ConvertStringToNumber(input: string) {
    var numeric = Number(input);
    return numeric;
  }

  totalValue(array){
    let total = 0;
    array.forEach(element => {
      if(element){
        total = total + parseFloat(element);
      }
    });
    return total;
  }

  minusValue(array){
    let total = 0;
    array.forEach(element => {
      if(element){
        total = total + parseFloat(element);
      }
    });
    return total;
  }

  getDisplayText(key){
    return productType.find(d=>d.key == key).displayText;
  }

  calculateCNG(val){
    const returnVal = {od:0,tp:0};
    if(this.quote.insuranceType === 'SAOD'){
      returnVal.od = val;
    }else if(this.quote.insuranceType === 'TP'){
      returnVal.tp = this.fixedTPCNG;
    }else if(this.quote.insuranceType === 'Comprehensive'){
      returnVal.od = val - this.fixedTPCNG;
      returnVal.tp = this.fixedTPCNG;
    }
    return returnVal;
  }
}
