import { Component, Input, EventEmitter, Output, OnInit, ViewEncapsulation, SimpleChanges } from '@angular/core';
import { Observable, of } from "rxjs";
import {
	FormGroup,
	FormControl,
	Validators,
	FormBuilder,
} from "@angular/forms";

import { LoggerService } from 'src/app/core/logger/logger.service';

@Component({
	selector: 'app-motor-information-details',
	templateUrl: './motor-information-details.component.html',
	styleUrls: ['./motor-information-details.component.scss'],
	encapsulation: ViewEncapsulation.None,
})

export class MotorInformationDetailsComponent implements OnInit {
	@Input() crmObj: any;
	@Input() isRenewal;
	@Output() nextButtonClicked: EventEmitter<any> = new EventEmitter<any>();
	motorInfoFormGroup: FormGroup;
	public isSubmitted: boolean = false;
	@Input()
	searching = false;
	searchFailed = false;
	hideSearchingWhenUnsubscribed = new Observable(() => () =>
		(this.searching = false)
	);

	constructor(
		private _formBuilder: FormBuilder,
		private logger: LoggerService
	) { }

	ngOnInit(): void {
		this.motorInfoFormGroup = this.createMotorFormGroup();
	}
	ngOnChanges(changes: SimpleChanges) {
		this.crmObj = changes.crmObj.currentValue;
		this.motorInfoFormGroup = this.createMotorFormGroup();
	}
	public createMotorFormGroup() {
		return this._formBuilder.group({
			engineNo: [this.crmObj.motorProfile.engineNo, Validators.required],
			chassisNo: [this.crmObj.motorProfile.chassisNo, Validators.required]
		});
	}

	get form() {
		return this.motorInfoFormGroup.controls;
	}

	public onSubmit(): void {
		this.isSubmitted = true;
		if (this.motorInfoFormGroup.invalid) {
			return;
		}
		this.nextButtonClicked.emit(this.motorInfoFormGroup.value);
	}
}
