export const environment = {
	production: false,
	_localUrl: "http://localhost:4200/",
	apiBaseUrl: "https://api-dev.insurancekarlo.com/",
	frontEndUrl: "https://dev.insurancekarlo.com/",
	cmsEndPoint: "https://cms-dev.insurancekarlo.com/",
	crmEndPoint: "https://crm-dev.insurancekarlo.com/",
	b2cEndPoint: "https://associates-dev.insurancekarlo.com/posp/login",
	apiKey: "anQK1JGTdU1ZcM7pOSwDACuThCfVNDO2kLvKyUw8",
	secretKey: "RIBPL12345678RIBPL",
	userPath: "users/",
	apiBasePath: {
		car: {
			// bajajgeneral: "bajaj-general/",
			godigit: "godigit/",
			reliancegeneral: "reliance-general/",
			// shriramgeneral: "shriram-general/",
			hdfcergo: "hdfc-ergo/",
		},
		bike: {
			// bajajgeneral: "bajaj-general/",
			godigit: "godigit/",
			// tataaig: "tata-aig/",
			reliancegeneral: "reliance-general/",
			// shriramgeneral: "shriram-general/",
			hdfcergo: "hdfc-ergo/",
			// kotakgeneral: "kotak-general/",
		},
		commercial: {
			bajajgeneral: "bajaj-general/",
		},
    health: {
      godigit: "godigit/",
    }
	},
  assetsPath: "assets/json/",
	trainingPath: "assets/training-materials/",
	examinationsPath: "assets/pos-exam/",
	serviceName: {
		premiumService: "calculate-premium",
		proposalService: "generate-quote",
		paymentService: "make-payment",
		policyService: "generate-policy",
		downloadService: "download-policy",
		searchService: "get-policy-details",
		orders: "orders",
		createLead: "leads",
		getLeadById: "leads/",
		updateLead: "leads/",
		generateSortUrl: "generate-sort-url",
		sendEmail: "send-email",
		sendPaymentLink: "send-payment-link-to-customer",
		joinOurNetwork: "lead-users",
		login: "login",
		setPassword: "set-password",
		forgotPassword: "forgot-password",
		resetPassword: "reset-password",
		orderList: "orders",
		pospLeads: "lead-users",
		register: 'users/register',
		upload: "upload",
		cognitoUser: 'registered-users',
		newUser: 'new-user',
		dynamoLeads: 'leads',
		userTrainingHistory: 'user-training-history/',
		userExaminations: 'user-examinations/',
		trainingItem: 'list-training-items',
		updateUser: 'users/user-profile/',
		refreshToken: 'users/refresh-token',
		vehicleMaster: 'vehicle-master',
    validateKyc: "validate-kyc",
    uploadKyc: "upload-kyc-document",
    kycStatus: "kyc-status",
    leadUsersKyc: 'lead-users-kyc'
	},
	emailTemplateType: {
		paymentLink: "paymentLink",
		quotation: "quotationLink"
	},
	debugMode: true,
	loggingLevel: {
		info: false,
		error: true,
		debug: false,
		warn: false
	},
	features: {
		isDisableLinks: false,
		isCommercialVehicle: false
	},
	insuranceProviders: {
		bajajgeneral: "bajaj-general",
		godigit: "godigit",
		tataaig: "tata-aig",
		reliancegeneral: "reliance-general",
		shriramgeneral: "shriram-general",
		hdfcergo: "hdfc-ergo",
		kotakgeneral: "kotak-general",
	},
	defaultTimeoutSeconds: 21600
}
