import { Component, Input, EventEmitter, Output, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/features/services/common.service';

@Component({
	selector: 'app-communication-information-details',
	templateUrl: './communication-information-details.component.html',
	styleUrls: ['./communication-information-details.component.scss']
})
export class CommunicationInformationDetailsComponent implements OnInit {
	@Input() crmObj;
	commInfoForm: FormGroup;
	public pinCodeData:Array<any>;
	public isFetching: boolean = false;
  public isReadOnly: boolean = false;
	@Output() nextButtonClicked: EventEmitter<any> = new EventEmitter<any>();

	constructor(
		private _formBuilder: FormBuilder,
		private commonService: CommonService
	) { }

	ngOnInit(): void {
		this.createCommunicationForm();
    this.setReadOnlyFlag();
	}

  setReadOnlyFlag(): void {
    this.isReadOnly = this.crmObj.kyc.isKycCompleted
  }


	ngOnChanges(changes: SimpleChanges) {
        this.crmObj = changes.crmObj.currentValue;
		this.createCommunicationForm();
	}
	public createCommunicationForm():void{
		this.commInfoForm = this._formBuilder.group({
			addressLine1: [this.crmObj.address.registered.addressLine1, Validators.required],
			addressLine2: [this.crmObj.address.registered.addressLine2, Validators.required],
			postalCode: [this.crmObj.address.registered.postalCode, Validators.required],
			city: [this.crmObj.address.registered.city, Validators.required],
			state: [this.crmObj.address.registered.state, Validators.required],
			comAddressLine1: [this.crmObj.address.communication.addressLine1, Validators.required],
			comAddressLine2: [this.crmObj.address.communication.addressLine2, Validators.required],
			comPostalCode: [this.crmObj.address.communication.postalCode, Validators.required],
			comCity: [this.crmObj.address.communication.city, Validators.required],
			comState: [this.crmObj.address.communication.state, Validators.required],
			sameAsRegisteredAddress:[this.crmObj.address.sameAsRegisteredAddress]
		});
	}

	public nextStep(evt) {
		if (this.commInfoForm.invalid) {
			return;
		}
		this.nextButtonClicked.emit(this.commInfoForm.value);
	}


	public callPincodeAPI(inputName): void {
		this.isFetching = true;
		const pinCode = this.commInfoForm.controls[inputName].value;
		if (pinCode.toString().length == 6) {
			this.commonService.getCityByPincode().subscribe((response) => {
				let pincodeList = response.filter((x) => x.Pincode == pinCode)
				this._resetStateAndCity(pincodeList, inputName);
			},(error) => {
				this._resetStateAndCity([], inputName);
			})
		}
	}

	public onAddressFlagChange(event):void{
		this.commInfoForm.patchValue({
			comAddressLine1: (event.checked)?this.commInfoForm.controls["addressLine1"].value:"",
			comAddressLine2: (event.checked)?this.commInfoForm.controls["addressLine2"].value:"",
			comPostalCode: (event.checked)?this.commInfoForm.controls["postalCode"].value:"",
			comCity: (event.checked)?this.commInfoForm.controls["city"].value:"",
			comState: (event.checked)?this.commInfoForm.controls["state"].value:"",
		})
	}

	private _resetStateAndCity(pincodeList:Array<any>, inputName):void {
		this.pinCodeData = pincodeList;
		this.isFetching = false;
		this.pathValueToInput(inputName);
	}

	private pathValueToInput(inputName):void{
		if(inputName == "postalCode"){
			this.commInfoForm.patchValue({
				city: (this.pinCodeData)?this.pinCodeData[0].District:"",
				state: (this.pinCodeData)?this.pinCodeData[0].State:""
			})
		}else{
			this.commInfoForm.patchValue({
				comCity: (this.pinCodeData)?this.pinCodeData[0].District:"",
				comState: (this.pinCodeData)?this.pinCodeData[0].State:""
			})
		}
	}

}

