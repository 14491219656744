import { Injectable } from "@angular/core";
import { LoggerService } from "src/app/core/logger/logger.service";
import { environment } from "src/environments/environment";
import { policyTypeArr } from "src/app/features/policy-config"
import { encryptStorage } from 'src/app/core/services/encryptStorage';
import { CrmFlowService } from "./crm-flow.service";

@Injectable({
	providedIn: "root",
})
export class HelperService {
	private apiBaseUrl = environment.apiBaseUrl;
	private apiBasePath = environment.apiBasePath;
	private serviceName = environment.serviceName

	constructor(
    private logger: LoggerService,
    private crmFlow: CrmFlowService
  ) { }

	public compareQuoteApiEndPoint(productType) {
		let endPointObj = []
		for (const [key, value] of Object.entries(
			this.apiBasePath[productType]
		)) {
			endPointObj.push(`${this.apiBaseUrl}${value}${this.serviceName.premiumService}`);
		}
		return endPointObj;
	}

	public createProposalApiEndPoint(insuranceProvider, productType) {
		const slug = this.generateSlug(insuranceProvider, productType);
		const apiEndPoint = `${this.apiBaseUrl}${slug}${this.serviceName.proposalService}`;
		return apiEndPoint;
	}

  public createKycApiEndPoint(insuranceProvider, productType) {
		const slug = this.generateSlug(insuranceProvider, productType);
		const apiEndPoint = `${this.apiBaseUrl}${slug}${this.serviceName.validateKyc}`;
		return apiEndPoint;
	}

	public makePaymentApiEndPoint(insuranceProvider, productType) {
		const slug = this.generateSlug(insuranceProvider, productType);
		return `${this.apiBaseUrl}${slug}${this.serviceName.paymentService}`;
	}

	public generatePolicyApiEndPoint(insuranceProvider, productType) {
		const slug = this.generateSlug(insuranceProvider, productType);
		return `${this.apiBaseUrl}${slug}${this.serviceName.policyService}`
	}

	public downloadPolicyApiEndPoint(insuranceProvider, productType) {
		const slug = this.generateSlug(insuranceProvider, productType);
		return `${this.apiBaseUrl}${slug}${this.serviceName.downloadService}`
	}

	public searchPolicyApiEndPoint(insuranceProvider, productType) {
		const slug = this.generateSlug(insuranceProvider, productType);
		return `${this.apiBaseUrl}${slug}${this.serviceName.searchService}`
	}

	public generateSlug(insuranceProvider, productType) {
		const providerName = insuranceProvider.split("-").join("");
		return this.apiBasePath[productType][providerName];
	}

	public quickQuoteApiPayload(inputObj) {
    let payload;
    if(inputObj.commonFields.productType != "health"){
      payload = this.createPayloadForMotor(inputObj);
    }else{
      payload = this.createPayloadForHealth(inputObj);
    }

		return payload;
	}

  public createPayloadForMotor(inputObj){
    let payload = {
			enquiryId: inputObj.leadId,
			productType: inputObj.commonFields.productType,
			productSubType: (inputObj.commonFields.productType) ? inputObj.commonFields.productType : "",
			insuranceType: inputObj.newPolicyInfo.newPolicyType,
			policyHolderType: inputObj.policyHolderInfo.policyholderType,
			riskStartDate: inputObj.newPolicyInfo.riskStartDate,
			riskEndDate: inputObj.newPolicyInfo.riskEndDate,
			motorProfile: inputObj.motorProfile,
			prePolicyInfo: (!inputObj.motorProfile.isNew) ? inputObj.prePolicyInfo : null,
			ncbInfo: (!inputObj.motorProfile.isNew) ? inputObj.ncbInfo : null,
			tpPolicyInfo: (inputObj.newPolicyInfo.newPolicyType == policyTypeArr[1]) ? inputObj.tpPolicyInfo : null,
			cpaInfo: inputObj.cpaInfo,
			addons: inputObj.extra.addons,
			pincode: (inputObj.address?.registered?.postalCode == null) ? "" : inputObj.address?.registered?.postalCode,
			pospInfo: null,
			isBreakin: inputObj.newPolicyInfo.isBreakin
		}
		if (inputObj.newPolicyInfo.idvType == "set") {
			payload["selectedIDV"] = inputObj.newPolicyInfo.idv;
		}
    return payload;
  }

  public createPayloadForHealth(inputObj){
    let payload = {
			enquiryId: inputObj.leadId,
			productType: inputObj.commonFields.productType,
			insuranceType: inputObj.newPolicyInfo.newPolicyType,
			policyHolderType: inputObj.policyHolderInfo.policyholderType,
			riskStartDate: inputObj.newPolicyInfo.riskStartDate,
			riskEndDate: inputObj.newPolicyInfo.riskEndDate,
			// motorProfile: inputObj.motorProfile,
			// prePolicyInfo: (!inputObj.motorProfile.isNew) ? inputObj.prePolicyInfo : null,
			ncbInfo: (!inputObj.motorProfile.isNew) ? inputObj.ncbInfo : null,
			// tpPolicyInfo: (inputObj.newPolicyInfo.newPolicyType == policyTypeArr[1]) ? inputObj.tpPolicyInfo : null,
			// cpaInfo: inputObj.cpaInfo,
			addons: inputObj.extra.addons,
			pincode: (inputObj.address?.registered?.postalCode == null) ? "" : inputObj.address?.registered?.postalCode,
			pospInfo: null,
			// isBreakin: inputObj.newPolicyInfo.isBreakin
		}
		// if (inputObj.newPolicyInfo.idvType == "set") {
		// 	payload["selectedIDV"] = inputObj.newPolicyInfo.idv;
		// }
    return payload;
  }

	public createQuoteApiPayload(inputObj) {
		let payload = this.quickQuoteApiPayload(inputObj);
		let quotationId = inputObj.newPolicyInfo.quoteId;
		if (inputObj.newPolicyInfo.workFlowId) {
			quotationId = quotationId + "-" + inputObj.newPolicyInfo.workFlowId;
		}
		const addresses = {
			registered: inputObj.address.registered,
			communication: inputObj.address.communication
		};
		payload["personalInfo"] = inputObj.policyHolderInfo;
		payload["addresses"] = addresses;
		payload["nominee"] = {
			"name": inputObj.nomineeInfo.name,
			"age":inputObj.nomineeInfo.age,
			"relation":inputObj.nomineeInfo.relation
		}
		payload["quotationId"] = quotationId;
    payload["proposalId"] = inputObj.newPolicyInfo.proposalNo;
		payload["quotationDate"] = inputObj.newPolicyInfo.riskStartDate;
		payload["selectedIDV"] = inputObj.newPolicyInfo.idv;
		payload["providerName"] = inputObj.newPolicyInfo.insuranceProvider;
		payload["systemId"] = payload.enquiryId;
    if(inputObj.commonFields.productType != "health"){
      payload["financer"] = {
        "isFinanced": inputObj.extra.isFinanced,
        "name": inputObj.extra.financierName,
        "pincode": inputObj.extra.financierPincode,
        "city": inputObj.extra.financierCity
      }
      if (payload.addons.aaieDiscount) {
        payload["aaimName"] = inputObj.extra.aaimName;
        payload["membershipNo"] = inputObj.extra.membershipNo;
        payload["membershipExpiryDate"] = inputObj.extra.membershipExpiryDate;
      }
    }

    payload["kyc"] = inputObj.kyc;

		return payload;
	}

	public payloadForSearchPolicy(inputObj) {
		return {
			policyNo: inputObj
		}
	}

	public sumPremium(obj: object) {
		var sum = 0;
		for (var el in obj) {
			if (obj.hasOwnProperty(el)) {
				if (obj[el]) {
					sum += parseFloat(obj[el]);
				}
			}
		}
		return sum;
	}

	public deepTrim(obj: any) {
		for (var prop in obj) {
			var value = obj[prop],
				type = typeof value;
			if (
				value != null &&
				(type == "string" || type == "object") &&
				obj.hasOwnProperty(prop)
			) {
				if (type == "object") {
					this.deepTrim(obj[prop]);
				} else {
					obj[prop] = obj[prop].trim();
				}
			}
		}
		return obj;
	}

	public generateSortUrl(inputObj) {
		return { "enquiryId": environment.secretKey + inputObj.leadId + environment.secretKey }
	}

  public validateKycApiPayload(crmObj,inputObj) {
    return {
      "kycStatus": inputObj.isKycCompleted,
      "docTypeCode": inputObj.kycDocType,
      "docNumber": inputObj.kycDocNumber,
      "panNo": inputObj.panNo,
      "quotationId": crmObj.newPolicyInfo.quoteId,
      "dob": this.crmFlow.formatDate(inputObj.dob),
      "productType":crmObj.commonFields.productType,
      "insuranceType":crmObj.newPolicyInfo.newPolicyType,
      "isNew":crmObj.motorProfile.isNew,
      "policyHolderType":crmObj.policyHolderInfo.policyholderType
    }
  }

  public generateVerifyKycUrl(insuranceProvider){
    return `${this.apiBaseUrl}${insuranceProvider}/${this.serviceName.validateKyc}`
  }

	public readFileAsync(file) {
		return new Promise((resolve, reject) => {
			let reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				resolve(reader.result);
			};
			reader.onerror = reject;
		})
	}
}
