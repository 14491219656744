<div class="card p-3">
	<div class="row">
		<div class="col-12 col-md-6 pb-1 mb-3 pb-lg-0 mb-lg-0"
			[ngClass]="crmObj.newPolicyInfo.newPolicyType != 'TP' ? 'col-lg-3 summary-border-right' : 'col-lg-4 summary-border-right'"
			ngClass.lt-sm="summary-border-bottom pb-2">
			<app-category-selection-details [motorProfile]="crmObj.motorProfile"></app-category-selection-details>
		</div>
		<div class="col-12 col-md-6 pb-3 mb-3 pb-lg-0 mb-lg-0"
			[ngClass]="crmObj.newPolicyInfo.newPolicyType != 'TP' ? 'col-lg-3 summary-border-right' : 'col-lg-4 summary-border-right'"
			ngClass.lt-sm="summary-border-bottom pb-3">
			<app-new-policy-selection (newPolicyChanged)="onTriggerNewPolicyUpdate($event)" [crmObj]="crmObj">
			</app-new-policy-selection>
		</div>
		<div *ngIf="crmObj.newPolicyInfo.newPolicyType != 'TP'"
			class="col-12 col-md-6 col-lg-3 pb-3 mb-3 pb-lg-0 mb-lg-0" ngClass="summary-border-right"
			ngClass.lt-sm="summary-border-bottom pb-3">
			<app-idv-selection [crmObj]="crmObj" [minIDV]="minIDV" [maxIDV]="maxIDV"
				(onIdvUpdate)="onTriggerIdvUpdate($event)"></app-idv-selection>
		</div>
		<div class="col-12 col-md-6" [ngClass]="crmObj.newPolicyInfo.newPolicyType != 'TP' ? 'col-lg-3' : 'col-lg-4'">
			<app-owner-type-selection (onOwnerChange)="onTriggerOwnerChange($event)"
				[ownerType]="crmObj.policyHolderInfo.policyholderType"></app-owner-type-selection>
		</div>
	</div>
</div>