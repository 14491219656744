<div class="row">
	<div class="col">
		<div class="card">
			<h3 class="card-header text-heading mb-0">
				{{section.title}}
			</h3>
			<div class="card-body" *ngIf="section.section == 'motorProfile'">
				<div class="row">
					<div class="col-sm-12 col-md-12">
						<p class="card-text">
							<strong>
								{{quote.make}} {{quote.model}} {{quote.variant}} - {{quote.fuelType}}
							</strong>
						</p>
					</div>
					<div class="col-sm-12 col-md-6">
						<p class="card-text">
							<span><span
									class="pr-5">Registration#:</span><strong>{{quote.registrationNo}}</strong></span><br />
							<span><span class="pr-5">Registration Date:</span><strong>{{quote.registrationDate | date:
									"dd-MM-yyyy"}}</strong></span>
						</p>
					</div>
					<div class="col-sm-12 col-md-6">
						<p class="card-text">
							<span><span class="pr-5">Engine#:</span><strong>{{quote.engineNo}}</strong></span><br />
							<span><span class="pr-5">Chassis#:</span><strong>{{quote.chassisNo }}</strong></span>
						</p>
					</div>
				</div>
			</div>

			<div class="card-body" *ngIf="section.section == 'previousPolicy' || section.section == 'tpPolicy'">
				<div class="row">
					<div class="col-sm-12 col-md-6">
						<p class="card-text">
							<span><span class="pr-5">#:</span><strong>{{quote.policyNo}}</strong></span><br />
							<span><i class="fa fa-calendar pr-5" aria-hidden="true"></i><strong>{{quote.riskStartDate |
									date:
									"dd-MM-yyyy"}}</strong></span>
						</p>
					</div>
					<div class="col-sm-12 col-md-6">
						<p class="card-text">
							<span><i
									class="fa fa-building-o pr-5"></i><strong>{{quote.insurerName}}</strong></span><br />
							<span><i class="fa fa-calendar pr-5" aria-hidden="true"></i><strong>{{quote.riskEndDate |
									date : "dd-MM-yyyy"
									}}</strong></span>
						</p>
					</div>
				</div>
			</div>

			<div class="card-body" *ngIf="section.section == 'personal'">
				<div class="row">
					<div class="col-sm-12 col-md-12">
						<p class="card-text">
							<strong *ngIf="quote.policyholderType == 'Individual'">
								{{quote.firstName}} {{quote.lastName}}
								<!-- (<i class="pr-5" [ng-class]="{'fa fa-male': quote.gender == 'Male'}" aria-hidden="true"></i>) -->
							</strong>
							<strong *ngIf="quote.policyholderType == 'Corporate'">
								{{quote.companyName}}
							</strong>
						</p>
					</div>
					<div class="col-sm-12 col-md-6">
						<p class="card-text">
							<span><i class="fa fa-envelope pr-5" aria-hidden="true"></i>{{quote.email}}</span><br />
							<span *ngIf="quote.policyholderType == 'Individual'"><i class="fa fa-user pr-5"
									aria-hidden="true"></i>{{quote.maritalStatus}}</span>
						</p>
					</div>
					<div class="col-sm-12 col-md-6">
						<p class="card-text">
							<span><i class="fa fa-phone pr-5"
									aria-hidden="true"></i><strong>{{quote.mobile}}</strong></span><br />

							<span *ngIf="quote.policyholderType == 'Individual'"><i class="fa fa-calendar pr-5"
									aria-hidden="true"></i>{{quote.dob | date: "dd-MM-yyyy"}}</span><br />
						</p>
					</div>
				</div>
			</div>

			<div class="card-body" *ngIf="section.section == 'addresses'">
				<div class="row">
					<div class="col-sm-12 col-md-12">
						<p class="card-text">
							<i class="fa fa-address-card pr-5" aria-hidden="true"></i>
							{{quote.communication.addressLine1}},
							{{quote.communication.addressLine2}},
							{{quote.communication.city}},
							{{quote.communication.state}},
							{{quote.communication.postalCode}}
						</p>
						<p class="card-text">
							<i class="fa fa-registered pr-5" aria-hidden="true"></i>
							{{quote.registered.addressLine1}},
							{{quote.registered.addressLine2}},
							{{quote.registered.city}},
							{{quote.registered.state}},
							{{quote.registered.postalCode}}
						</p>
					</div>
				</div>
			</div>

			<div class="card-body" *ngIf="section.section == 'nominee'">
				<div class="row">
					<div class="col-sm-12 col-md-4">
						<p class="card-text">
							<span class="pr-5">{{quote.name}}</span>
						</p>
					</div>
					<div class="col-sm-12 col-md-4">
						<p class="card-text">
							<span class="pr-5">{{quote.age}} years</span>
						</p>
					</div>
					<div class="col-sm-12 col-md-4">
						<p class="card-text">
							<span class="pr-5">{{quote.relation}}</span>
						</p>
					</div>
				</div>
			</div>

			<div class="card-body" *ngIf="section.section == 'ncb'">
				<div class="row">
					<div class="col-sm-12 col-md-4">
						<p class="card-text">
							<label>
								<i class="fa pr-5" [ngClass]="!quote.isNcb? 'fa-check': 'fa-times'"
									aria-hidden="true"></i> Claim Taken
							</label><br />
							<label *ngIf="quote.isNcb" class="pr-5">{{quote.currentNcb}}%</label>
						</p>
					</div>
				</div>
			</div>

			<div class="card-body" *ngIf="section.section == 'addons'">
				<div class="row">
					<div class="col-sm-12 col-md-4">
						<p class="card-text">
							<label *ngFor="let item of quote | keyvalue">
								<span *ngIf="item.value">
									<i class="fa fa-check pr-5" aria-hidden="true"></i> {{item.key}}
								</span>
							</label><br />
						</p>
					</div>
				</div>
			</div>

			<div class="card-body" *ngIf="section.section == 'extra'">
				<div class="row">
					<div class="col-sm-12 col-md-4">
						<p class="card-text">
							<span class="pr-5">{{quote}}</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>