<div class="card mt-3">
	<h3 class="card-header text-heading mb-0">Personal Info</h3>
	<div class="card-body">
		<form [formGroup]="personalInfoFormGroup" class="bg-white personal-info-form-section">
			<div class="row pb-3">
				<div class="col-sm-12 col-lg-6">
					<mat-form-field class="w-100" appearance="outline">
						<input [readonly]="isReadOnly" matInput formControlName="firstName" placeholder="Enter Policyholder First Name"
							autocomplete="new-firstName" />
						<mat-error *ngIf="
              personalInfoFormGroup['controls'].firstName.invalid &&
              personalInfoFormGroup['controls'].firstName.dirty
            ">
							Policyholder First Name Required
						</mat-error>
					</mat-form-field>
				</div>
				<div class="col-sm-12 col-lg-6">
					<mat-form-field class="w-100" appearance="outline">
						<input [readonly]="isReadOnly" matInput formControlName="lastName" placeholder="Enter Policyholder Last Name"
							autocomplete="new-lastName" />
						<mat-error *ngIf="
              personalInfoFormGroup['controls'].lastName.invalid &&
              personalInfoFormGroup['controls'].lastName.dirty
            ">
							Policyholder Last Name Required
						</mat-error>
					</mat-form-field>
				</div>
			</div>
			<div class="row pb-3">
				<div class="col-sm-12 col-lg-6">
					<mat-form-field class="w-100" appearance="outline">
						<input  matInput formControlName="email" placeholder="Enter Policyholder Email id"
							autocomplete="off" />
						<mat-error *ngIf="
              personalInfoFormGroup['controls'].email.invalid &&
              personalInfoFormGroup['controls'].email.dirty
            ">
							Policyholder email Required
						</mat-error>
					</mat-form-field>
				</div>

				<div class="col-sm-12 col-lg-6">
					<mat-form-field class="w-100" appearance="outline">
						<input  matInput formControlName="mobile" placeholder="Enter Policyholder Phone number"
							maxlength="10" autocomplete="off" />
						<mat-error *ngIf="
              personalInfoFormGroup['controls'].mobile.invalid &&
              personalInfoFormGroup['controls'].mobile.dirty
            ">
							Policyholder phone number Required
						</mat-error>
					</mat-form-field>
				</div>
			</div>
			<div class="row pb-3">
				<div class="col-sm-12 col-lg-4">
					<mat-form-field class="w-100" appearance="outline">
						<input [readonly]="isReadOnly" matInput [matDatepicker]="picker" onkeydown="return false" [max]="ownerDOBLimit"
							placeholder="Date Of Birth" formControlName="dob" tabindex="2" autocomplete="new-dob" />
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-datepicker #picker ngDefaultControl></mat-datepicker>
						<mat-error *ngIf="
              personalInfoFormGroup['controls'].dob.invalid &&
              personalInfoFormGroup['controls'].dob.dirty
            ">
							Policyholder date of birth is required
						</mat-error>
					</mat-form-field>
				</div>
				<div class="col-sm-12 col-lg-4">
					<mat-form-field class="w-100" appearance="outline">
						<mat-select formControlName="gender">
							<mat-option value="">Gender</mat-option>
							<mat-option value="{{ option }}" *ngFor="let option of genderArr; let i = index">
								{{ option }}
							</mat-option>
						</mat-select>
						<mat-error *ngIf="
              personalInfoFormGroup['controls'].gender.invalid &&
              personalInfoFormGroup['controls'].gender.dirty
            ">
							Policyholder Gender is required
						</mat-error>
					</mat-form-field>
				</div>
				<div class="col-sm-12 col-lg-4">
					<mat-form-field class="w-100" appearance="outline">
						<mat-select formControlName="maritalStatus">
							<mat-option value="null">Marital Status</mat-option>
							<mat-option value="{{ option }}" *ngFor="
                let option of maritalStatusArr;
                let i = index
              ">
								{{ option }}
							</mat-option>
						</mat-select>
						<mat-error *ngIf="
              personalInfoFormGroup['controls'].maritalStatus.invalid &&
              personalInfoFormGroup['controls'].maritalStatus.dirty
            ">
							Policyholder Marital Status is required
						</mat-error>
					</mat-form-field>
				</div>
			</div>
			<div class="row pb-3">
				<div class="col-sm-12 col-lg-6">
				<mat-form-field class="w-100" appearance="outline">
					<input [readonly]="isReadOnly" matInput formControlName="panNo" placeholder="Enter Policyholder Pan No"
					autocomplete="off" minlength="10" maxlength="10"/>
          <mat-error *ngIf="
              personalInfoFormGroup['controls'].panNo.invalid &&
              personalInfoFormGroup['controls'].panNo.dirty
            ">
							Policyholder Pan No Required
						</mat-error>
				</mat-form-field>
			</div>
			<div class="row">
				<div class="col">
					<div class="text-center mt-4 mb-3">
						<button class="btn btn-default btn-real" matStepperPrevious>Back</button>
						<button class="btn btn-default btn-real btn-selected ms-4"
							(click)="nextStep($event)">Next</button>
					</div>
				</div>
			</div>
	</div>
</form>
</div>
