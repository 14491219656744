import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PaymentConfirmationComponent } from './features/components/payment/payment-confirmation/payment-confirmation.component';
import { ThankYouComponent } from './features/components/thank-you/thank-you.component';
import { ProposalComponent } from './features/components/proposal/proposal.component';
import { KycConfirmationComponent } from './features/components/kyc-confirmation/kyc-confirmation.component';

const routes: Routes = [
	{
		path: "",
		loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
	},
	{
		path: "feature",
		loadChildren: () =>
			import("./features/features.module").then((m) => m.FeaturesModule),
	},
	{
		path: "confirmation",
		component: PaymentConfirmationComponent
	},
	{
		path: "thank-you/:leadId",
		component: ThankYouComponent
	},
  {
		path: "kyc-confirmation/:leadId",
		component: KycConfirmationComponent
	},
	{
		path: "proposal",
		component: ProposalComponent,
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		anchorScrolling: 'enabled'
	})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
