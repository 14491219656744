import { environment as defaultEnvironment } from "./environment.default";

export const environment = {
  ...defaultEnvironment,
  apiBaseUrl: "https://api.insurancekarlo.com/",
  frontEndUrl: "https://www.insurancekarlo.com/",
  cmsEndPoint:"https://cms.insurancekarlo.com/",
  crmEndPoint:"https://crm.insurancekarlo.com/",
  b2cEndPoint: "https://associates.insurancekarlo.com/posp/login",
  apiKey:"sp9HZkFrAQ1amAzwTc9vJ4DI4EtxT4E975h7Bvaj",
  production: true,
  name: "production",
  logMessage:
    "Angular is running.",
  debugMode: false,
  loggingLevel: {
    info: false,
    error: true,
    debug: false,
    warn: false,
  },
  features:{
    isDisableLinks:true,
    isCommercialVehicle:false
  }
};
