import { Component, OnInit, Input } from '@angular/core';
import { CrmFlowService } from '../../services/crm-flow.service';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { leadStatus } from '../../policy-config';
import { LeadResponse } from '../../interfaces/leadResponse';
import { Router } from '@angular/router';
import { InsuranceService } from '../../services/insurance.service';

@Component({
	selector: 'app-new-proposal',
	templateUrl: './new-proposal.component.html',
	styleUrls: ['./new-proposal.component.scss'],
})
export class NewProposalComponent implements OnInit {
	@Input() public crmObj;
	public crmId: string;
	@Input() public steps;
	constructor(
		private router: Router,
		private crmFlow: CrmFlowService,
		private logger: LoggerService,
		private insuranceService: InsuranceService
	) { }

	ngOnInit(): void { 
		this.setCrmId();
	}

	public setCrmId(): void {
		this.crmId = this.crmObj?.id;
	}

	public generateNewLead(): void {
		this.crmObj["commonFields"]["leadStatus"] = leadStatus.junkLead; 
		this.callUpdateLeadApi(this.crmObj);
	}

	public callUpdateLeadApi(leadPayload): void {
		this.crmFlow.updateApi(this.crmId, leadPayload).subscribe(
			(leadResponse: LeadResponse) => {
				this.clearSession();
			},
			(error) => {
				this.logger.info(error);
			}
		);
	}

	public clearSession() :void {
		this.insuranceService.clearValue("leadReqId");
		this.insuranceService.clearValue("productType");
		this.insuranceService.clearValue("isNewVehicle");
		this.insuranceService.clearValue("vehicleCC");
		this.insuranceService.clearValue("premiumBreakup");
		this.insuranceService.clearValue("vehicleIDV");
		this.insuranceService.clearValue("productSubType");
		this.router.navigate(['/']);
	}
}
