<div class="row mt-4">
    <div>
        <h2 class="text-center"><strong>CHECKOUT</strong></h2>
        <p class="text-center">Fill the form below → Review details → Get policy</p>
    </div>
</div>
<div class="mt-4 row header-row text-center">
    <div *ngFor="let item of items; let i = index" class="custom-col col-md-3 col-sm-6 col-6 text-center"
        [ngClass]="selectedIndex == i ? 'selected' : ''">
        <a (click)="selectHeader('motor');">{{selectedIndex}}{{i}}
            <img src="/assets/images/car.png" height="100" class="img_responsive" title="Car Insurance" />
        </a>
    </div>
</div>