import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule} from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';

import { RealModalComponent } from './real-modal/real-modal.component';
// import { InsuranceTypeComponent } from '../features/components/insurance/insurance-type/insurance-type.component';
import { LoaderComponent } from './loader/loader.component';

@NgModule({
  declarations: [
    RealModalComponent,
    // InsuranceTypeComponent,
    LoaderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MatButtonModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule
  ],
  exports: [
    RealModalComponent,
    // InsuranceTypeComponent,
    LoaderComponent
  ],
  providers:[
  ]
})
export class CommonRealModule { }
