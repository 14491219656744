import { Component, Input, EventEmitter, Output, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { CommonService } from "src/app/features/services/common.service";
import { policyTypeArr } from "src/app/features/policy-config"
import { environment } from 'src/environments/environment.default';

@Component({
	selector: 'app-previous-insurance-details',
	templateUrl: './previous-insurance-details.component.html',
	styleUrls: ['./previous-insurance-details.component.scss']
})
export class PreviousInsuranceDetailsComponent implements OnInit {
	@Input() crmObj;
	public isSubmitted: boolean = false;
	previousPolicyForm: FormGroup;
	policyTypeArr: Array<any> = policyTypeArr;
	public insurerData: Array<any> = [];
	@Output() nextButtonClicked: EventEmitter<any> = new EventEmitter<any>();
	public preInsurerName: String;
	public tpInsurerName: String;
	public isDisabled: boolean = true;
	public selectedInsurer: any;
	public isBlock: boolean = false;
	public modifiedPolicyList: Array<any> = ["Comprehensive", "TP"]
	public isPreviousPolicyExist: boolean;

	constructor(
		private _formBuilder: FormBuilder,
		private commonService: CommonService,
		private logger: LoggerService
	) { }

	ngOnInit(): void {

		this.isPreviousPolicyExist = this.crmObj.prePolicyInfo.isPreviousPolicy;
		this.getInsuranceData();
		this.setInsurerName();
		this.previousPolicyForm = this.createPrePolicyFormGroup();
		if (this.crmObj.newPolicyInfo.newPolicyType == this.policyTypeArr[1]) {
			this.setFormValidationForSaod();
			if(this.crmObj.newPolicyInfo.insuranceProvider != environment.insuranceProviders.reliancegeneral){
				this.modifiedPolicyList = ["Comprehensive", "TP"];
			}else{
				this.modifiedPolicyList = ["Comprehensive", "SAOD"];
			}
		}
		if (!this.isPreviousPolicyExist) {
			this.removeValidationForBreakin();
		}
	}
	ngOnChanges(changes: SimpleChanges) {
		this.crmObj = changes.crmObj.currentValue;
		this.previousPolicyForm = this.createPrePolicyFormGroup();
		if (this.crmObj.newPolicyInfo.newPolicyType == this.policyTypeArr[1]) {
			this.setFormValidationForSaod();
		}
	}
	public setInsurerName(): void {
		this.preInsurerName = (this.crmObj.prePolicyInfo.insurerName) ? this.crmObj.prePolicyInfo.insurerName : "";
		this.tpInsurerName = (this.crmObj.tpPolicyInfo.insurerName) ? this.crmObj.tpPolicyInfo.insurerName : "";
	}

	public onPreviousPolicyExist(event): void {
		this.isPreviousPolicyExist = event.checked;
		this.removeValidationForBreakin();
	}

	createPrePolicyFormGroup() {
		return this._formBuilder.group({
			isPreviousPolicy: [this.crmObj.prePolicyInfo.isPreviousPolicy],
			previousPolicyType: [this.crmObj.prePolicyInfo.policyType, Validators.required],
			insurerName: [this.preInsurerName, Validators.required],
			policyNo: [this.crmObj.prePolicyInfo.policyNo, Validators.required],
			tpInsurerName: [this.crmObj.tpPolicyInfo.insurerName],
			tpPolicyNo: [this.crmObj.tpPolicyInfo.policyNo],
			tpRiskStartDate: [{ value: this.crmObj.tpPolicyInfo.riskStartDate, disabled: true }],
			tpRiskEndDate: [{ value: this.crmObj.tpPolicyInfo.riskEndDate, disabled: true }]
		});
	}

	public setFormValidationForSaod(): void {
		const tpInsurerName = this.previousPolicyForm.get("tpInsurerName");
		const tpPolicyNo = this.previousPolicyForm.get("tpPolicyNo");
		const tpRiskStartDate = this.previousPolicyForm.get("tpRiskStartDate");
		const tpRiskEndDate = this.previousPolicyForm.get("tpRiskEndDate");
		tpInsurerName.setValidators([Validators.required]);
		tpRiskStartDate.setValidators([Validators.required]);
		tpRiskEndDate.setValidators([Validators.required]);
		tpInsurerName.updateValueAndValidity();
		tpPolicyNo.updateValueAndValidity();
		tpRiskStartDate.updateValueAndValidity();
		tpRiskEndDate.updateValueAndValidity();
	}

	public removeValidationForBreakin(): void {
		const previousPolicyType = this.previousPolicyForm.get("previousPolicyType");
		const insurerName = this.previousPolicyForm.get("insurerName");
		const policyNo = this.previousPolicyForm.get("policyNo");
		if (this.isPreviousPolicyExist) {
			previousPolicyType.setValidators([Validators.required]);
			insurerName.setValidators([Validators.required]);
			policyNo.setValidators([Validators.required]);
		} else {
			previousPolicyType.setValidators(null);
			insurerName.setValidators(null);
			policyNo.setValidators(null);
		}
		previousPolicyType.updateValueAndValidity();
		insurerName.updateValueAndValidity();
		policyNo.updateValueAndValidity();
	}

	get form() {
		return this.previousPolicyForm.controls;
	}
	public onSelect(evt) {
		// console.log(this.selectedInsurer);
	}
	public getInsuranceData(): void {
		this.commonService.getInsurerDetails(this.crmObj.newPolicyInfo.insuranceProvider).subscribe((data) => {
			this.insurerData = data;
		})
	}
	public onSubmit(): void {
		this.selectedInsurer = this.insurerData.filter((insurer) => {
			if (this.previousPolicyForm.value.insurerName == insurer.name) {
				return true;
			} else {
				return false;
			}
		});
    if (this.previousPolicyForm.invalid) {
			return;
		}
		if(this.crmObj.newPolicyInfo.insuranceProvider == environment.insuranceProviders.reliancegeneral){
			if (this.crmObj.newPolicyInfo.newPolicyType != policyTypeArr[2] && this.previousPolicyForm.value.previousPolicyType == policyTypeArr[2]) {
				this.isBlock = true;
				return;
			}
		}

		this.nextButtonClicked.emit(this.previousPolicyForm.value);
	}
}
