<app-loader *ngIf="isLoading"></app-loader>
<div class="container">
	<div class="row" >
    <div class="col-12" *ngIf="selectedInsuranceType !== productTypeObj[2].key">
      <app-motor-insurance (crmLeadObj)="onInsuranceFormSubmitted($event)" [selectedInsuranceType]="selectedInsuranceType"></app-motor-insurance>
    </div>
    <div class="col-12" *ngIf="selectedInsuranceType === productTypeObj[2].key">
      <app-health-insurance (crmLeadObj)="onInsuranceFormSubmitted($event)"></app-health-insurance>
    </div>
	</div>
</div>
