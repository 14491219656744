import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { ApiService } from 'src/app/core/services/api.service';
import { productType, purchaseType } from 'src/app/features/policy-config';
import { InsuranceService } from 'src/app/features/services/insurance.service';
import * as rtoData from 'server/RTO_RegistraionRegion.json';
import { CommonService } from 'src/app/features/services/common.service';


@Component({
  selector: 'app-motor-insurance',
  templateUrl: './motor-insurance.component.html',
  styleUrls: ['./motor-insurance.component.scss']
})
export class MotorInsuranceComponent implements OnInit {
  public isLoading: boolean = true;
  @Output() crmLeadObj = new EventEmitter<Object>();
  @Input() selectedInsuranceType
  selectedCar: string = purchaseType.old;
  public productTypeObj: Array<any> = productType;
	makeList: any[] = [];
	modelList: any[] = [];
	engineList: any[] = [];
	variantList: any[] = [];
	selectedMake: string = "";
	selectedModel: string = "";
	selectedEngine: string = "";
	selectedVariant: string = "";
	insuranceForm: FormGroup;
	filteredMakes: Observable<any> = new Observable();
	filteredModel: Observable<any> = new Observable();
	filteredEngines: Observable<any> = new Observable();
	filteredVariants: Observable<any> = new Observable();
	filteredPolicyExpired: Observable<any> = new Observable();
	filteredRto: Observable<any> = new Observable();
	renewItem: string = "";
	newItem: string = "";
	submitted: boolean = false;
	private rtoData: any;
	public registrationDate: any = new Date();
	public maxRegistrationDate: any;
	public minRegistrationDate: any;
  public purchaseTypeObj: any = purchaseType;

  constructor(
    private fb: FormBuilder,
		private insuranceService: InsuranceService,
		private apiService: ApiService,
		private logger: LoggerService,
    private commonService:CommonService
  ) { }

  ngOnInit(): void {
    this.defaultValues();
		this.insuranceForm = this.createFormGroup();
    this.rtoData = rtoData.default;


		let productCode;
		if (this.selectedInsuranceType === this.productTypeObj[0].key) {
			productCode = this.productTypeObj[0].productCode;
		} else if (this.selectedInsuranceType === this.productTypeObj[1].key) {
			productCode = this.productTypeObj[1].productCode;
		}else{
      this.isLoading = false;
    }

		if (productCode) {
			this.getVehicleMaster(productCode);
      this.getFilteredMakeList();
			this.setFormValidations();
			this.setRtoDropdown();
		}
  }

  setRtoDropdown() {
		this.filteredRto = this.insuranceForm.get('rto')!.valueChanges.pipe(
			startWith(''),
			map(item => (item ? this.insuranceService._filterRto(this.rtoData, item) : this.rtoData.slice()))
		);
	}

	getVehicleMaster(productCode) {
    this.loadVehicleMaster()
		// const payload = { "productCode": productCode }
		// this.apiService.getVehicleMaster(payload).subscribe(
		// 	(resp) => {
    //     this.isLoading = false;
    //     if(resp.length > 0){
    //       this.makeList = resp;
    //     }else{
    //       this.loadVehicleMaster()
    //     }
		// 	},
		// 	(error) => {
    //     this.loadVehicleMaster()
		// 		console.log(error);
		// 	}
		// );
	}

  loadVehicleMaster(): void {
    this.commonService.getVehicleMaster().subscribe((resp) => {
      const filteredResponse = resp.filter((d) =>
        d.categoryType.toLowerCase() == this.selectedInsuranceType
      )

      this.makeList = filteredResponse[0].tradeItems;
      this.logger.info(this.makeList)
      this.isLoading = false
    })
  }

  validateInput(c: any) {
		const RTO_NUM_REGEX = /[A-Z]{2}[0-9]{2}/g;
		const RTO_LOCATION_REGEX = /^[A-Z]+$/i;

		return (RTO_NUM_REGEX.test(c.value) || RTO_LOCATION_REGEX.test(c.value)) ? null : {
			invalid: true
		};
	}

  getQuotes(): void {
    this.submitted = true;
    if (this.insuranceForm.invalid) {
			return;
		}
    this.crmLeadObj.emit(this.insuranceForm.value);
		this.submitted = false;
  }

	ngOnChanges() {
    this.selectedInsuranceType = this.selectedInsuranceType ? this.selectedInsuranceType : "Car";
		this.renewItem = "Renew "+this.selectedInsuranceType;
		this.newItem = "New "+this.selectedInsuranceType;
	}

	defaultValues() {
		this.minRegistrationDate = new Date();
		this.maxRegistrationDate = new Date();
		this.minRegistrationDate.setFullYear(this.minRegistrationDate.getFullYear() - 25);
		this.maxRegistrationDate.setMonth(this.maxRegistrationDate.getMonth() - 7);
	}

	createFormGroup() {
		return this.fb.group({
      productType: [this.selectedInsuranceType],
      productSubType: [""],
      isNew: [false, Validators.required],
			make: ["", Validators.required],
			model: ["", Validators.required],
			engine: ["", Validators.required],
			variant: ["", Validators.required],
      cc: ["", Validators.required],
			rto: ["", [Validators.required, this.validateInput]],
			registrationDate: ["", Validators.required],
			mobile: ["", [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
			email: ["", [Validators.required, Validators.email]]
		});
	}

	get form() {
		return this.insuranceForm.controls;
	}

	setFormValidations() {
		const registrationDate = this.insuranceForm.get("registrationDate");
		if (this.selectedCar == this.purchaseTypeObj.old) {
			registrationDate?.setValidators([Validators.required]);
		} else {
			registrationDate?.setValidators(null);
		}
		registrationDate?.updateValueAndValidity();
	}

	getFilteredMakeList() {
		this.filteredMakes = this.insuranceForm.get('make')!.valueChanges.pipe(
			startWith(''),
			map(item => (item ? this.insuranceService._filterMakeData(this.makeList, item) : this.makeList.slice()))
		);
    this.logger.info(this.filteredMakes);
	}

	onCarSelectionChange(value: string) {
    this.selectedCar = value;
    const isNew = value === purchaseType.old ? false : true;
    setTimeout(() => {
      this.insuranceForm.patchValue({
        isNew: isNew
      });
      this.setFormValidations();
    }, 800)
	}

	onMakeChange(event: any) {
		this.selectedMake = event.option.value;
		this.selectedModel = "";
		this.selectedEngine = "";
		this.selectedVariant = "";
		this.modelList = [];
		this.variantList = [];
		let carModels = this.makeList.filter((item) =>
			item.itemName === this.selectedMake
		);
		this.modelList = carModels[0].models;

		this.filteredModel = this.insuranceForm.get('model')!.valueChanges.pipe(
			startWith(''),
			map(item => (item ? this.insuranceService._filterModelData(this.modelList, item) : this.modelList.slice()))
		);
	}

	onModelChange(event: any) {
		this.engineList = [];
		this.selectedModel = event.option.value;
		this.selectedEngine = "";
		this.selectedVariant = "";
		let variants = this.modelList.filter((item) =>
			item.modelName.includes(this.selectedModel)
		);
		let varList = variants[0].variant;

		var flags = [];
		for (let i = 0; i < varList.length; i++) {
			if (flags[varList[i].Operated_By]) continue;
			flags[varList[i].Operated_By] = true;
			this.engineList.push({
				fuelId: i,
				fuelType: varList[i].Operated_By
			});
		}

		this.filteredEngines = this.insuranceForm.get('engine')!.valueChanges.pipe(
			startWith(''),
			map(item => (item ? this.insuranceService._filterEngineData(this.engineList, item) : this.engineList.slice()))
		);
	}

	onEngineChange(event: any) {
		this.variantList = [];
		this.selectedEngine = event.option.value;
		this.selectedVariant = "";

		this.variantList = this.modelList.filter((item) =>
			item.modelName === this.selectedModel
		)[0].variant.filter((item) =>
			item.Operated_By === this.selectedEngine
		);

		this.filteredVariants = this.insuranceForm.get('variant')!.valueChanges.pipe(
			startWith(''),
			map(item => (item ? this.insuranceService._filterVariantData(this.variantList, item) : this.variantList.slice()))
		);
	}

	onVariantChange(event: any) {
		this.selectedVariant = event.option.value;
		const selectedItem = this.variantList.filter((item) => item.variantName === this.selectedVariant);
    this.insuranceForm.patchValue({
      cc: selectedItem[0].CC,
      productSubType: selectedItem[0].productSubType
    })
	}

}
