<app-loader *ngIf="isLoading"></app-loader>
<div class="card">
  <h3 class="card-header text-heading mb-0">Choose Gender</h3>
  <div class="card-body">
    <form [formGroup]="genderSelectionForm">
      <div class="row pb-3">
        <div class="col-12 pb-4">
          <ul class="gender-selection-container">
            <li [ngClass]="{'active': (isMale && item === maleInput) || (isFemale && item ===femaleInput) }"
              (click)="genderSelection(item)" *ngFor="let item of genderList;  let i = index">
              <div class="text-center text-heading">
                <i class="fa fa-{{item.toLowerCase()}}" aria-hidden="true"></i>
                <p class="m-0">{{item}} </p>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-12 text-center">
          <p *ngIf="isSubmitted && genderSelectionForm['controls'].gender.errors" class="text-bold text-danger">
            Please Choose your
            Gender to begin the process</p>
        </div>
        <div class="col-12 py-4 text-center">
          <button (click)="onSubmit()" class="btn btn-default btn-real btn-selected">Next</button>
        </div>
      </div>
    </form>
  </div>
</div>