import { Component, OnInit, Input, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
	selector: 'app-item-value',
	templateUrl: './item-value.component.html',
	styleUrls: ['./item-value.component.scss']
})
export class ItemValueComponent implements OnInit {
	@Input() section: any;
	@Input() quote: any;
	constructor() { }

	ngOnInit(): void {
	}

}
