<!-- <div class="plans mb-2"> -->
    <div class="row plans">
        <div class="col-12">
            <h4 class="mb-1">
                    {{ quoteResponseCount + 
                        (quoteResponseCount > 1 ? " Plans" : " Plan") + " found" }}
            </h4>
            <p class="body-copy-sm">
                Prices Exclusive of GST
            </p>
        </div>
    </div>
<!-- </div> -->