<app-loader *ngIf="isLoading"></app-loader>
<div class="card">
  <h3 class="card-header text-heading mb-0">One Last Stage</h3>
  <div class="card-body">
    <form (ngSubmit)="onSubmit()" autocomplete="off" [formGroup]="leadForm">
      <div class="row pb-3">
        <div class="offset-md-2 col-md-8 offset-lg-2 col-lg-8 pb-4 text-center">
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <div class="form-group row">
                <label class="col-sm-6 text-bold col-form-label" for="email">Email</label>
                <div class="col-sm-6">
                  <input type="text" autocomplete="off" formControlName="email" class="form-control" id="email"
                    [ngClass]="{ 'is-invalid': isSubmitted && form.email.errors }" />
                  <div *ngIf="isSubmitted && form.email.errors" class="invalid-feedback">
                    <span class="text-danger" *ngIf="form.email.errors.required">Email is required</span>
                    <span class="text-danger" *ngIf="form.email.errors.email">Email must be a valid email address</span>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="form-group row">
                <label class="col-sm-6 text-bold col-form-label" for="mobile">Contact No</label>
                <div class="col-sm-6">
                  <input type="text" autocomplete="off" formControlName="mobile" class="form-control" id="mobile"
                    maxlength="10" minlength="10" [ngClass]="{ 'is-invalid': isSubmitted && form.mobile.errors }" />
                  <div *ngIf="isSubmitted && form.mobile.errors" class="invalid-feedback">
                    <span class="text-danger p-0" *ngIf="form.mobile.errors.required">Mobile is
                      required</span>
                    <span class="text-danger p-0" *ngIf="form.mobile.errors.pattern">
                      Mobile Number should start with any of number 6. 7, 8, 9!
                    </span>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="form-group row">
                <label class="col-sm-6 text-bold col-form-label" for="pincode">Pincode of Your Residence</label>
                <div class="col-sm-6">
                  <input type="text" autocomplete="off" formControlName="pincode" class="form-control" id="pincode"
                    maxlength="6" minlength="6" [ngClass]="{ 'is-invalid': isSubmitted && form.pincode.errors }" />
                  <div *ngIf="isSubmitted && form.pincode.errors" class="invalid-feedback">
                    <span class="text-danger p-0" *ngIf="form.pincode.errors.required">Pincode
                      required</span>
                    <span class="text-danger p-0" *ngIf="form.pincode.errors.pattern">
                      Please enter valid postcode
                    </span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-12 py-4 text-center">
          <!-- <button (click)="goBack()" class="btn btn-default btn-real mx-2">Previous</button> -->
          <button matStepperPrevious class="btn btn-default btn-real mx-2">Previous</button>
          <button class="btn btn-default btn-real btn-selected">Next</button>
        </div>
      </div>
    </form>
  </div>
</div>
