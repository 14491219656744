import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class LoggerService {
  isDebugMode = environment.debugMode;
  loggingLevel = environment.loggingLevel;

  noop = (): any => undefined;
  // isDebugMode: boolean = true;
  // loggingLevel = {
  //   info: true,
  //   warn: true,
  //   error: true,
  //   debug: true,
  // }

  constructor() { }

  get info() {
    if (!environment.production) {
      if (this.isDebugMode && this.loggingLevel.info) {
        return console.info.bind(console);
      } else {
        return this.noop;
      }
    } else {
      return this.noop;
    }
  }

  get warn() {
    if (!environment.production) {
      if (this.isDebugMode && this.loggingLevel.warn) {
        return console.warn.bind(console);
      } else {
        return this.noop;
      }
    } else {
      return this.noop;
    }
  }

  get error() {
    if (!environment.production) {
      if (this.isDebugMode || this.loggingLevel.error) {
        return console.error.bind(console);
      } else {
        return this.noop;
      }
    } else {
      return this.noop;
    }
  }

  get debug() {
    if (!environment.production) {
      if (this.isDebugMode && this.loggingLevel.debug) {
        return console.debug.bind(console);
      } else {
        return this.noop;
      }
    } else {
      return this.noop;
    }
  }

  invokeConsoleMethod(type: string, args?: any): void {
    const logFn: Function = console[type] || console.log || false;
    logFn.apply(console, [args]);
  }

  log(loggingLevelType: string = 'info', message: any, ...optionalParams: any[]) {
    switch (loggingLevelType) {
      case "errors":
        this.error(message, optionalParams);
        break;
      case "info" || "verbose":
        this.info(message, optionalParams);
        break;
      case "warnings":
        this.warn(message, optionalParams);
        break;
      case "debug":
        this.debug(message, optionalParams);
        break;
      default:
        this.info(message, optionalParams);
    }

  }
}
