import { Injectable } from '@angular/core';
import { ApiService } from "src/app/core/services/api.service";
import { environment } from "../../../environments/environment";

@Injectable({
	providedIn: 'root'
})
export class CommonService {
	private assetsPath = `${environment.assetsPath}`;

	constructor(
		private _apiService: ApiService,
	) { }

  getVehicleMaster() {
		const path = `${this.assetsPath}masters/makemodels.json`;
		return this._apiService.getAPICall(path);
	}

	getFinancierDetails(provider) {
		const path = `${this.assetsPath}${provider}-finance-master.json`;
		return this._apiService.getAPICall(path);
	}

	getInsurerDetails(provider) {
		const path = `${this.assetsPath}${provider}-insurance-master.json`;
		return this._apiService.getAPICall(path);
	}

  loadKycMasterData(provider) {
		const path = `${this.assetsPath}${provider}-kyc-master.json`;
		return this._apiService.getAPICall(path);
	}

	getCityByPincode() {
		const path = `${this.assetsPath}pincode.json`;
		return this._apiService.getAPICall(path);
	}

	capitalizeFirstLetter(string) {
		string = string.toLowerCase();
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	getAddOnsDetails(productType) {
		const path = `${this.assetsPath}addons/${productType}.json`;
		return this._apiService.getAPICall(path);
	}

	public getAdminAccessByRole(userRole) {
		const path = `${this.assetsPath}admin-access/${userRole}.json`;
		return this._apiService.getAPICall(path);
	}

	public getExamQuestionDetails(path) {
		// const path = `${this.assetsPath}questions.json`;
		return this._apiService.getAPICall(path);
	}

	public updateUserSession(payload) {
		return this._apiService.getNewToken(payload)
	}

  public uploadFile(payload) {
    const path = `${environment.apiBaseUrl}${environment.serviceName.upload}`;
    return this._apiService.postAPICall(path, payload);
  }
}
