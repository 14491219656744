import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { CrmFlowService } from 'src/app/features/services/crm-flow.service';
import { LeadResponse } from 'src/app/features/interfaces/leadResponse';
import { InsuranceService } from 'src/app/features/services/insurance.service';

@Component({
  selector: 'app-kyc-confirmation',
  templateUrl: './kyc-confirmation.component.html',
  styleUrls: ['./kyc-confirmation.component.scss']
})
export class KycConfirmationComponent implements OnInit {
  public isLoading: boolean = true;
	public crmId: string;
	public crmObj: any;
	public generatedCrmSub = new Subscription();

  constructor(
    private router: Router,
		private logger: LoggerService,
		private crmFlow: CrmFlowService,
		private insuranceService: InsuranceService,
		private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getLeadId();
  }

  public getLeadId(): void {
		this.crmId = this.activatedRoute.snapshot.paramMap.get('leadId');
		this.getLeadDetailsFromCrm();
	}

	public getLeadDetailsFromCrm(): void {
		this.generatedCrmSub = this.crmFlow.getLeadDetails(this.crmId).subscribe({
			next: (leadResponse: LeadResponse) => {
        this.crmObj = leadResponse[0];
        this.processRedirection();
			},
			error: (error) => {
				this.logger.info(error);
			},
			complete: () => {
				this.isLoading = false;
				this.logger.info("complete");
			}

		})
	}

  public processRedirection(): void {
    if(this.crmObj.kyc.isKycCompleted) {
      this.insuranceService.storeValue("leadReqId", this.crmObj.leadId);
      this.router.navigate(['/feature/personal-info']);
    }
  }

}
