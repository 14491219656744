<div class="container">
	<div class="row text-center">
		<div *ngFor="let productType of productTypeObj; let i = index"
			class="custom-col col-md-3 col-sm-6 col-6 text-center rounded-circle"
			[ngClass]="selectedValue === productType.key ? 'selected' : ''">
			<a href="/feature/{{productType.key}}-insurance" title="{{productType.key}} Insurance from Real Insurance Broker Pvt. Ltd.">
				<img src="/assets/images/{{productType.logo}}.png" class="img_responsive" height="100"
					title="Simplest tool to compare {{productType.displayText}} insurance quotes"
					alt="Simplest tool to compare {{productType.displayText}} insurance quotes" /></a>
		</div>
	</div>
</div>
