import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-new-policy-summary',
	templateUrl: './new-policy-summary.component.html',
	styleUrls: ['./new-policy-summary.component.scss']
})
export class NewPolicySummaryComponent implements OnInit {
	@Input() quote: any;

	constructor() { }

	ngOnInit(): void {
	}

}
