<div class="container custom-p-t-3 container-real-insurance">
	<div class="row">
		<div class="col-12 card">
			<mat-horizontal-stepper [linear]="true" labelPosition="bottom" class="health-insurance-splitter-form"
				(selectionChange)="emitToBoxHeader($event)" #stepper>
				<ng-template matStepperIcon="gender">
					<mat-icon>male</mat-icon>
				</ng-template>
				<ng-template matStepperIcon="people">
					<mat-icon>people</mat-icon>
				</ng-template>
				<ng-template matStepperIcon="age">
					<mat-icon>cake</mat-icon>
				</ng-template>
				<ng-template matStepperIcon="lead">
					<mat-icon>person_add</mat-icon>
				</ng-template>

				<!--Gender Selection-->
				<mat-step state="gender" [completed]="genderStepCompleted">
					<ng-template matStepLabel>
						<h3>Gender Selection</h3>
					</ng-template>
					<app-gender-selection (nextButtonClicked)="genderSelectionStepNextClicked($event)">
					</app-gender-selection>
				</mat-step>

				<!--Insurer Selection-->
				<mat-step state="people" [completed]="insurerStepCompleted">
					<ng-template matStepLabel>
						<h3>Insurer Selection</h3>
					</ng-template>
					<app-insured-selection *ngIf="stepper.selectedIndex == 1" [inputObj]="healthInsInput"
						(nextButtonClicked)="insuredStepNextClicked($event)">
					</app-insured-selection>
				</mat-step>

				<!--age Selection-->
				<mat-step state="age" [completed]="ageStepCompleted">
					<ng-template matStepLabel>
						<h3>Age Selection</h3>
					</ng-template>
					<app-member-age *ngIf="stepper.selectedIndex == 2" [inputObj]="healthInsInput"
						(nextButtonClicked)="ageStepNextClicked($event)">
					</app-member-age>
				</mat-step>

				<!--age Selection-->
				<mat-step state="lead" [completed]="leadStepCompleted">
					<ng-template matStepLabel>
						<h3>Lead Form</h3>
					</ng-template>
					<app-lead-form
            *ngIf="stepper.selectedIndex == 3" [inputObj]="healthInsInput"
						(nextButtonClicked)="leadStepNextClicked($event)">
					</app-lead-form>
				</mat-step>
			</mat-horizontal-stepper>
		</div>
	</div>
</div>
