<form [formGroup]="extraFormGroup" class="bg-white personal-info-form-section">
	<div class="card mt-3">
		<div *ngIf="(crmObj.policyHolderInfo.policyholderType == pHolder[0]) && (!crmObj.cpaInfo.isEcpa)">
			<h3 class="card-header text-heading mb-0">Nominee</h3>
			<div class="card-body">
				<div class="row pb-3">
					<div class="col-sm-12 col-lg-5">
						<mat-form-field class="w-100" appearance="outline">
							<input matInput formControlName="name" placeholder="Enter Nominee Name"
								autocomplete="new-name" />
							<mat-error *ngIf="
              extraFormGroup['controls'].name.invalid &&
              extraFormGroup['controls'].name.dirty
            ">
								Nominee Name is required
							</mat-error>
						</mat-form-field>
					</div>
					<div class="col-sm-12 col-lg-2">
						<mat-form-field class="w-100" appearance="outline">
							<mat-select formControlName="age">
								<mat-option value="">Select Nominee Age</mat-option>
								<mat-option value="{{ option }}" *ngFor="let option of numberSeq; let i = index">
									{{ option }}
								</mat-option>
							</mat-select>
							<mat-error *ngIf="
              extraFormGroup['controls'].age.invalid &&
              extraFormGroup['controls'].age.dirty
            ">
								Nominee Age is required
							</mat-error>
						</mat-form-field>
					</div>
					<div class="col-sm-12 col-lg-5">
						<mat-form-field class="w-100" appearance="outline">
							<mat-select formControlName="relation">
								<mat-option value="">Select relation with Policyholder</mat-option>
								<mat-option value="{{ option }}" *ngFor="let option of relationshipList; let i = index">
									{{ option }}
								</mat-option>
							</mat-select>
							<mat-error *ngIf="
              extraFormGroup['controls'].relation.invalid &&
              extraFormGroup['controls'].relation.dirty
            ">
								Relationship with policyholder is required
							</mat-error>
						</mat-form-field>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="(crmObj.policyHolderInfo.policyholderType == pHolder[0]) && (crmObj.cpaInfo.isEcpa)">
			<h3 class="card-header text-heading mb-0">CPA Policy</h3>
			<div class="card-body">
				<!--(dateChange)="onriskStartDateChange($event)"-->
				<div class="row pb-3">
					<div class="col-sm-12 col-lg-4">
						<mat-form-field class="w-100" appearance="outline">
							<mat-select formControlName="insurerName">
								<mat-option value="">Select CPA Insurer Name</mat-option>
								<mat-option value="{{ option.name }}" *ngFor="let option of insurerData; let i = index">
									{{ option.name }}
								</mat-option>
							</mat-select>
							<mat-error *ngIf="
								extraFormGroup['controls'].insurerName.invalid &&
								extraFormGroup['controls'].insurerName.dirty
							  ">
								Select CPA Insurer Name
							</mat-error>
						</mat-form-field>
					</div>
					<div class="col-sm-12 col-lg-4">
						<mat-form-field class="w-100" appearance="outline">
							<input matInput placeholder="Enter CPA Policy No" formControlName="policyNo" maxlength="18"
								autocomplete="new-previousTpPolicyNo" />
							<mat-error *ngIf="
								extraFormGroup['controls'].policyNo.invalid &&
								extraFormGroup['controls'].policyNo.dirty
							  ">
								CPA Policy No with min 5 and max 18 chars required
							</mat-error>
						</mat-form-field>
					</div>
					<div class="col-sm-12 col-lg-4">
						<mat-form-field class="w-100" appearance="outline">
							<input matInput [matDatepicker]="picker5" onkeydown="return false"
								placeholder="Enter CPA Start Date" formControlName="riskStartDate" />
							<mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
							<mat-datepicker #picker5 ngDefaultControl></mat-datepicker>
							<mat-error *ngIf="
								extraFormGroup['controls'].riskStartDate.invalid &&
								extraFormGroup['controls'].riskStartDate.dirty
							  ">
								CPA Start Date is required
							</mat-error>
						</mat-form-field>
					</div>
				</div>
			</div>
		</div>
		<div>
			<h3 class="card-header text-heading mb-0">Extra</h3>
			<div class="card-body">
				<div class="row pb-3" *ngIf="isAutomobileAssociation">
					<div class="col-sm-12 col-lg-4">
						<mat-form-field class="w-100" appearance="outline">
							<mat-select formControlName="aaimName">
								<mat-option value="">Select Automobile Association Name</mat-option>
								<mat-option value="{{ option.name }}"
									*ngFor="let option of automobileObj; let i = index">
									{{ option.displayName }}
								</mat-option>
							</mat-select>
							<mat-error *ngIf="
				                                      extraFormGroup['controls'].aaimName.invalid &&
				                                      extraFormGroup['controls'].aaimName.dirty
				                                    ">
								Automobile Association Name is required
							</mat-error>
						</mat-form-field>
					</div>
					<div class="col-sm-12 col-lg-4">
						<mat-form-field class="w-100" appearance="outline">
							<input matInput formControlName="membershipNo" type="text" maxlength="6" tabindex="5"
								placeholder="Enter Your Automobile Association Membership No" autocomplete="off" />
							<mat-error *ngIf="
												  extraFormGroup['controls'].membershipNo.invalid &&
												  extraFormGroup['controls'].membershipNo.dirty
												">
								Automobile Association Membership No is required
							</mat-error>
						</mat-form-field>
					</div>
					<div class="col-sm-12 col-lg-4">
						<mat-form-field class="w-100" appearance="outline">
							<input matInput [matDatepicker]="picker6" onkeydown="return false"
								placeholder="Enter Membership Expiry Date" [min]="minExpiryDate"
								formControlName="membershipExpiryDate" />
							<mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
							<mat-datepicker #picker6 ngDefaultControl></mat-datepicker>
							<mat-error *ngIf="
														extraFormGroup['controls'].membershipExpiryDate.invalid &&
														extraFormGroup['controls'].membershipExpiryDate.dirty
													  ">
								Memebership Expiry Date is required
							</mat-error>
						</mat-form-field>
					</div>
				</div>
				<div class="row pb-3">
					<div class="col-sm-12 col-lg-6">
						<mat-slide-toggle class="form-control" (change)="onFinanced($event)" class="pr-5"
							formControlName="isFinanced">
						</mat-slide-toggle><label for="isFinanced">Enable if Vehicle Financed</label>
					</div>
				</div>
				<div class="row pb-3" *ngIf="isFinanced">
					<div class="col-sm-12 col-lg-4">
						<mat-form-field class="w-100" appearance="outline">
							<input matInput formControlName="financierName" placeholder="Enter Financer Name"
								autocomplete="off" />
							<mat-error *ngIf="
								extraFormGroup['controls'].financierName.invalid &&
								extraFormGroup['controls'].financierName.dirty
							  ">
								Financier Name is required
							</mat-error>
						</mat-form-field>
					</div>
					<div class="col-sm-12 col-lg-4">
						<mat-form-field class="w-100" appearance="outline">
							<input (input)="callPincodeAPI()" matInput formControlName="financierPincode" type="text"
								maxlength="6" tabindex="5" placeholder="Enter Pincode" autocomplete="off" />
							<mat-error *ngIf="
				  extraFormGroup['controls'].financierPincode.invalid &&
				  extraFormGroup['controls'].financierPincode.dirty
				">
								Valid 6 digit Pincode is required
							</mat-error>
						</mat-form-field>
					</div>
					<div class="col-sm-12 col-lg-4">
						<mat-form-field class="w-100" appearance="outline">
							<mat-label *ngIf="
				  extraFormGroup['controls'].financierCity.invalid &&
				  !extraFormGroup['controls'].financierCity.touched
				">Select City
							</mat-label>
							<mat-error *ngIf="
				  extraFormGroup['controls'].financierCity.invalid &&
				  extraFormGroup['controls'].financierCity.touched
				">Please enter Pincode first to set city name
							</mat-error>
							<input matInput formControlName="financierCity" type="text" placeholder="Enter City Name" />
						</mat-form-field>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class=" col text-center mt-4 pb-1">
				<button class="btn btn-default btn-real" matStepperPrevious>Back</button>
				<button [disabled]="isDisable" class="btn btn-default btn-real btn-selected ms-4"
					(click)="nextStep($event)">Next</button>
			</div>
		</div>
	</div>
</form>
