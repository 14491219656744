import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CrmFlowService } from 'src/app/features/services/crm-flow.service';
import { LeadResponse } from 'src/app/features/interfaces/leadResponse';
import * as moment from 'moment';
import { voluntaryDiscountArr, ncbPerArr, ownerTypeArr, productTypeObj } from "src/app/features/policy-config"
import { LoggerService } from 'src/app/core/logger/logger.service';
import { CommonService } from 'src/app/features/services/common.service';
import { BreakInServiceService } from 'src/app/features/services/break-in-service.service'

@Component({
	selector: 'app-quote-filter-sidebar',
	templateUrl: './quote-filter-sidebar.component.html',
	styleUrls: ['./quote-filter-sidebar.component.scss']
})
export class QuoteFilterSidebarComponent implements OnInit {
	@Input() public crmObj;
	public crmId: string;
	public isOwnerChanged: boolean;
	public isPolicyExpired: boolean;
	public isNcbClaimed: boolean;
	public isCpa: boolean;
	public isDl: boolean;
	public isEcpa: boolean
	public selectedNcb: number;
	public volumetricDisc: number = 0;
	public isRegistrationDateEdit: boolean = false;
	public isPreviousPolicyDateEdit: boolean = false;
	public registrationDate: string;
	public riskEndDate: string;
	public minRegistrationDate: string;
	public maxRegistrationDate: string;
	public minPreviousPolicyDate: string;
	public maxPreviousPolicyDate: string;
	public selectedAddons: any;
	public productType: any = productTypeObj;
	public isDisplayCpaBox: boolean;
	public isNcbDisplayBox: boolean;
	public isPreviousPolicyDisplayBox: boolean;
	public addonsList: Array<any> = [];
	public accessoriesList: Array<any> = [];
	public additionalCoversList: Array<any> = [];
	public extraList: Array<any> = [];
	public ncbList: Array<any> = ncbPerArr;
	public TpPolicyType: string = 'TP';
	public ownerTypeList: Array<any> = ownerTypeArr;
	public vdList: any;
	@Output() public isCrmDataUpdated = new EventEmitter<boolean>()
	@Output() public sideBarClose = new EventEmitter();

	constructor(
		private crmFlow: CrmFlowService,
		private logger: LoggerService,
		private commonService: CommonService,
		private breakInService: BreakInServiceService
	) { }

	ngOnInit(): void {
    this.setCrmId();
		this.setIsOwnerChanged();
		this.setIsPolicyExpired();
		this.setIsNcbClaimed();
		this.setIsCpa();
		this.setSelectedNcb();
		this.displayCpaBox();
		this.displayNcbBox();
		this.displayPreviousPolicyBox();
		this.setRegistrationDate();
		this.setMinMaxRegistrationDate();
		this.setPreviousPolicyDate()
		this.setMinMaxPrePolicyDate();
		// setTimeout(() => {
			this.getDefaultAddons();
			this.setSelectedAddons();
			this.setVdList();
		// }, 600);
    this.logger.info(this.addonsList);
	}

	public setVdList(): void {
		this.vdList = voluntaryDiscountArr[this.crmObj.commonFields.productType]
	}

	public getDefaultAddons(): void {
		const insuranceType = this.crmObj.newPolicyInfo.newPolicyType.toUpperCase();
    this.logger.info(insuranceType);
		this.commonService.getAddOnsDetails(this.crmObj.commonFields.productType).subscribe((data) => {
			if (insuranceType !== 'COMPREHENSIVE') {
				if (data[insuranceType].addons) {
					this.addonsList = data[insuranceType].addons.filter((item) => {
            this.logger.info(item);
						return this.crmFlow.validateCutOffDate(item, this.crmObj)
					});
				}

				if (data[insuranceType].accessories) {
					this.accessoriesList = data[insuranceType].accessories.filter(function (item) {
						return item.defaultDisplay;
					});
				}
				if (data[insuranceType].additionalCovers) {
					this.additionalCoversList = data[insuranceType].additionalCovers.filter(function (item) {
						return item.defaultDisplay;
					});
				}
				if (data[insuranceType].extraCovers) {
					this.extraList = data[insuranceType].extraCovers.filter(function (item) {
						return item.defaultDisplay;
					});
				}

				const commonAccessoriesList = data.common.accessories.filter(function (item) {
					return item.defaultDisplay;
				});
				if (commonAccessoriesList.length > 0) {
					this.accessoriesList.push(...commonAccessoriesList);
				}
			} else {
				this.addonsList = data.SAOD.addons.filter((item) => {
          this.logger.info(item);
					return this.crmFlow.validateCutOffDate(item, this.crmObj)
				});
				this.accessoriesList = data.SAOD.accessories.filter(function (item) {
					return item.defaultDisplay;
				});
				this.additionalCoversList = data.TP.additionalCovers.filter(function (item) {
					return item.defaultDisplay;
				});
				this.extraList = data.SAOD.extraCovers.filter(function (item) {
					return item.defaultDisplay;
				});
				const TPExtraCovers = data.TP.extraCovers.filter(function (item) {
					return item.defaultDisplay;
				});
				this.extraList.push(...TPExtraCovers);

				const commonAccessoriesList = data.common.accessories.filter(function (item) {
					return item.defaultDisplay;
				});
				if (commonAccessoriesList.length > 0) {
					this.accessoriesList.push(...commonAccessoriesList);
				}
			}
		})
    // this.logger.info("========> at ln 141")
    // this.logger.info(this.addonsList);
	}

	public setCrmId(): void {
		this.crmId = this.crmObj.leadId;
	}

	public setIsOwnerChanged(): void {
		this.isOwnerChanged = this.crmObj.motorProfile.isOwnerChanged;
	}

	public setIsPolicyExpired(): void {
		this.isPolicyExpired = this.crmObj.newPolicyInfo.isBreakin
	}

	public setIsNcbClaimed(): void {
		this.isNcbClaimed = !this.crmObj.ncbInfo.isNcb
	}

	public setIsCpa(): void {
		this.isCpa = this.crmObj.cpaInfo.isCpa;
		this.isDl = this.crmObj.cpaInfo.isDl;
		this.isEcpa = this.crmObj.cpaInfo.isEcpa;
	}

	public onRegistrationDateEdit(): void {
		this.isRegistrationDateEdit = true;
	}

	public enableEdit(): void {
		this.isPreviousPolicyDateEdit = true;
	}

	public setMinMaxRegistrationDate(): void {
		const today = new Date()
		let age = 25;
		let subtract = 0
		let addDays = 0
		if (this.crmObj.commonFields.productType == this.productType.bike && this.crmObj.newPolicyInfo.newPolicyType == "TP") {
			age = 21;
			subtract = 4
			addDays = 29
		}
		this.maxRegistrationDate = this.crmFlow.formatDate(moment(today).subtract(6, 'months'));
		this.minRegistrationDate = this.crmFlow.formatDate(moment(today).subtract(age, 'years').add(subtract, 'months').subtract(addDays, 'days'));
	}

	public setRegistrationDate(): void {
		this.registrationDate = this.crmObj.motorProfile.registrationDate;
	}

	public setPreviousPolicyDate(): void {
		this.riskEndDate = this.crmObj.prePolicyInfo.riskEndDate;
	}

	public setMinMaxPrePolicyDate(): void {
		const today = new Date()
		this.minPreviousPolicyDate = this.crmFlow.formatDate(moment(today));
		this.maxPreviousPolicyDate = this.crmFlow.formatDate(moment(today).add(45, 'days'));
	}

	public setSelectedAddons(): void {
		this.selectedAddons = this.crmObj.extra.addons;
		// this.logger.info(this.selectedAddons);
    if (this.addonsList) {
			this.addonsList.map((item) => {
        // this.logger.info(item)
				if (this.selectedAddons.hasOwnProperty(item.value)) {
					item.selected = this.selectedAddons[item.value]
				}
			});
		}
    // this.logger.info(this.addonsList);
		if (this.accessoriesList) {
			this.accessoriesList.map((item) => {
				if (this.selectedAddons.hasOwnProperty(item.value)) {
					item.selected = (this.selectedAddons[item.value] == null) ? false : true;
					item.coverAmount = (this.selectedAddons[item.value] == null) ? 0 : parseInt(this.selectedAddons[item.value]);
				}
			});
		}
		if (this.additionalCoversList) {
			this.additionalCoversList.map((item) => {
				if (this.selectedAddons.hasOwnProperty(item.value)) {
					item.selected = (this.selectedAddons[item.value] == null) ? false : true;
					item.coverAmount = (this.selectedAddons[item.value] == null) ? 10000 : parseInt(this.selectedAddons[item.value]);
				}
			});
		}
		if (this.extraList) {
			this.extraList.map((item) => {
				if (this.selectedAddons.hasOwnProperty(item.value)) {
					if (item.type == "boolean") {
						item.selected = this.selectedAddons[item.value]
					} else {
						item.coverAmount = (this.selectedAddons[item.value] == null) ? 0 : parseInt(this.selectedAddons[item.value]);
					}
				}
			});
		}
	}

	public displayCpaBox(): void {
		this.isDisplayCpaBox = this.crmObj.policyHolderInfo.policyholderType == ownerTypeArr[1] ? false : true;
	}

	public displayNcbBox(): void {
		if (!this.crmObj.motorProfile.isOwnerChanged
			&& (this.crmObj.newPolicyInfo.newPolicyType != this.TpPolicyType)
			&& !this.crmObj.motorProfile.isNew
		) {
			this.isNcbDisplayBox = true;
		} else {
			this.isNcbDisplayBox = false
		}
	}

	public displayPreviousPolicyBox(): void {
		this.isPreviousPolicyDisplayBox = !this.crmObj.motorProfile.isNew;
	}

	public setSelectedNcb(): void {
		this.selectedNcb = this.crmObj.ncbInfo.currentNcb
	}

	public onRegistrationDateChange(event): void {
		this.crmObj["motorProfile"]["registrationDate"] = this.crmFlow.formatDate(event);
		this.crmObj["motorProfile"]["manufactureDate"] = this.crmFlow.formatDate(event);
		this.crmObj["motorProfile"]["purchaseDate"] = this.crmFlow.formatDate(event);
		this.crmFlow.setNewPolicyTypeOnRegistrationDateChange(this.crmObj);
		this.crmObj["newPolicyInfo"]["newPolicyType"] = this.crmFlow.newPolicyItems.newPolicyType;
		this.crmObj["tpPolicyInfo"]["riskStartDate"] = this.crmFlow.leadTpPolicy.riskStartDate;
		this.crmObj["tpPolicyInfo"]["riskEndDate"] = this.crmFlow.leadTpPolicy.riskEndDate;

		this.callUpdateLeadApi(this.crmObj);
		this.isRegistrationDateEdit = false;
		this.close();
	}

	public onPreviousPolicyDateChange(event): void {
		this.setRiskStartEndDate(event);
		this.callUpdateLeadApi(this.crmObj);
		this.isPreviousPolicyDateEdit = false;
		this.close();
	}

	public onVehicleOwnerChange(event): void {
		this.isOwnerChanged = event.checked
		this.crmObj["motorProfile"]["isOwnerChanged"] = this.isOwnerChanged
		this.isNcbDisplayBox = !event.checked;
		if (this.isOwnerChanged) {
			this.crmObj["ncbInfo"]["isNcb"] = !this.isOwnerChanged;
			this.crmObj["ncbInfo"]["currentNcb"] = this.ncbList[0];
			this.crmObj["ncbInfo"]["newNcb"] = this.ncbList[0];
		}
		setTimeout(() => {
			this.callUpdateLeadApi(this.crmObj);
		}, 400);
		this.close();
	}

	public onPACoverChange(event): void {
		this.isCpa = event.checked;
		this.isDl = true;
		this.isEcpa = !event.checked;
		this.crmObj["cpaInfo"]["isCpa"] = this.isCpa;
		this.crmObj["cpaInfo"]["isDl"] = this.isDl;
		this.crmObj["cpaInfo"]["isEcpa"] = this.isEcpa;
		setTimeout(() => {
			this.callUpdateLeadApi(this.crmObj);
		}, 400);
		this.close();
	}

	public onDlChange(event): void {
		this.isDl = event.checked;
		this.isEcpa = event.checked;
		this.crmObj["cpaInfo"]["isCpa"] = this.isCpa;
		this.crmObj["cpaInfo"]["isDl"] = this.isDl;
		this.crmObj["cpaInfo"]["isEcpa"] = this.isEcpa;
		setTimeout(() => {
			this.callUpdateLeadApi(this.crmObj);
		}, 400);
		this.close();
	}

	public onPolicyExpired(event): void {
		this.isPolicyExpired = event.checked;
		this.crmObj["newPolicyInfo"]["isBreakin"] = event.checked;
		const isInspectionFlag = this.breakInService.setInspectionFlag(this.crmObj)
		this.crmObj["newPolicyInfo"]['isInspection'] = (event.checked) ? isInspectionFlag : false;
		// this.isNcbDisplayBox = !(this.isPolicyExpired);
		this.setRiskStartEndDate(null);
		// this.onNcbChange(event, false);
		setTimeout(() => {
			this.callUpdateLeadApi(this.crmObj);
		}, 400);
		this.close();
	}

	public setRiskStartEndDate(setPreviousPolicyExpiryDate: string = null): void {
		if (this.isPolicyExpired) {
			this.crmFlow.setPreviousPolicyExpiryDateOnPolicyExpired()
			this.crmFlow.setPreviousPolicyStartDateOnPolicyExpired();
		} else {
			this.crmFlow.setPreviousPolicyExpiryDate(setPreviousPolicyExpiryDate)
			this.crmFlow.setPreviousPolicyStartDate(setPreviousPolicyExpiryDate)
		}
		this.crmFlow.setRiskStartDate(this.isPolicyExpired);
		this.crmFlow.setRiskEndDate();
		this.crmObj["prePolicyInfo"]["riskEndDate"] = this.crmFlow.prePolicyObj.riskEndDate;
		this.crmObj["prePolicyInfo"]["riskStartDate"] = this.crmFlow.prePolicyObj.riskStartDate;
		this.crmObj["newPolicyInfo"]["riskStartDate"] = this.crmFlow.newPolicyItems.riskStartDate;
		this.crmObj["newPolicyInfo"]["riskEndDate"] = this.crmFlow.newPolicyItems.riskEndDate;
	}

	public onNcbChange(event, executeAPi: boolean = true): void {
		this.isNcbClaimed = event.checked
		this.crmObj["ncbInfo"]["isNcb"] = !this.isNcbClaimed
		this.crmObj["ncbInfo"]["currentNcb"] = "0";
		this.crmObj["ncbInfo"]["newNcb"] = this.crmFlow.calculateNewNcb(this.crmObj.ncbInfo.currentNcb, this.crmObj.ncbInfo.isNcb);
		if (executeAPi) {
			setTimeout(() => {
				this.callUpdateLeadApi(this.crmObj);
			}, 400);
		}
	}

	public onNcBValueChange(event): void {
		this.crmObj["ncbInfo"]["currentNcb"] = event;
		this.crmObj["ncbInfo"]["newNcb"] = this.crmFlow.calculateNewNcb(this.crmObj.ncbInfo.currentNcb, this.crmObj.ncbInfo.isNcb);
		setTimeout(() => {
			this.callUpdateLeadApi(this.crmObj);
		}, 400);
	}

	public updateAddons(): void {
		// this.logger.info(this.addonsList)
    if (this.addonsList) {
			this.addonsList.map((addon) => {
				if (addon.selected) {
					this.selectedAddons[addon.value] = addon.selected;
				}
			})
		}
    // this.logger.info(this.selectedAddons);
		if (this.accessoriesList) {
			this.accessoriesList.map((addon) => {
				this.selectedAddons[addon.value] = (addon.selected) ? addon.coverAmount : null;
			})
		}
		if (this.additionalCoversList) {
			this.additionalCoversList.map((addon) => {
				if (addon.type == "boolean") {
					this.selectedAddons[addon.value] = (addon.selected) ? addon.minValue : null;
				} else {
					this.selectedAddons[addon.value] = (addon.selected) ? addon.coverAmount : null;
				}
			})
		}
		if (this.extraList) {
			this.extraList.map((addon) => {
				if (addon.type == "boolean") {
					this.selectedAddons[addon.value] = addon.selected;
				} else {
					this.selectedAddons[addon.value] = (addon.coverAmount == 0) ? null : addon.coverAmount;
				}
			})
		}
		this.crmObj["extra"]["addons"] = this.selectedAddons;
		setTimeout(() => {
			this.callUpdateLeadApi(this.crmObj);
		}, 400);
		this.close();
	}

	public callUpdateLeadApi(leadPayload): void {
		this.crmFlow.updateApi(this.crmId, leadPayload).subscribe(
			(leadResponse: LeadResponse) => {
				setTimeout(() => {
					this.isCrmDataUpdated.emit(true);
				}, 500);
			},
			(error) => {
				this.isCrmDataUpdated.emit(false);
				this.logger.info(error);
			}
		);
	}

	public close = () => {
		this.sideBarClose.emit();
	}
}
