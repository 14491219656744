<form [formGroup]="previousPolicyForm" class="bg-white personal-info-form-section">
	<div class="card mt-3">
		<h3 class="card-header text-heading mb-0">Pre Policy Info</h3>
		<div class="card-body">
			<div class="row" *ngIf="crmObj.newPolicyInfo.isBreakin">
				<div class="col-sm-12 col-lg-6">
					<mat-slide-toggle class="form-control" (change)="onPreviousPolicyExist($event)" class="pr-5"
						formControlName="isPreviousPolicy">
					</mat-slide-toggle><label for="isPreviousPolicy">I have Policy Copy</label>
				</div>
			</div>
			<div class="row mt-3" *ngIf="isPreviousPolicyExist">
				<div class="col-sm-12 col-lg-4">
					<div class="form-group">
						<label for="previousPolicyType" class="pb-1">Previous Policy Type</label>
						<mat-button-toggle-group appearance="legacy" formControlName="previousPolicyType"
							aria-label="New Policy">
							<mat-button-toggle *ngFor="let item of modifiedPolicyList" value="{{item}}">{{item}}
							</mat-button-toggle>
						</mat-button-toggle-group>
					</div>
				</div>
				<div class="col-sm-12 col-lg-4">
					<div class="form-group">
						<label for="insurerName" class="pb-1">Previous Insurer Name</label>
						<select class="form-control form-select" formControlName="insurerName" [ngClass]="{
                    'is-invalid': isSubmitted && form.insurerName.errors}" (change)="onSelect($event)">
							<option value="">Select Insurer From the list</option>
							<option value="{{ option.name }}" *ngFor="let option of insurerData; let i = index">{{
								option.name }}</option>
						</select>
						<div *ngIf="isSubmitted && form.insurerName.errors" class="invalid-feedback">
							<span class="text-danger" *ngIf="form.insurerName.errors.required">Previous Insurer Name is
								required</span>
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-lg-4">
					<div class="form-group">
						<label for="policyNo" class="pb-1">Previous Policy No</label>
						<input autocomplete="on" class="form-control" formControlName="policyNo" type="text"
							minlength="5" maxlength="25" placeholder="Enter Previous Policy No" [ngClass]="{
                    'is-invalid': isSubmitted && form.policyNo.errors
                  }" />
						<div *ngIf="isSubmitted && form.policyNo.errors" class="invalid-feedback">
							<span class="text-danger" *ngIf="form.policyNo.errors.required">Previous Policy no is
								required</span>
							<span class="text-danger" *ngIf="form.policyNo.errors.pattern">Previous Policy no must be a
								valid
								pattern</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<h3 class="card-header text-heading mb-0" *ngIf="crmObj.newPolicyInfo.newPolicyType == policyTypeArr[1]">TP
			Policy Info</h3>
		<div class="card-body" *ngIf="crmObj.newPolicyInfo.newPolicyType == policyTypeArr[1]">
			<div class="row pb-3">
				<div class="col-sm-12 col-lg-6">
					<div class="form-group">
						<label for="tpInsurerName" class="pb-1">Third Party Insurer Name</label>
						<select class="form-control form-select" formControlName="tpInsurerName" [ngClass]="{
                    'is-invalid': isSubmitted && form.tpInsurerName.errors}">
							<option value="">Select Insurer From the list</option>
							<option value="{{ option.name }}" *ngFor="let option of insurerData; let i = index">{{
								option.name }}</option>
						</select>
						<div *ngIf="isSubmitted && form.tpInsurerName.errors" class="invalid-feedback">
							<span class="text-danger" *ngIf="form.tpInsurerName.errors.required">Third Party Insurer
								Name Is required</span>
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-lg-6">
					<div class="form-group">
						<label for="tpPolicyNo" class="pb-1">Third Party Policy No</label>
						<input autocomplete="on" class="form-control" formControlName="tpPolicyNo" type="text"
							minlength="5" maxlength="25" placeholder="Enter Previous Policy No" [ngClass]="{
                    'is-invalid': isSubmitted && form.policyNo.errors
                  }" />
						<div *ngIf="isSubmitted && form.tpPolicyNo.errors" class="invalid-feedback">
							<span class="text-danger" *ngIf="form.tpPolicyNo.errors.required">Third Party Policy no is
								required</span>
							<span class="text-danger" *ngIf="form.tpPolicyNo.errors.pattern">Third Party Policy no must
								be a valid
								pattern</span>
						</div>
					</div>
				</div>
			</div>
			<div class="row pb-3">
				<div class="col-sm-12 col-lg-6">
					<div class="form-group">
						<label for="tpRiskStartDate" class="pb-1">Third Party Policy Start Date</label>
						<input autocomplete="on" class="form-control" formControlName="tpRiskStartDate" type="date"
							minlength="5" maxlength="18" placeholder="Enter Third Party Policy Start Date" [ngClass]="{
                    'is-invalid': isSubmitted && form.tpRiskStartDate.errors
                  }" />
						<div *ngIf="isSubmitted && form.tpRiskStartDate.errors" class="invalid-feedback">
							<span class="text-danger" *ngIf="form.tpRiskStartDate.errors.required">Third Party Policy
								Start Date is required</span>
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-lg-6">
					<div class="form-group">
						<label for="tpRiskEndDate" class="pb-1">Third Party Policy End Date</label>
						<input autocomplete="off" class="form-control" formControlName="tpRiskEndDate" type="date"
							minlength="5" maxlength="18" placeholder="Enter Third Party Policy End Date" [ngClass]="{
                    'is-invalid': isSubmitted && form.tpRiskEndDate.errors
                  }" />
						<div *ngIf="isSubmitted && form.tpRiskEndDate.errors" class="invalid-feedback">
							<span class="text-danger" *ngIf="form.tpRiskEndDate.errors.required">Third Party Policy
								End Date is required</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col p-4 mt-3">
				<div class="form-group body-copy-lg">
					<p class="text-center p-2 invalid-feedback" *ngIf="isBlock">
						<span class="text-danger"><strong>Oops!!! You cannot proceed further to generate policy, becuase
								you have selected Third Party as previous policy type.</strong></span>
					</p>
					<p class="text-center p-2 alert alert-info" *ngIf="!isPreviousPolicyExist">
						<span class="text-danger"><strong><i class="fa fa-bell pr-5" aria-hidden="true"></i>No Claim
								Bonus is not applicable if
								you don't have policy
								copy</strong></span>
					</p>
					<p class="text-center">
						<button class="btn btn-default btn-real" matStepperPrevious>Back</button>
						<button class="btn btn-default btn-real btn-selected ms-4" (click)="onSubmit()"
							id="previousPolicy">
							Next
						</button>
					</p>
				</div>
			</div>
		</div>
	</div>
</form>
