import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Input('loadingText') loaderText:string =  'Fetching Data from Provider..Please Wait...'; 

  constructor() { }

  ngOnInit() {
  }

}