<div class="col-12">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item">
                <a class="text-black" (click)="generateNewLead()">
                    <i class="fa fa-home pr-10" aria-hidden="true"></i>Home
                </a>
            </li>
            <li class="breadcrumb-item" *ngIf="steps == 'Quotation'" [ngClass]="{
                'active': steps == 'Quotation'
              }">
                <a class="text-black" routerLink="['feature/compare-quotes']" *ngIf="steps != 'Quotation'">
                    <i class="fa fa-list pr-10" aria-hidden="true"></i>Quotation
                </a>
                <a *ngIf="steps == 'Quotation'">
                    <i class="fa fa-list pr-10" aria-hidden="true"></i>Quotation
                </a>
            </li>
            <li class="breadcrumb-item" *ngIf="steps == 'ProposalInput'" [ngClass]="{
                'active': steps == 'ProposalInput'
              }">
                <a>
                    <i class="fa fa-list pr-10" aria-hidden="true"></i>Quotation
                </a>
            </li>
            <li class="breadcrumb-item" *ngIf="steps == 'Proposal'" [ngClass]="{
                'active': steps == 'Proposal'
              }">
                <a>
                    <i class="fa fa-list pr-10" aria-hidden="true"></i>Quotation
                </a>
            </li>
        </ol>
    </nav>
</div>