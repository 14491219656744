import { Injectable } from '@angular/core';
import { KycValidationApiFlag, KycDocTypeList } from "src/app/features/kyc-config";

@Injectable({
  providedIn: 'root'
})
export class KycService {
  public kycValidationApiFlag: any = KycValidationApiFlag;
  public kycDocTypeList: any = KycDocTypeList;
  constructor() {}

  public validateKycProcess(insuranceProvider) {
    return this.kycValidationApiFlag[insuranceProvider.replace("-","")]
  }

  public getKycDoctypeId(insuranceProvider, elementType) {
    const dataObj = this.kycDocTypeList[insuranceProvider];
    const listItem = dataObj.filter((listObj) => {
      return listObj["docType"] == elementType
    })
    return listItem[0]["docId"];
  }
}
