<div class="modal-header d-block">

	<div class="row dcpop-desktop-wrapper">
		<div class="col-md-4">
			<img class="mb-2" src="/assets/images/list-quote-images/{{quote.providerName}}.png">
		</div>
		<div class="col-md-8">
			<h3 class="mb-0 text-heading">Premium Breakup For {{getDisplayText(quote.productType)}}
				{{quote.insuranceType}}
				Policy</h3>
		</div>
	</div>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
		<!-- <span aria-hidden="true">&times;</span> -->
		<i class="fa fa-times" aria-hidden="true"></i>
	</button>


</div>

<div class="modal-body">
	<div class="row">
		<div class="col-md-5 left-side">

			<div class="dcmodal-bike-info">
				<div class="bike-info-group">
					<div class="bike-info-heading heading-blue d-flex justify-content-between">
						<div class="breakup-info">Registration Date:</div>
						<div class="breakup-price ng-binding">{{quote.motorProfile.registrationDate | date:
							"dd-MM-yyyy"}}</div>
					</div>
					<div class="bike-info-text d-flex justify-content-between">
						<div class="breakup-info">RTO:</div>
						<div class="breakup-price ng-binding">{{quote.motorProfile.rtoLoc}} </div>
					</div>
				</div>
				<div class="bike-info-group">
					<div class="bike-info-heading d-flex justify-content-between">
						<div class="breakup-info">IDV:</div>
						<div class="breakup-price ng-binding">
							{{quote.vehicleIDV.defaultValue | currency: "INR":"symbol"}}
						</div>
					</div>
				</div>
				<div *ngIf="quote.vehicleIDV.minValue" class="bike-info-group">
					<div class="bike-info-heading d-flex justify-content-between">
						<div class="breakup-info">Min. IDV:</div>
						<div class="breakup-price ng-binding"> {{quote.vehicleIDV.minValue | currency: "INR":"symbol"}}
						</div>
					</div>
				</div>

				<div *ngIf="quote.vehicleIDV.maxValue" class="bike-info-group">
					<div class="bike-info-heading d-flex justify-content-between">
						<div class="breakup-info">Max. IDV:</div>
						<div class="breakup-price ng-binding"> {{quote.vehicleIDV.maxValue | currency: "INR":"symbol"}}
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col-md-7 right-side">
			<div class="dcmodal-content">
				<div class="dcmodal-content-row dcmodal-content-heading">
					<strong>Basic OD Premium (+)</strong>
				</div>
				<div class="dcmodal-content-row">
					<div class="d-flex breakup-text-desc justify-content-between">
						<div class="breakup-info">Basic OD Premium </div>
						<div class="breakup-price ng-binding">
							{{
							(quote.premiumBreakup && quote.premiumBreakup.ownDamageCover
							? quote.premiumBreakup.ownDamageCover
							: 0
							) | currency: "INR":"symbol"
							}}
						</div>
					</div>
				</div>

				<div *ngIf="quote.premiumBreakup.electricalAssecories && quote.premiumBreakup.electricalAssecories!=0"
					class="dcmodal-content-row">
					<div class="d-flex breakup-text-desc justify-content-between">
						<div class="breakup-info">Electrical </div>
						<div class="breakup-price ng-binding">
							{{
							(quote.premiumBreakup && quote.premiumBreakup.electricalAssecories
							? quote.premiumBreakup.electricalAssecories
							: 0
							) | currency: "INR":"symbol"
							}}
						</div>
					</div>
				</div>

				<div *ngIf="quote.premiumBreakup.nonElectricalAssecories && quote.premiumBreakup.nonElectricalAssecories!=0"
					class="dcmodal-content-row">
					<div class="d-flex breakup-text-desc justify-content-between">
						<div class="breakup-info">Non-Electrical </div>
						<div class="breakup-price ng-binding">
							{{
							(quote.premiumBreakup && quote.premiumBreakup.nonElectricalAssecories
							? quote.premiumBreakup.nonElectricalAssecories
							: 0
							) | currency: "INR":"symbol"
							}}
						</div>
					</div>
				</div>

				<div *ngIf="quote.premiumBreakup.externalCng && quote.premiumBreakup.externalCng!=0"
					class="dcmodal-content-row">
					<div class="d-flex breakup-text-desc justify-content-between">
						<div class="breakup-info">Cng Fitted </div>
						<div class="breakup-price ng-binding">
							{{
							quote.premiumBreakup.externalCng | currency: "INR":"symbol"
							}}
						</div>
					</div>
				</div>

				<div *ngIf="quote.premiumBreakup.cngFitted && quote.premiumBreakup.cngFitted!=0"
					class="dcmodal-content-row">
					<div class="d-flex breakup-text-desc justify-content-between">
						<div class="breakup-info">Cng Fitted </div>
						<div class="breakup-price ng-binding">
							{{
							quote.premiumBreakup.cngFitted | currency: "INR":"symbol"
							}}
						</div>
					</div>
				</div>


				<div class="dcmodal-content-row">
					<div class="d-flex breakup-text-desc justify-content-between">
						<div class="breakup-info">
							<strong>(A) Total OD Premium</strong>
						</div>
						<div class="breakup-price"><strong class="ng-binding"> {{
								totalValue([quote.premiumBreakup.ownDamageCover,
								quote.premiumBreakup.electricalAssecories
								,quote.premiumBreakup.nonElectricalAssecories,quote.premiumBreakup.externalCng,
								quote.premiumBreakup.cngFitted]) |
								currency:
								"INR":"symbol"
								}}</strong></div>
					</div>
				</div>

				<div class="dcmodal-content-row dcmodal-content-heading">
					<strong>Discount (-)</strong>
				</div>

				<div class="dcmodal-content-row">
					<div class="d-flex breakup-text-desc justify-content-between">
						<div class="breakup-info ng-binding">NCB</div>
						<div class="breakup-price ng-binding"> {{
							(quote.premiumBreakup && quote.premiumBreakup.ncbDiscount
							? quote.premiumBreakup.ncbDiscount
							: 0
							) | currency: "INR":"symbol"
							}}</div>
					</div>
				</div>

				<div *ngIf="quote.premiumBreakup.aaieDiscount && quote.premiumBreakup.aaieDiscount!=0"
					class="dcmodal-content-row">
					<div class="d-flex breakup-text-desc justify-content-between">
						<div class="breakup-info ng-binding">Automobile Association Disc</div>
						<div class="breakup-price ng-binding"> {{
							(quote.premiumBreakup && quote.premiumBreakup.aaieDiscount
							? quote.premiumBreakup.aaieDiscount
							: 0
							) | currency: "INR":"symbol"
							}}</div>
					</div>
				</div>

				<div *ngIf="quote.premiumBreakup.araiApproval && quote.premiumBreakup.araiApproval!=0"
					class="dcmodal-content-row">
					<div class="d-flex breakup-text-desc justify-content-between">
						<div class="breakup-info ng-binding">Anti-theft Fitted</div>
						<div class="breakup-price ng-binding"> {{
							(quote.premiumBreakup && quote.premiumBreakup.araiApproval
							? quote.premiumBreakup.araiApproval
							: 0
							) | currency: "INR":"symbol"
							}}</div>
					</div>
				</div>

				<div *ngIf="quote.premiumBreakup.voluntaryDiscount && quote.premiumBreakup.voluntaryDiscount!=0"
					class="dcmodal-content-row">
					<div class="d-flex breakup-text-desc justify-content-between">
						<div class="breakup-info ng-binding">Voluntary Discount</div>
						<div class="breakup-price ng-binding"> {{
							(quote.premiumBreakup && quote.premiumBreakup.voluntaryDiscount
							? quote.premiumBreakup.voluntaryDiscount
							: 0
							) | currency: "INR":"symbol"
							}}</div>
					</div>
				</div>

				<div class="dcmodal-content-row">

					<div class="d-flex breakup-text-desc justify-content-between">
						<div class="breakup-info">
							<strong>(B) Total Discount</strong>
						</div>
						<div class="breakup-price"><strong class="ng-binding">{{
								totalValue([quote.premiumBreakup.ncbDiscount,
								quote.premiumBreakup.aaieDiscount
								,quote.premiumBreakup.araiApproval,quote.premiumBreakup.voluntaryDiscount]) | currency:
								"INR":"symbol"
								}}</strong></div>
					</div>

				</div>

				<div class="dcmodal-content-row dcmodal-content-heading">

					<strong>TP Premium (+)</strong>

				</div>

				<div class="dcmodal-content-row"
					ng-show="PremiumBreakup.PremiumBreakUpDetails.BasicThirdPartyLiability > 0">
					<div class="d-flex breakup-text-desc justify-content-between">
						<div class="breakup-info">Basic Third Party Liability</div>
						<div class="breakup-price ng-binding"> {{
							(quote.premiumBreakup && quote.premiumBreakup.thirdPartyCover
							? quote.premiumBreakup.thirdPartyCover
							: 0
							) | currency: "INR":"symbol"
							}}</div>
					</div>
				</div>

				<div *ngIf="quote.premiumBreakup.paCover && quote.premiumBreakup.paCover!=0"
					class="dcmodal-content-row">
					<div class="d-flex breakup-text-desc justify-content-between">
						<div class="breakup-info">PA Cover To Owned Driver</div>
						<div class="breakup-price ng-binding"> {{
							(quote.premiumBreakup && quote.premiumBreakup.paCover
							? quote.premiumBreakup.paCover
							: 0
							) | currency: "INR":"symbol"
							}}</div>
					</div>
				</div>

				<div *ngIf="quote.premiumBreakup.paidDriver && quote.premiumBreakup.paidDriver!=0"
					class="dcmodal-content-row">
					<div class="d-flex breakup-text-desc justify-content-between">
						<div class="breakup-info">Paid Driver Cover</div>
						<div class="breakup-price ng-binding"> {{
							(quote.premiumBreakup && quote.premiumBreakup.paidDriver
							? quote.premiumBreakup.paidDriver
							: 0
							) | currency: "INR":"symbol"
							}}</div>
					</div>
				</div>

				<div *ngIf="quote.premiumBreakup.unnamedPassenger && quote.premiumBreakup.unnamedPassenger!=0"
					class="dcmodal-content-row">
					<div class="d-flex breakup-text-desc justify-content-between">
						<div class="breakup-info">Unnamed PA Cover</div>
						<div class="breakup-price ng-binding"> {{
							(quote.premiumBreakup && quote.premiumBreakup.unnamedPassenger
							? quote.premiumBreakup.unnamedPassenger
							: 0
							) | currency: "INR":"symbol"
							}}</div>
					</div>
				</div>


				<div *ngIf="quote.premiumBreakup.externalCngTP && quote.premiumBreakup.externalCngTP!=0"
					class="dcmodal-content-row">
					<div class="d-flex breakup-text-desc justify-content-between">
						<div class="breakup-info">Cng Fitted</div>
						<div class="breakup-price ng-binding"> {{
							quote.premiumBreakup.externalCngTP | currency: "INR":"symbol"
							}}</div>
					</div>
				</div>


				<div *ngIf="quote.premiumBreakup.tppdDiscount && quote.premiumBreakup.tppdDiscount!=0"
					class="dcmodal-content-row">
					<div class="d-flex breakup-text-desc justify-content-between">
						<div class="breakup-info">TPPD Discount</div>
						<div class="breakup-price ng-binding"> - {{
							(quote.premiumBreakup && quote.premiumBreakup.tppdDiscount
							? quote.premiumBreakup.tppdDiscount
							: 0
							) | currency: "INR":"symbol"
							}}</div>
					</div>
				</div>


				<div class="dcmodal-content-row">

					<div class="d-flex breakup-text-desc justify-content-between">
						<div class="breakup-info">
							<strong>(C) Total TP Premium</strong>
						</div>
						<div class="breakup-price"><strong class="ng-binding"> {{
								minusValue([totalValue([quote.premiumBreakup.thirdPartyCover,
								quote.premiumBreakup.paCover
								,quote.premiumBreakup.paidDriver
								,quote.premiumBreakup.unnamedPassenger
								,quote.premiumBreakup.externalCngTP]) , quote.premiumBreakup.tppdDiscount]) | currency:
								"INR":"symbol"
								}}</strong></div>
					</div>

				</div>

				<div class="dcmodal-content-row dcmodal-content-heading">

					<strong>Addon Premium (+)</strong>
				</div>


				<div *ngIf="quote.premiumBreakup.consumables && quote.premiumBreakup.consumables!=0"
					class="dcmodal-content-row">
					<div class="d-flex breakup-text-desc justify-content-between">
						<div class="breakup-info">
							{{
							quote.providerName == "reliance-general"? "Secure Plus" : "consumables"
							}}
						</div>
						<div class="breakup-price ng-binding"> {{
							(quote.premiumBreakup && quote.premiumBreakup.consumables
							? quote.premiumBreakup.consumables
							: 0
							) | currency: "INR":"symbol"
							}}</div>
					</div>
				</div>


				<div *ngIf="quote.premiumBreakup.dailyAllowance && quote.premiumBreakup.dailyAllowance!=0"
					class="dcmodal-content-row">
					<div class="d-flex breakup-text-desc justify-content-between">
						<div class="breakup-info">Daily Allowance</div>
						<div class="breakup-price ng-binding"> {{
							(quote.premiumBreakup && quote.premiumBreakup.dailyAllowance
							? quote.premiumBreakup.dailyAllowance
							: 0
							) | currency: "INR":"symbol"
							}}</div>
					</div>
				</div>

				<div *ngIf="quote.premiumBreakup.engineProtection && quote.premiumBreakup.engineProtection!=0"
					class="dcmodal-content-row">
					<div class="d-flex breakup-text-desc justify-content-between">
						<div class="breakup-info">Engine Protector</div>
						<div class="breakup-price ng-binding"> {{
							(quote.premiumBreakup && quote.premiumBreakup.engineProtection
							? quote.premiumBreakup.engineProtection
							: 0
							) | currency: "INR":"symbol"
							}}</div>
					</div>
				</div>

				<div *ngIf="quote.premiumBreakup.keyReplacement && quote.premiumBreakup.keyReplacement!=0"
					class="dcmodal-content-row">
					<div class="d-flex breakup-text-desc justify-content-between">
						<div class="breakup-info">
							{{
							quote.providerName == "reliance-general"? "Secure Premium" : "Key Replacement"
							}}
						</div>
						<div class="breakup-price ng-binding"> {{
							(quote.premiumBreakup && quote.premiumBreakup.keyReplacement
							? quote.premiumBreakup.keyReplacement
							: 0
							) | currency: "INR":"symbol"
							}}</div>
					</div>
				</div>

				<div *ngIf="quote.premiumBreakup.lossOfPersonalBelongins  && quote.premiumBreakup.lossOfPersonalBelongins!=0"
					class="dcmodal-content-row">
					<div class="d-flex breakup-text-desc justify-content-between">
						<div class="breakup-info">Loss of Personal Belongings</div>
						<div class="breakup-price ng-binding"> {{
							(quote.premiumBreakup && quote.premiumBreakup.lossOfPersonalBelongins
							? quote.premiumBreakup.lossOfPersonalBelongins
							: 0
							) | currency: "INR":"symbol"
							}}</div>
					</div>
				</div>

				<div *ngIf="quote.premiumBreakup.ncbProtector && quote.premiumBreakup.ncbProtector!=0"
					class="dcmodal-content-row">
					<div class="d-flex breakup-text-desc justify-content-between">
						<div class="breakup-info">NCB Protector</div>
						<div class="breakup-price ng-binding"> {{
							(quote.premiumBreakup && quote.premiumBreakup.ncbProtector
							? quote.premiumBreakup.ncbProtector
							: 0
							) | currency: "INR":"symbol"
							}}</div>
					</div>
				</div>

				<div *ngIf="quote.premiumBreakup.returnToInvoice && quote.premiumBreakup.returnToInvoice!=0"
					class="dcmodal-content-row">
					<div class="d-flex breakup-text-desc justify-content-between">
						<div class="breakup-info">Invoice Price</div>
						<div class="breakup-price ng-binding"> {{
							(quote.premiumBreakup && quote.premiumBreakup.returnToInvoice
							? quote.premiumBreakup.returnToInvoice
							: 0
							) | currency: "INR":"symbol"
							}}</div>
					</div>
				</div>


				<div *ngIf="quote.premiumBreakup.rimProtection  && quote.premiumBreakup.rimProtection!=0"
					class="dcmodal-content-row">
					<div class="d-flex breakup-text-desc justify-content-between">
						<div class="breakup-info">Rim Protection</div>
						<div class="breakup-price ng-binding"> {{
							(quote.premiumBreakup && quote.premiumBreakup.rimProtection
							? quote.premiumBreakup.rimProtection
							: 0
							) | currency: "INR":"symbol"
							}}</div>
					</div>
				</div>

				<div *ngIf="quote.premiumBreakup.roadsideAssistance && quote.premiumBreakup.roadsideAssistance!=0"
					class="dcmodal-content-row">
					<div class="d-flex breakup-text-desc justify-content-between">
						<div class="breakup-info">Emergency Assistance</div>
						<div class="breakup-price ng-binding"> {{
							(quote.premiumBreakup && quote.premiumBreakup.roadsideAssistance
							? quote.premiumBreakup.roadsideAssistance
							: 0
							) | currency: "INR":"symbol"
							}}</div>
					</div>
				</div>

				<div *ngIf="quote.premiumBreakup.tyreProtection  && quote.premiumBreakup.tyreProtection!=0"
					class="dcmodal-content-row">
					<div class="d-flex breakup-text-desc justify-content-between">
						<div class="breakup-info">Tyre Protector</div>
						<div class="breakup-price ng-binding"> {{
							(quote.premiumBreakup && quote.premiumBreakup.tyreProtection
							? quote.premiumBreakup.tyreProtection
							: 0
							) | currency: "INR":"symbol"
							}}</div>
					</div>
				</div>


				<div *ngIf="quote.premiumBreakup.zeroDepreciation && quote.premiumBreakup.zeroDepreciation!=0"
					class="dcmodal-content-row">
					<div class="d-flex breakup-text-desc justify-content-between">
						<div class="breakup-info">Nil Depreciation</div>
						<div class="breakup-price ng-binding"> {{
							(quote.premiumBreakup && quote.premiumBreakup.zeroDepreciation
							? quote.premiumBreakup.zeroDepreciation
							: 0
							) | currency: "INR":"symbol"
							}}</div>
					</div>
				</div>
				<div class="d-flex breakup-text-desc justify-content-between dcmodal-content-row">
					<div class="breakup-info">
						<strong>(D) Total AddOn Premium</strong>
					</div>
					<div class="breakup-price"><strong class="ng-binding">

							{{
							totalValue([quote.premiumBreakup.consumables
							,quote.premiumBreakup.dailyAllowance
							,quote.premiumBreakup.engineProtection
							,quote.premiumBreakup.keyReplacement
							,quote.premiumBreakup.lossOfPersonalBelongins
							,quote.premiumBreakup.ncbProtector
							,quote.premiumBreakup.returnToInvoice
							,quote.premiumBreakup.rimProtection
							,quote.premiumBreakup.roadsideAssistance
							,quote.premiumBreakup.tyreProtection
							,quote.premiumBreakup.zeroDepreciation
							]) | currency: "INR":"symbol"
							}}
						</strong></div>
				</div>
				<div class="dcmodal-footer-row">
					<div class="d-flex dcmodal-footer-content align-items-center justify-content-center">
						<div class="footer-content-box">
							<div class="calculate-info"><strong>Net Premium (A-B) + C + D</strong></div>
							<div class="calculate-price ng-binding"> {{
								(quote && quote.netPremium
								? quote.netPremium
								: 0
								) | currency: "INR":"symbol"
								}}</div>
						</div>

						<span class="calculate-sign">+</span>

						<div class="footer-content-box">
							<div class="calculate-info"><strong>GST 18%</strong></div>
							<div class="calculate-price ng-binding"> {{
								(quote && quote.totalTax
								? quote.totalTax
								: 0
								) | currency: "INR":"symbol"
								}}</div>
						</div>

						<span class="calculate-sign">=</span>

						<div class="footer-content-box total">
							<div class="calculate-info">Final Premium Payable</div>
							<div class="calculate-price ng-binding"> {{
								(quote && quote.grossPremium
								? quote.grossPremium
								: 0
								) | currency: "INR":"symbol"
								}}</div>
						</div>

					</div>
				</div>

			</div>
		</div>
	</div>
</div>