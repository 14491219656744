<div class="row">
	<div class="col-sm-12 col-lg-3">
		<img src="/assets/images/our_partners/{{quote!.providerName}}.png" class="companySpecificLogo card-img" />
	</div>
	<div class="col-sm-12 col-lg-9">
		<div class="row">
			<div class="col-sm-12 col-lg-6">
				<ul class="list-group list-group-flush">
					<li class="list-group-item">Proposal #: <span class="pl-5 proposal-ID" data-bs-toggle="tooltip"
							data-bs-placement="bottom" title="{{quote.proposalId}}">{{quote.proposalId}}</span></li>
					<li class="list-group-item">New Policy: <span class="pl-5">{{quote.newPolicyType}}</span></li>
					<li class="list-group-item">Start Date: <span class="pl-5">{{quote.riskStartDate | date:
							"dd-MM-yyyy"}}</span></li>
				</ul>
			</div>
			<div class="col-sm-12 col-lg-6">
				<ul class="list-group list-group-flush">
					<li class="list-group-item">Proposal Date: <span class="pl-5">{{quote.proposalDate | date:
							"dd-MM-yyyy"}}</span></li>
					<li class="list-group-item">IDV: <span class="pl-5">{{quote.idv | currency:
							"INR":"symbol"}}</span></li>
					<li class="list-group-item">End Date: <span class="pl-5">{{quote.riskEndDate | date:
							"dd-MM-yyyy"}}</span></li>

				</ul>
			</div>
		</div>
	</div>
</div>