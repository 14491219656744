<div class="row">
    <div class="col-10 col-md-10">
        <div class="ms-md-2" ngClass.lt-sm="mt-2">
            <h4 class="text-heading mb-1">Vehicle Profile</h4>
            <div *ngIf="!isEditMode">
                <p class="m-0"><span class="text--sm">
                        <strong>{{ motorProfile.make }} {{ motorProfile.model}} {{ motorProfile.variant}}, {{
                            motorProfile.fuelType}}</strong></span></p>
                <p class="m-0"><span class="text--sm">
                    <strong>{{ motorProfile.rtoLoc }} </strong></span></p>
            </div>
        </div>
    </div>
    <!-- <div fxShow="true" class="col-2 col-md-2" *ngIf="!isEditMode" (click)="openEditModalDialog()">
        <button mat-icon-button class="mat-icon-button">
            <mat-icon>edit</mat-icon>
        </button>
    </div> -->
</div>