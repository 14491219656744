<div>
	<mat-toolbar color="primary" class="p-0">
		<div class="container m-0 container-real-insurance">
			<div mat-button class="mainLogo">
				<a [routerLink]="['/']" title="Real Insurance Pvt. Ltd.">
					<img src="/assets/images/logo.png" alt="Real Insurance Pvt. Ltd." />
				</a>
			</div>
			<span class="toolbar-spacer"></span>
			<div fxShow="true" fxHide.lt-md="true">
				<ul class="toolbar-items-ul align-items-center mb-0">
					<li class="padding-right-menu" title="Real Insurance Pvt. Ltd.">
						<a [routerLink]="['/']"><i class="fa fa-home pr-10" aria-hidden="true"></i>Home</a>
					</li>
					<li class="padding-right-menu" title="Call">
						<a href="callto:+919431813129"><i class="fa fa-phone pr-10" aria-hidden="true"></i>+91
							9431813129</a>
					</li>
					<li title="POS Login">
						<a href="{{b2cEndPoint}}" target="_blank"><i aria-hidden="true"
								class="fa fa-sign-in pr-10"></i>POSP Access</a>
					</li>
					<!-- <li *ngIf="user" title="Dashboard" class="padding-right-menu">
						<a [routerLink]="['/admin']"><i aria-hidden="true"
								class="fa fa-tachometer pr-10"></i>Dashboard</a>
					</li> -->
					<!-- <li *ngIf="user" title="Logged-In User">
						<div ngbDropdown class="display-inline-block dropdown p-0">
							<button ngbDropdownToggle class="btn px-0" type="button" id="dropdownMenuButton1"
								aria-expanded="false">
								<span>Welcome </span><span>{{ user.name }}</span>
							</button>
							<ul ngbDropdownMenu aria-labelledby="dropdownMenuButton1">
								<li><a ngbDropdownItem (click)="logout()">logout</a></li>
							</ul>
						</div>
					</li> -->
				</ul>
			</div>
			<div fxShow="true" fxHide.gt-sm="true">
				<button mat-icon-button (click)="onToggleSidenav()" class="mat-icon-button sidenav-toggle-button">
					<mat-icon>menu</mat-icon>
				</button>
			</div>
		</div>
	</mat-toolbar>
</div>