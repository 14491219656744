import { Component, OnInit, Input } from '@angular/core';
import {
	productType
} from "src/app/features/policy-config";

import { InsuranceService } from 'src/app/features/services/insurance.service';
import { CrmFlowService } from 'src/app/features/services/crm-flow.service';
import { ApiService } from 'src/app/core/services/api.service';
import { DynamoResponse, LeadResponse } from 'src/app/features/interfaces/leadResponse';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-insurance-info',
	templateUrl: './insurance-info.component.html',
	styleUrls: ['./insurance-info.component.scss']
})
export class InsuranceInfoComponent implements OnInit {
	public isLoading: boolean = false;
  public submitted: boolean = false;
	@Input() selectedInsuranceType: string;
	public productTypeObj: Array<any> = productType;

	constructor(
		private insuranceService: InsuranceService,
		private crmFlow: CrmFlowService,
		private apiService: ApiService,
		private router: Router,
		private logger: LoggerService
	) { }

	ngOnInit(): void {
	}

  public onInsuranceFormSubmitted(inputObj): void{
    this.createLeadRequest(inputObj);
  }

	public createLeadRequest(formInput): void {
    this.isLoading = true;
		const leadReqId = this.insuranceService.fetchSessionValue("leadReqId");
    let leadPayload = this.crmFlow.createLeadPayload(formInput);

    if (!leadReqId) {
			this.apiService.createLead(leadPayload).subscribe(
				(leadResponse: DynamoResponse) => {
					this.processApiSuccessResponse(leadResponse);
				},
				(error) => {
					this.processApiErrorResponse(error);
				}
			);
		} else {
      const leadCreatedOn = this.insuranceService.fetchSessionValue("leadCreatedOn");
      leadPayload["createdOn"] = leadCreatedOn;
      leadPayload["modifiedOn"] = this.crmFlow.getCurrentTimestamp();
      this.apiService.updateLead(leadPayload, leadReqId).subscribe(
				(leadResponse: LeadResponse) => {
					this.processApiSuccessResponse(leadResponse);
				},
				(error) => {
					this.processApiErrorResponse(error);
				}
			);
		}
	}

	processApiSuccessResponse(data) {
    const leadId = (data.recordId)?data.recordId:data.leadId;
    const createdOn = (data.recordId)?data.leadsInput.createdOn:data.createdOn
    setTimeout(() => {
			// this.isLoading = false;
			this.insuranceService.storeValue("leadReqId", leadId);
      this.insuranceService.storeValue("leadCreatedOn", createdOn);
			this.router.navigate(['/feature/compare-quotes']);
		}, 500)
	}

	processApiErrorResponse(errorResponse) {
		this.logger.info(errorResponse);
		// this.errorMessage = true;
	}

}
