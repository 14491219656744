<div class="container ">
    <app-loader *ngIf="isLoading"></app-loader>
    <div class="row" *ngIf="apiResponse">
        <div class="col-lg-8 col-md-12">
            <div class="row">
                <div class="col">
                    <h2 class="text-heading card-header pb-2 pt-2 mb-2">Confirm and Make Payment</h2>
                </div>
            </div>
            <app-new-policy-summary *ngIf="newPolicyObj" [quote]="newPolicyObj"></app-new-policy-summary>
            <app-item-value [quote]="apiResponse.motorProfile" [section]="reviewSectionObj.motorProfile">
            </app-item-value>
            <app-item-value [quote]="apiResponse.prePolicyInfo" *ngIf="isPreviousPolicyExists"
                [section]="reviewSectionObj.previousPolicy"></app-item-value>
            <app-item-value [quote]="apiResponse.ncbInfo" *ngIf="apiResponse.ncbInfo" [section]="reviewSectionObj.ncb">
            </app-item-value>
            <app-item-value [quote]="apiResponse.tpPolicyInfo" *ngIf="isTpPolicyFlag"
                [section]="reviewSectionObj.tpPolicy">
            </app-item-value>
            <app-item-value [quote]="addonsObj" *ngIf="(addonsObj | json) != '{}'" [section]="reviewSectionObj.addons">
            </app-item-value>
            <app-item-value [quote]="apiResponse.personalInfo" [section]="reviewSectionObj.personal"></app-item-value>
            <app-item-value [quote]="apiResponse.addresses" [section]="reviewSectionObj.addresses"></app-item-value>
            <app-item-value [quote]="apiResponse.nominee" *ngIf="isNomineeFlag" [section]="reviewSectionObj.nominee">
            </app-item-value>
            <app-item-value *ngIf="extraObj" [quote]="crmObj.extra" [section]="reviewSectionObj.extra"></app-item-value>
        </div>
        <div class="col-lg-4 col-md-12">
            <app-premium-details [premium]="premiumObj" [quote]="apiResponse"></app-premium-details>
            <mat-checkbox (change)="changeCheck($event)">I agree Terms & Conditions</mat-checkbox>
            <app-payment-form *ngIf="paymentProcessingType == 'paymentform'" [payment]="paymentOptions"
                [disabledAgreement]="disabledAgreement"></app-payment-form>
            <button class="btn btn-real btn-block btn-login w-100 mt-3" (click)="onBuyNow()"
                [disabled]="disabledAgreement" *ngIf="paymentProcessingType !== 'paymentform'">
                <i class="fa fa-shopping-cart pr-10" aria-hidden="true"></i>Purchase Now
            </button>
        </div>
    </div>
</div>