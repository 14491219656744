import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Output, EventEmitter } from '@angular/core';
import { policyHolderGenderArr } from 'src/app/features/policy-config';
import { male, female } from 'src/app/features/health-insurance-config';
@Component({
	selector: 'app-gender-selection',
	templateUrl: './gender-selection.component.html',
	styleUrls: ['./gender-selection.component.scss']
})
export class GenderSelectionComponent implements OnInit {
	genderSelectionForm: FormGroup;
	genderList: Array<any> = policyHolderGenderArr;
	maleInput: string = male;
	femaleInput: string = female;
	isSubmitted: boolean = false;
	isLoading: boolean = true;
	isMale: boolean;
	isFemale: boolean;
	@Output() nextButtonClicked = new EventEmitter<object>();

	constructor(
		private formBuilder: FormBuilder
	) { }

	ngOnInit(): void {
		this.genderSelectionForm = this.createFormGroup();
		setTimeout(() => {
			this.genderList.pop();
			this.isLoading = false;
		}, 800)
	}

	get form() {
		return this.genderSelectionForm.controls;
	}

	createFormGroup() {
		return this.formBuilder.group({
			gender: [
				null,
				[Validators.required],
			],
		});
	}

	genderSelection(selection) {
		if (selection == this.genderList[0]) {
			this.isMale = true;
			this.isFemale = false;
		} else {
			this.isMale = false;
			this.isFemale = true;
		}
		this.genderSelectionForm.patchValue({
			"gender": selection
		})
	}

	onSubmit(): void {
		this.isSubmitted = true;
		if (this.genderSelectionForm.invalid) {
			return;
		}
		this.nextButtonClicked.emit(this.genderSelectionForm.value);
	}
}
