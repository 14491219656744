<div class="container">
    <app-loader *ngIf="isLoading"></app-loader>
    <div class="row mt-3 pb-3" *ngIf="crmObj">
        <div class="col">
            <div class="row">
                <div class="col-lg-8 offset-lg-2">
                    <div *ngIf="!source" class="alert alert-success text-center pt-3" role="alert">
                        <h4 class="alert-heading">
                            <i class="fa fa-check pr-10" aria-hidden="true"></i> Congratulations
                        </h4>
                        <p>We have successfully generated the payment link for your purchase with the insurance
                            provider <span
                                class="alert-heading--normal">{{crmObj.newPolicyInfo.insuranceProvider}}</span></p>
                        <hr>
                        <div class="input-group mb-3">
                            <input type="text" value="{{crmObj.extra.paymentLink}}" aria-label="Disabled input example"
                                disabled readonly class="form-control" />
                            <button class="btn btn-outline-secondary btn-selected" (click)="sendEmail()"
                                id="button-addon2">Send
                                Email</button>
                        </div>
                        <p *ngIf="isEMailSent">Email sent</p>
                    </div>
                    <div *ngIf="source" class="alert alert-success text-center pt-3" role="alert">
                        <h4 class="alert-heading">
                            <i class="fa fa-check pr-10" aria-hidden="true"></i> Thank You
                        </h4>
                        <p>For generating self inspection link and pin <span
                                class="alert-heading--normal">{{crmObj.newPolicyInfo.insuranceProvider}}</span></p>
                        <hr>
                        <p>Link has sent to your mobile no shared with us</p>
                        <!-- <div class="input-group mb-3">
                            <input type="text" value="{{crmObj.extra.paymentLink}}" aria-label="Disabled input example"
                                disabled readonly class="form-control" />
                            <button class="btn btn-outline-secondary btn-selected" (click)="sendEmail()"
                                id="button-addon2">Send
                                Email</button>
                        </div>
                        <p *ngIf="isEMailSent">Email sent</p> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>