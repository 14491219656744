<div class="container custom-p-t-3 container-real-insurance">
  <div class="row">
    <div class="col-12">
      <app-insurance-type (selectedInsurance)="onSelectedInsuranceReceived($event)"></app-insurance-type>
    </div>
    <div class="col-12" *ngIf="isPageAccessAllowed">
      <app-insurance-info [selectedInsuranceType]="selectedInsuranceType"></app-insurance-info>
    </div>
    <div class="col-6 m-auto mt-5" *ngIf="!isPageAccessAllowed">
      <p class="text-center p-2 alert alert-warning">
        <span class="text-danger"><strong><i class="fa fa fa-exclamation-triangle pr-5" aria-hidden="true"></i>Please
            complete your account activation process to access this feature </strong></span>
      </p>
    </div>
  </div>
</div>