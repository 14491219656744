import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment'

@Component({
	selector: 'app-sidenav-list',
	templateUrl: './sidenav-list.component.html',
	styleUrls: ['./sidenav-list.component.scss']
})
export class SidenavListComponent implements OnInit {
	@Output() sidenavClose = new EventEmitter();
	public b2cEndPoint:string = environment.b2cEndPoint

	constructor() { }

	ngOnInit(): void {
	}

	public onSidenavClose = () => {
		this.sidenavClose.emit();
	}

}
