<app-loader *ngIf="isLoading"></app-loader>
<div class="col-12 p-0">
  <input type="button" class="btn btn-default btn-real mr-5p"
    [ngClass]="selectedCar === purchaseTypeObj.old ? 'btn-selected' : ''"
    (click)="onCarSelectionChange(purchaseTypeObj.old);" id="{{purchaseTypeObj.old}}" [value]="renewItem">
  <input type="button" class="btn btn-default btn-real"
    [ngClass]="selectedCar === purchaseTypeObj.new ? 'btn-selected' : ''"
    (click)="onCarSelectionChange(purchaseTypeObj.new);" id="{{purchaseTypeObj.new}}" [value]="newItem">
</div>
<div class="col-12 ">
  <form [formGroup]="insuranceForm" class="mt-4" (submit)="getQuotes()">
    <div class="form-group">
      <div class="row">
        <div [ngClass]="selectedCar === purchaseTypeObj.old ? 'col-lg-3' : 'col-lg-4'"
          class="mt-2 pl-0 px-0 pe-2  min-height col-sm-12">
          <mat-form-field class="w-100" appearance='outline'>
            <input matInput placeholder="Make" aria-label="Manufacturer" [matAutocomplete]="auto"
              formControlName="make" class="custom-typehead">
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onMakeChange($event)">
              <mat-option *ngFor="let item of filteredMakes | async" [value]="item.itemName">
                <span>{{ item.itemName }}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div class="invalid-feedback" *ngIf="submitted && form.make.errors">
            <span class="text-danger p-0" *ngIf="form.make.errors.required">Make is required</span>
          </div>
        </div>
        <div [ngClass]="selectedCar === purchaseTypeObj.old ? 'col-lg-3' : 'col-lg-4'"
          class="mt-2 pl-0 px-0 pe-2  col-sm-12 min-height">
          <mat-form-field class="w-100" appearance='outline'>
            <input matInput placeholder="Model" aria-label="Model" [matAutocomplete]="autoModel"
              formControlName="model" class="custom-typehead" [ngModel]="selectedModel">
            <mat-autocomplete #autoModel="matAutocomplete" (optionSelected)="onModelChange($event)">
              <mat-option *ngFor="let item of filteredModel | async" [value]="item.modelName">
                <span>{{ item.modelName }}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div class="invalid-feedback" *ngIf="submitted && form.model.errors">
            <span class="text-danger p-0" *ngIf="form.model.errors.required">Model is
              required</span>
          </div>
        </div>
        <div [ngClass]="selectedCar === purchaseTypeObj.old ? 'col-lg-3' : 'col-lg-4'"
          class="mt-2 pl-0 px-0 pe-2  min-height col-sm-12">
          <mat-form-field class="w-100" appearance='outline'>
            <input matInput placeholder="Fuel Type" aria-label="engine"
              [matAutocomplete]="autoEngine" formControlName="engine" class="custom-typehead"
              [ngModel]="selectedEngine">
            <mat-autocomplete #autoEngine="matAutocomplete"
              (optionSelected)="onEngineChange($event)">
              <mat-option *ngFor="let item of filteredEngines | async" [value]="item.fuelType">
                <span>{{ item.fuelType }}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div class="invalid-feedback" *ngIf="submitted && form.engine.errors">
            <span class="text-danger p-0" *ngIf="form.engine.errors.required">Engine Type is
              required</span>
          </div>
        </div>

        <div [ngClass]="selectedCar === purchaseTypeObj.old ? 'col-lg-3' : 'col-lg-4'"
          class="mt-2 pl-0 px-0 pe-2  min-height col-sm-12">
          <mat-form-field class="w-100" appearance='outline'>
            <input matInput placeholder="Variant" aria-label="Variant"
              [matAutocomplete]="autoVariant" formControlName="variant" class="custom-typehead"
              [ngModel]="selectedVariant">
            <mat-autocomplete #autoVariant="matAutocomplete"
              (optionSelected)="onVariantChange($event)">
              <mat-option *ngFor="let item of filteredVariants | async"
                [value]="item.variantName">
                <span>{{ item.variantName }} {{item.CC}}cc </span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div class="invalid-feedback" *ngIf="submitted && form.variant.errors">
            <span class="text-danger p-0" *ngIf="form.variant.errors.required">Variant is
              required</span>
          </div>
        </div>
        <div [ngClass]="selectedCar === purchaseTypeObj.old ? 'col-lg-3' : 'col-lg-4'"
          class="mt-2 pl-0 px-0 pe-2  min-height col-sm-12">
          <mat-form-field class="w-100" appearance='outline'>
            <input type="text" matInput placeholder="RTO" aria-label="Number"
              [matAutocomplete]="autoRto" formControlName="rto" class="custom-typehead"
              oninput="this.value = this.value.toUpperCase();">
            <mat-autocomplete #autoRto="matAutocomplete">
              <mat-option *ngFor="let item of filteredRto | async" [value]="item.Name">
                <span>{{ item.Name }}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div class="invalid-feedback" *ngIf="submitted && form.rto.errors">
            <!-- <span class="text-danger p-0" *ngIf="form.rto.errors.required">RTO is required</span> -->
            <span class="text-danger p-0" *ngIf="form.rto.errors.invalid">Valid RTO is
              required</span>
          </div>
        </div>
        <div class="col-lg-3 mt-2 pl-0 px-0 pe-2  min-height col-sm-12"
          *ngIf="selectedCar === purchaseTypeObj.old">
          <mat-form-field class="w-100" appearance="outline">
            <input matInput [matDatepicker]="regDatePicker" onkeydown="return false"
              placeholder="Registration Date" formControlName="registrationDate"
              [min]="minRegistrationDate" [max]="maxRegistrationDate" />
            <mat-datepicker-toggle matSuffix [for]="regDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #regDatePicker ngDefaultControl></mat-datepicker>
          </mat-form-field>
          <div class="invalid-feedback" *ngIf="submitted && form.registrationDate.errors">
            <span class="text-danger p-0" *ngIf="form.registrationDate.errors.required">Registration
              Date is
              required</span>
          </div>
        </div>
        <div [ngClass]="selectedCar === purchaseTypeObj.old ? 'col-lg-3' : 'col-lg-4'"
          class="mt-2 pl-0 px-0 pe-2  min-height col-sm-12">
          <mat-form-field class="w-100" appearance="outline">
            <input matInput placeholder="Mobile Number" autocomplete="mobile"
              formControlName="mobile" maxlength="10" />
          </mat-form-field>
          <div class="invalid-feedback" *ngIf="submitted && form.mobile.errors">
            <span class="text-danger p-0" *ngIf="form.mobile.errors.required">Mobile is
              required</span>
            <span class="text-danger p-0" *ngIf="form.mobile.errors.pattern">
              Mobile Number should start with any of number 6. 7, 8, 9!
            </span>
          </div>
        </div>
        <div [ngClass]="selectedCar === purchaseTypeObj.old ? 'col-lg-3' : 'col-lg-4'"
          class="mt-2 pl-0 px-0 pe-2  min-height col-sm-12">
          <mat-form-field class="w-100" appearance="outline">
            <input matInput placeholder="Email ID" autocomplete="off" formControlName="email" />
          </mat-form-field>
          <div class="invalid-feedback" *ngIf="submitted && form.email.errors">
            <span class="text-danger p-0" *ngIf="form.email.errors.required">Email is
              required</span>
            <span class="text-danger p-0" *ngIf="form.email.errors.email">Email must be a valid
              email
              address!</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center my-5">
          <button class="btn btn-default btn-real btn-selected" id="getQuotes" >
            GET QUOTES
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
