<div class="container ">
    <app-loader *ngIf="isLoading"></app-loader>
    <div class="row" *ngIf="apiResponse">
        <div class="col-lg-8 col-md-12">
            <div class="row">
                <div class="col">
                    <h2 class="text-heading card-header pb-2 pt-2 mb-2">Review & Confirm</h2>
                </div>
            </div>
            <app-new-policy-summary *ngIf="newPolicyObj" [quote]="newPolicyObj"></app-new-policy-summary>
            <app-item-value [quote]="apiResponse.motorProfile" [section]="reviewSectionObj.motorProfile">
            </app-item-value>
            <app-item-value [quote]="apiResponse.prePolicyInfo" *ngIf="isPreviousPolicyExists"
                [section]="reviewSectionObj.previousPolicy"></app-item-value>
            <app-item-value [quote]="apiResponse.ncbInfo" *ngIf="apiResponse.ncbInfo" [section]="reviewSectionObj.ncb">
            </app-item-value>
            <app-item-value [quote]="apiResponse.tpPolicyInfo" *ngIf="isTpPolicyFlag"
                [section]="reviewSectionObj.tpPolicy">
            </app-item-value>
            <app-item-value [quote]="addonsObj" *ngIf="(addonsObj | json) != '{}'" [section]="reviewSectionObj.addons">
            </app-item-value>
            <app-item-value [quote]="apiResponse.personalInfo" [section]="reviewSectionObj.personal"></app-item-value>
            <app-item-value [quote]="apiResponse.addresses" [section]="reviewSectionObj.addresses"></app-item-value>
            <app-item-value [quote]="apiResponse.nominee" *ngIf="isNomineeFlag" [section]="reviewSectionObj.nominee">
            </app-item-value>
            <app-item-value *ngIf="extraObj" [quote]="crmObj.extra" [section]="reviewSectionObj.extra"></app-item-value>
        </div>
        <div class="col-lg-4 col-md-12">
            <app-premium-details [premium]="premiumObj" [quote]="apiResponse"></app-premium-details>
            <p class="text-center p-2 mt-3 alert alert-info" *ngIf="apiReq.isBreakin">
              <span>
                  <strong>
                      <i class="fa fa-bell pr-5" aria-hidden="true"></i>
                      Your Policy Start Date is {{apiReq.riskStartDate | date:
                      "dd-MM-yyyy"}}
                  </strong>
              </span>
            </p>
            <p class="text-center p-2 mt-3 alert alert-info" *ngIf="apiResponse.kycStatus != kycStatus.complete">
              <span>
                <strong>
                    <i class="fa fa-bell pr-5" aria-hidden="true"></i>
                    Your KYC Status :  {{apiResponse.kycStatus}}
                    <span *ngIf="apiResponse.kycStatus != 'completed'">Your Policy will enforce after the KYC Status completed</span>.
                </strong>
            </span>
            </p>
            <mat-checkbox (change)="changeCheck($event)">I agree Terms & Conditions</mat-checkbox>
            <app-payment-form #paymentInformation *ngIf="paymentProcessingType == paymentGatewayType.form"
                [payment]="paymentOptions" (paymentForm)="onBuyNow($event)" [disabledAgreement]="disabledAgreement">
            </app-payment-form>
            <button class="btn btn-block btn-danger btn-real btn-selected w-100 mt-3" (click)="onBuyNow($event)"
                [disabled]="disabledAgreement" *ngIf="paymentProcessingType !== paymentGatewayType.form">
                <i class="fa fa-shopping-cart pr-10" aria-hidden="true"></i>Purchase Now
            </button>
            <p class="text-center mt-3"> Or </p>
            <button class="btn btn-block btn-secondary btn-outline-light btn-background--gray text-center w-100"
                (click)="onSendEmail($event)">
                <i class="fa fa-envelope pr-10" aria-hidden="true"></i>Send Proposal to Email
            </button>
        </div>
    </div>
    <div class="row" *ngIf="!apiResponse && errorMessage.type!=null">
        <div class="col">
            <div class="alert alert-{{errorMessage.type}}" role="alert">
                <h4 class="alert-heading">Failure</h4>
                <p>{{errorMessage.message}}</p>
                <hr>
                <p class="mb-0"><a (click)="modifyDetails()" class="btn btn-{{errorMessage.type}}"></a></p>
            </div>
        </div>
    </div>
</div>
