import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { InsuranceService } from 'src/app/features/services/insurance.service';
import { CrmFlowService } from 'src/app/features/services/crm-flow.service';
import { LeadResponse } from 'src/app/features/interfaces/leadResponse';
import { LoggerService } from "src/app/core/logger/logger.service";
import { leadStatus } from "src/app/features/policy-config"

@Component({
	selector: 'app-information-stepper',
	templateUrl: './information-stepper.component.html',
	styleUrls: ['./information-stepper.component.scss']
})

export class InformationStepperComponent implements OnInit {
	public crmId: string;
	public crmObj: any;
	public isLoading: boolean = true;
	public isProposalFormSubmitted: boolean = false

	constructor(
		private crmFlow: CrmFlowService,
		private insuranceService: InsuranceService,
		private logger: LoggerService,
		private router: Router
	) { }

	ngOnInit(): void {
		this.getLeadDetailsFromCrm();
	}

	public getLeadId(): void {
		this.crmId = this.insuranceService.fetchSessionValue("leadReqId");
		if (!this.crmId) {
			this.router.navigate(['/']);
		}
	}

	public getLeadDetailsFromCrm(): void {
		if (!this.crmId) {
			this.getLeadId();
		}
		this.crmFlow.getLeadDetails(this.crmId).subscribe((leadResponse: LeadResponse) => {
      this.crmObj = leadResponse[0];
			this.loadPage();
		},
			(error) => {

			})
	}

	public loadPage(): void {
		this.isLoading = false;
	}

	public onProposalFormSubmitted(event): void {
		if (event) {
			this.isLoading = event;
			this.isProposalFormSubmitted = true
			setTimeout(() => {
				this.crmObj = event;
				this.crmObj["commonFields"]["leadStatus"] = leadStatus.proposalInfoUpdated;
				this.callUpdateLeadApi(this.crmObj);
			}, 800);

		}
	}

	public callUpdateLeadApi(leadPayload): void {
		this.crmFlow.updateApi(this.crmId, leadPayload).subscribe(
			(leadResponse: LeadResponse) => {
				this.loadPage();
				if (this.isProposalFormSubmitted) {
					this.router.navigate(["/feature/payment-confirmation"]);
				}
			},
			(error) => {
				this.logger.info(error);
			}
		);
	}

}

