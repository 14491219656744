import { ChangeDetectorRef, Component, HostListener, ViewChild } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { environment } from 'src/environments/environment.default';
import { InsuranceService } from './features/services/insurance.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	@ViewChild("sidenav") sidenav;
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		if (event.target.innerWidth > 959) {
			this.sidenav.close()
		};
	}

	constructor(private idle: Idle, cd: ChangeDetectorRef, private insuranceService: InsuranceService) {
		idle.setIdle(environment.defaultTimeoutSeconds);
		idle.setTimeout(environment.defaultTimeoutSeconds);
		idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

		idle.onIdleStart.subscribe(() => {
			this.insuranceService.clearAll();
			window.location.href = "/";
		});

		idle.onIdleEnd.subscribe(() => {
			cd.detectChanges();
		});
	}

	reset() {
		// we'll call this method when we want to start/reset the idle process
		// reset any component state and be sure to call idle.watch()
		this.idle.watch();
	}

	ngOnInit(): void {
		// right when the component initializes, start reset state and start watching
		this.reset();
	}
}
