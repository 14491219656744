import { Component, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { CrmFlowService } from 'src/app/features/services/crm-flow.service';
import { LeadResponse } from 'src/app/features/interfaces/leadResponse';
import { policyTypeArr, ownerTypeArr, reviewSection, paymentStatus } from 'src/app/features/policy-config';
import { ErrorMessage } from "src/app/features/interfaces/error-message";
import { environment } from 'src/environments/environment.default';


@Component({
	selector: 'app-proposal',
	templateUrl: './proposal.component.html',
	styleUrls: ['./proposal.component.scss']
})
export class ProposalComponent implements OnInit {
	public crmId: string;
	public crmObj: any;
	public pTypeArr: Array<any> = policyTypeArr;
	public oTypeArr: Array<any> = ownerTypeArr;
	public isLoading: boolean = true;
	public isPreviousPolicyExists: boolean = true;
	public isTpPolicyFlag: boolean = false;
	public isNomineeFlag: boolean = false;
	public apiReq: any;
	public apiResponse: any;
	public paymentOptions: any;
	disabledAgreement: boolean = true;
	public errorMessage: ErrorMessage = {
		message: null,
		link: null,
		buttonText: null,
		type: null
	};
	public paymentLink: string;
	public paymentform: any;
	public paymentProcessingType: string;
	public premiumObj: any;
	public newPolicyObj: any;
	public addonsObj: any = {};
	public generatedProposalSub = new Subscription();
	public reviewSectionObj: any = reviewSection;
	public extraObj: any;

	constructor(
		private route: ActivatedRoute,
		private crmFlow: CrmFlowService,
	) { }

	ngOnInit(): void {
		this.getQueryParameter();
	}

	public getQueryParameter(): void {
		this.route.queryParams.subscribe((params) => {
			this.getLeadId(params.refId)
		});
	}

	public getLeadId(inputString): void {
		const param = atob(inputString);
		const param1 = param.replace(environment.secretKey, "")
		const param2 = param1.replace(environment.secretKey, "")
		this.crmId = param2;
		this.getLeadDetailsFromCrm();
	}

	public getLeadDetailsFromCrm(): void {
		this.generatedProposalSub = this.crmFlow.getLeadDetails(this.crmId).subscribe((leadResponse: LeadResponse) => {
			this.crmObj = leadResponse[0];
			this.validateLeadBeforeProposalGeneration();
		},
			(error) => {
				// this.generateQuoteFailed(error, "warning");
			})
	}

	public validateLeadBeforeProposalGeneration(): void {
		const expiryDate = `${this.crmObj.extra.proposalExpiryDate} 23:59:59`;
		if (this.crmFlow.sameDay(expiryDate)) {
			this.generateQuoteResponse();
		} else {
			this.isLoading = false;
			this.errorMessage.message = "Invalid Proposal Link";
			this.errorMessage.link = '/'
			this.errorMessage.buttonText = "Generate New Quotation"
		}
	}


	public generateQuoteResponse() {
		this.isLoading = false;
		this.apiResponse = this.crmObj.extra.respPayload;
		this.setPreviousPolicy()
		this.setTPFlag();
		this.setNomineeFlag();
		this.setPremiumBreakupObj();
		this.setNewPolicyInfoObj();
		this.setAddonsObj();
		this.setPaymentObj();
	}

	public setPaymentObj(): void {
		this.paymentOptions = this.apiResponse.paymentResponse;
		this.paymentLink = this.paymentOptions.paymentUrl;
		this.paymentProcessingType = this.paymentOptions.paymentGatewayType;
		if (this.paymentOptions.inputPayload) {
			this.paymentform = this.paymentOptions.inputPayload;
		}
	}

	public setAddonsObj(): void {
		for (const [key, value] of Object.entries(this.apiResponse.addons)) {
			if (typeof this.apiResponse?.premiumBreakup?.key != "undefined" && this.apiResponse?.premiumBreakup?.key != "0.00") {
				this.addonsObj[key] = this.apiResponse?.premiumBreakup?.key;
			}
		}
	}

	public setNewPolicyInfoObj(): void {
		this.newPolicyObj = {
			providerName: this.apiResponse.providerName,
			proposalId: this.apiResponse.proposalId,
			enquiryId: this.apiResponse.enquiryId,
			proposalDate: this.apiResponse.proposalDate,
			riskStartDate: this.apiResponse.riskStartDate,
			riskEndDate: this.apiResponse.riskEndDate,
			newPolicyType: this.apiResponse.insuranceType,
			productType: this.apiResponse.productType,
			idv: this.apiResponse.selectedIDV,
			policyNo: this.apiResponse.policyNo,
			policyStatus: paymentStatus.pending
		}
	}

	public setPremiumBreakupObj() {
		this.premiumObj = {
			netPremium: this.apiResponse.netPremium ? this.apiResponse.netPremium : this.crmObj.paymentInfo.netPremium,
			totalTax: this.apiResponse.totalTax ? this.apiResponse.totalTax : this.crmObj.paymentInfo.taxAmount,
			grossPremium: this.apiResponse.grossPremium ? this.apiResponse.grossPremium : this.crmObj.paymentInfo.grossPremium,
		}
	}

	public setPreviousPolicy(): void {
		if (this.apiResponse.motorProfile.isNew) {
			this.isPreviousPolicyExists = false;
		} else if (this.apiResponse.isBreakin) {
			this.isPreviousPolicyExists = false;
		}
	}

	public setTPFlag(): void {
		if (this.apiResponse.insuranceType == this.pTypeArr[1]) {
			this.isTpPolicyFlag = true;
		}
	}

	public setNomineeFlag(): void {
		if (this.apiResponse.personalInfo.policyholderType == this.oTypeArr[0]) {
			this.isNomineeFlag = true;
		}
	}

	ngOnDestroy(): void {
		this.generatedProposalSub.unsubscribe();
	}

	public changeCheck(event): void {
		this.disabledAgreement = !event.checked;
	}

	public onBuyNow(): void {
		setTimeout(() => {
			this.triggerPaymentButton();
		}, 400);
	}


	public triggerPaymentButton(): void {
		setTimeout(() => {
			if (this.paymentProcessingType != 'paymentform') {
				window.open(this.paymentLink, "_self");
			}
		}, 1000);
		this.isLoading = false;
	}

}
