export const leadSource = {
	application: "Application",
	website: "Website"
}
export const ncbPerArr = ["0", "20", "25", "35", "45", "50"];
export const policyTypeArr = ["Comprehensive", "SAOD", "TP"];
export const ownerTypeArr = ["Individual", "Corporate"];
export const relationShip = [
	"Brother",
	"Daughter",
	"Father",
	"Husband",
	"Mother",
	"Son",
	"Sister",
	"Wife"
];

export const productTypeObj = {
	car: 'car',
	bike: 'bike',
	cv: 'commercial'
};

export const productType = [
	{
		key: "car",
		logo: "car",
		displayText: "Private Car",
    productCode: "1801"
	},
	{
		key: "bike",
		logo: "motorbike",
		displayText: "Two Wheeler",
    productCode: "1802"
	},
	{
		key: "health",
		logo: "healthcare",
		displayText: "Health",
    productCode: "1804"
	},
	{
		key: "commercial",
		logo: "commercial",
		displayText: "Commercial Vehicle",
    productCode: "1803"
	}
]

export const insuranceDuration = {
	car: 3,
	bike: 5,
	commercial: 1
}
export const purchaseType = {
	old: "renew",
	new: "new"
}

export const policyHolderGenderArr = ["Male", "Female", "Others"];
export const policyHolderMaritalStatusArr = ["Single", "Married", "Widow", "Divorced"];
export const voluntaryDiscountArr = {
	"car": [0, 2500, 5000, 7500, 15000],
	"bike": [0, 500, 750, 1000, 1500, 3000]
}
export const leadStatus = {
	junkLead: "Junk Lead",
	leadCreated: "Lead Created",
	quoteInfoUpdated: "Quotation Info Updated",
	quoteGenerated: "Quotation Generated",
	quoteLinkSent: "Quotation Link Sent",
	quoteSelected: "Quotation Selected",
	proposalInfoUpdated: "Proposal Info Updated",
	proposalGenerated: "Proposal Generated",
	proposalLinkSent: "Proposal Link Sent",
	proposalExpired: "Payment Link Expired",
	quoteExpired: "Quotation Expired",
  kycPending: "Kyc Pending",
  paymentReceivedKycPending: "Payment Received Kyc Pending",
  inspectionPending: "Inspection Pending",
	closed: "Closed",
}

export const paymentStatus = {
	pending: "Pending",
	completed: "Completed"
}

export const addonsConfig = {
	"car": [
		"zeroDepreciation",
		"engineProtection",
		"returnToInvoice",
		"tyreProtection"
	],
	"bike": [
		"zeroDepreciation",
		"roadsideAssistance",
		"consumables",
		"returnToInvoice"
	],
	"commercial": []
}

export const reviewSection = {
	motorProfile: {
		title: "Motor Profile",
		section: "motorProfile"
	},
	previousPolicy: {
		title: "Previous Policy",
		section: "previousPolicy"
	},
	tpPolicy: {
		title: "Third Party Policy",
		section: "tpPolicy"
	},
	ncb: {
		title: "No Claim Bonus",
		section: "ncb"
	},
	addons: {
		title: "Addons & Accessories",
		section: "addons"
	},
	personal: {
		title: "Policy Holder",
		section: "personal"
	},
	addresses: {
		title: "Addresses",
		section: "addresses"
	},
	nominee: {
		title: "Nominee",
		section: "nominee"
	},
	extra: {
		title: "Additional Info",
		section: "extra"
	}
}

export const healthInsuranceType = ['Individual', 'Family'];

export const SAODCUTOFDATE = "2018-09-01"

export const AAIMOBJ = [
	{
		name: "AAUI",
		displayName: "Automobile Association of Upper India"
	}, {
		name: "AASI",
		displayName: "Automobile Association of Southern India"
	}, {
		name: "AAEI",
		displayName: "Automobile Association of Eastern India"
	}, {
		name: "WIAA",
		displayName: "Western India Automobile Association"
	}, {
		name: "UPAA",
		displayName: "U.P. Automobile Association"
	}
]

export const RISKSTARTDATEONBREAKIN = {
	"tata-aig": 1,
	"godigit": 2,
	"hdfc-ergo": 2,
	"bajaj-general": 2,
	"kotak-general": 2,
	"reliance-general": 2
}

export const PaymentGatewayType = {
  form: "Form",
  link: "link"
}

export const KYCSTATUS = {
  complete: "DONE",
  inProgress: "IN_PROGRESS",
  failed: "FAILED",
}
