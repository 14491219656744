<app-quote-plan-details *ngIf="quoteList.length" [quoteResponseCount]="quoteList.length"></app-quote-plan-details>
<div class="card-deck row">
    <div *ngFor="let quote of quoteList" class="col-xs-12 col-md-4">
        <div class="card text-center">
            <!--Card image-->
            <div class="view overlay p-3 m-auto">
                <img class="card-img-top" src="/assets/images/list-quote-images/{{quote.providerName}}.png"
                    alt="{{quote.providerName}}">
                <!-- <a href="#!">
                    <div class="mask rgba-white-slight"></div>
                </a> -->
            </div>
            <!--Card content-->
            <div class="card-body">
                <!--Title-->
                <h4 class="card-title text-center">
                    <span class="text-heading lt-spacing">Cover Value(IDV):</span><br />
                    <strong>{{
                        (quote.vehicleIDV && quote.vehicleIDV.defaultValue ? quote.vehicleIDV.defaultValue : 0)
                        | currency: "INR":"symbol"
                        }}</strong>
                </h4>
                <!-- Button -->
                <div class="card-btn col-12">
                    <button type="button" class="btn text-center btn-selected" (click)="selectPlan(quote);">
                        <span class="text--sm">Purchase Now</span><br />
                        <span class="text--bg">{{ quote.netPremium | currency: "INR":"symbol" }}</span>
                    </button>
                </div>

                <div class=" p-2">
                    <a class="text-center cursor-pointer" (click)="showNcbModal(quote)"> Premium Breakup </a>
                </div>
            </div>

        </div>
    </div>

</div>

<ng-container #vc></ng-container>

<ng-template #modal>
    <app-real-modal id="ncbModal" class="hhidden">
        <!-- [quote]="premiumBreakUp" -->
        <app-premium-breakup [quote]="quote"></app-premium-breakup>
        <button (click)="closeDialog()" class="close-dialog">x</button>
    </app-real-modal>
</ng-template>