import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { of, Subscription, throwError } from "rxjs";
import { retryWhen, delay, take, mergeMap } from "rxjs/operators";

import { CrmFlowService } from '../../services/crm-flow.service';
import { InsuranceService } from 'src/app/features/services/insurance.service';
import { HelperService } from '../../services/helper.service';
import { LoggerService } from "src/app/core/logger/logger.service";
import { ApiService } from "src/app/core/services/api.service";
import { LeadResponse } from '../../interfaces/leadResponse';
import { ErrorMessage } from "src/app/features/interfaces/error-message";
import { policyTypeArr } from '../../policy-config';
import { BreakInServiceService } from '../../services/break-in-service.service';

@Component({
	selector: 'app-compare-quotes',
	templateUrl: './compare-quotes.component.html',
	styleUrls: ['./compare-quotes.component.scss']
})
export class CompareQuotesComponent implements OnInit {
	public crmId: string;
	public steps: string = "Quotation";
	public isQueryString: boolean = false;
	public isProposalFromLoad: boolean = true;
	public crmObj: any;
	public quoteResponseCount: number;

	public insuranceSub = new Subscription();
	public errorMessage: ErrorMessage = {
		message: null,
		link: null,
		buttonText: null,
		type: null
	};
	public quoteList: any = [];
	public quoteListFiltered: any = [];
	public minIDV: any = 0;
	public maxIDV: any = 0;
	// public defaultIDV: any;
	public sentLinkSuccessMessage: string;
	public enableLinkSent: boolean = false;
	public isLoading: boolean = true;
	public isIdvValueChanged: boolean = false;
	public quotationLink: any;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private crmFlow: CrmFlowService,
		private insuranceService: InsuranceService,
		private helperService: HelperService,
		private apiService: ApiService,
		private logger: LoggerService,
		private breakInService: BreakInServiceService
	) { }

	ngOnInit(): void {
		this.checkParam();
		this.getLeadDetailsFromCrm();
	}

	public checkParam(): void {
		this.route.queryParams.subscribe((params) => {
			this.crmId = params["quoteId"];
			// this.isQueryString = (params["refId"]) ? true : false;
		});
	}

	public getLeadId(): void {
		this.crmId = this.insuranceService.fetchSessionValue("leadReqId");
		if (!this.crmId) {
			this.router.navigate(['/']);
		}
	}

	public getLeadDetailsFromCrm(): void {
		if (!this.isQueryString) {
			this.getLeadId();
		}
		this.crmFlow.getLeadDetails(this.crmId).subscribe((leadResponse: LeadResponse) => {
      this.crmObj = leadResponse[0];
			this.fetchQuoteList();
		},
			(error) => {

			})
	}

	public fetchQuoteList(): void {
		this.quoteListFiltered = [];
		this.quoteList = [];
		const requestPayload = this.helperService.quickQuoteApiPayload(this.crmObj);
		const apiEndPoint = this.helperService.compareQuoteApiEndPoint(this.crmObj.commonFields.productType);
		delete requestPayload.motorProfile.vehicleId;
		for (const endPoint of apiEndPoint) {
			this.calculatePremium(endPoint, requestPayload);
		}

	}

	public calculatePremium(apiServiceUrl: string, requestPayload: object): void {
		this.apiService
			.postAPICall(apiServiceUrl, requestPayload)
			.pipe(
				retryWhen(
					err => {
						return err.pipe(
							mergeMap((response) => {
								if (response.status === 504) {
									return of(response).pipe(
										delay(500),
										take(3)
									);
								}
								return throwError({ error: response });
							}),
						)
					}
				)
			)
			.subscribe(
				(data) => {
					const findItem = this.quoteList.find(d => d.providerName === data.providerName);
					if (!findItem) {
						this.quoteResponseCount++;
						this.quoteList.push(data);
						this.verifyQuoteList();
					}

				},
				(error) => {
					this.isLoading = false;
				}
			);
	}

	verifyQuoteList() {
		this.filterQuoteList();
	}

	filterQuoteList() {
		this.minIDV = this.quoteList[0].vehicleIDV && this.quoteList[0].vehicleIDV.minValue ? this.quoteList[0].vehicleIDV.minValue : undefined;
		this.maxIDV = this.quoteList[0].vehicleIDV && this.quoteList[0].vehicleIDV.maxValue ? this.quoteList[0].vehicleIDV.maxValue : undefined;
		this.quoteListFiltered = [];
		this.quoteListFiltered = this.quoteList.filter((quote) => {
			if (parseFloat(this.minIDV) > parseFloat(quote.vehicleIDV ? quote.vehicleIDV.minValue : undefined)) {
				this.minIDV = quote.vehicleIDV.minValue;
			}
			if (parseFloat(this.maxIDV) < parseFloat(quote.vehicleIDV ? quote.vehicleIDV.maxValue : undefined)) {
				this.maxIDV = quote.vehicleIDV.maxValue;
			}
			return true;
		});
		this.minIDV = parseInt(this.minIDV);
		this.maxIDV = parseInt(this.maxIDV);
		this.isLoading = false;
	}

	ngOnDestroy(): void {
		this.insuranceSub.unsubscribe();
	}

	public onQuoteSelected(event): void {
    const obj = this.crmFlow.updateQuotationDataToLead(this.crmObj, event);
		this.isProposalFromLoad = true;
		setTimeout(() => {
			this.callUpdateLeadApi(obj);
		}, 400);
	}

	public onOwnedByUpdated(event): void {
		this.isProposalFromLoad = false;
		this.crmObj["policyHolderInfo"]["policyholderType"] = event
		setTimeout(() => {
			this.callUpdateLeadApi(this.crmObj);
		}, 400);
	}

	public onUpdateIdv(event): void {
		this.isProposalFromLoad = false;
		this.crmObj["newPolicyInfo"]["idv"] = event.idv;
		this.crmObj["newPolicyInfo"]["idvType"] = event.idvType;
		setTimeout(() => {
			this.insuranceService.storeValue("idvValue", event)
			this.callUpdateLeadApi(this.crmObj);
		}, 400);
	}

	public onUpdateNewPolicy(event): void {
		this.isProposalFromLoad = false;
		this.crmObj["newPolicyInfo"]["newPolicyType"] = event
		const isInspectionFlag = this.breakInService.setInspectionFlag(this.crmObj)
		this.crmObj["newPolicyInfo"]['isInspection'] = isInspectionFlag;
		if (event == policyTypeArr[2]) {
			this.crmObj["ncbInfo"]["isNcb"] = false;
			this.crmObj["ncbInfo"]["currentNcb"] = 0;
			this.crmObj["ncbInfo"]["newNcb"] = 0;
			this.crmObj["extra"]["addons"]["zeroDepreciation"] = false;
			this.crmObj["extra"]["addons"]["roadsideAssistance"] = false;
			this.crmObj["extra"]["addons"]["engineProtection"] = false;
			this.crmObj["extra"]["addons"]["ncbProtector"] = false;
			this.crmObj["extra"]["addons"]["keyReplacement"] = false;
			this.crmObj["extra"]["addons"]["consumables"] = false;
			this.crmObj["extra"]["addons"]["dailyAllowance"] = false;
			this.crmObj["extra"]["addons"]["returnToInvoice"] = false;
			this.crmObj["extra"]["addons"]["tyreProtection"] = false;
			this.crmObj["extra"]["addons"]["lossOfPersonalBelongins"] = false;
			this.crmObj["extra"]["addons"]["rimProtection"] = false;
			this.crmObj["extra"]["addons"]["electricalAssecories"] = null;
			this.crmObj["extra"]["addons"]["nonElectricalAssecories"] = null;
			this.crmObj["extra"]["addons"]["aaieDiscount"] = false;
			this.crmObj["extra"]["addons"]["araiApproval"] = false;
			this.crmObj["extra"]["addons"]["voluntaryDiscount"] = null;
		}
		setTimeout(() => {
			this.callUpdateLeadApi(this.crmObj);
		}, 400);
	}

	public onCrmUpdated(event): void {
		if (event) {
			window.location.reload();
		}
	}

	public callUpdateLeadApi(leadPayload): void {
		this.isLoading = true;
    leadPayload["modifiedOn"] = this.crmFlow.getCurrentTimestamp();
		this.crmFlow.updateApi(this.crmId, leadPayload).subscribe(
			(leadResponse: LeadResponse) => {
				setTimeout(() => {
					if (this.isProposalFromLoad) {
						this.router.navigate(['/feature/personal-info']);
					} else {
						this.isProposalFromLoad = false;
						window.location.reload();
					}
				}, 500);
			},
			(error) => {
				this.logger.info(error);
			}
		);
	}
}
