import { Component, Input, EventEmitter, Output, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, of } from "rxjs";
import {
	FormGroup,
	FormControl,
	Validators,
	FormBuilder,
} from "@angular/forms";

import { LoggerService } from 'src/app/core/logger/logger.service';

@Component({
  selector: 'app-registration-details',
  templateUrl: './registration-details.component.html',
  styleUrls: ['./registration-details.component.scss']
})
export class RegistrationDetailsComponent implements OnInit {
	@Input() motorProfile: any;
	@Output() nextButtonClicked: EventEmitter<any> = new EventEmitter<any>();
	registrationDetailsFormGroup: FormGroup;
	public isSubmitted: boolean = false;
	public alphabetHash: string;
	public fourDigitNo: string;
	@Input()
	searching = false;
	searchFailed = false;
	hideSearchingWhenUnsubscribed = new Observable(() => () =>
		(this.searching = false)
	);

	constructor(
		private _formBuilder: FormBuilder,
		private logger: LoggerService
	) { }

	ngOnInit(): void {
		this.processRegistartionNo();
		this.registrationDetailsFormGroup = this.createMotorFormGroup();
	}

	public processRegistartionNo(): void {
		const regArr = this.motorProfile.registrationNo.split("-");
		this.alphabetHash = regArr[2]
		this.fourDigitNo = regArr[3];
	}

	public createMotorFormGroup() {
		if(this.motorProfile.isNew){
			return null;
		}else{
			return this._formBuilder.group({
				rtoLoc: [this.motorProfile.rtoLoc, Validators.required],
				rtoTwoDigit: [this.alphabetHash, Validators.required],
				rtoNumber: [this.fourDigitNo, Validators.required],
			});
		}
	}

	get form() {
		return this.registrationDetailsFormGroup.controls;
	}

	public onSubmit(): void {
		this.isSubmitted = true;
		if (this.registrationDetailsFormGroup.invalid) {
			return;
		}
		this.nextButtonClicked.emit(this.registrationDetailsFormGroup.value);
	}
}

