import { Injectable } from '@angular/core';
import{encryptStorage} from 'src/app/core/services/encryptStorage';

@Injectable({
	providedIn: 'root'
})

export class InsuranceService {

	constructor() { }

	_filterMakeData(arr: any, value: any): any {
		const filterValue = value.toLowerCase();
		return arr.filter((item: { itemName: string }) => item.itemName.toLowerCase().includes(filterValue));
	}

	_filterModelData(arr: any, value: any): any {
		const filterValue = value.toLowerCase();
		return arr.filter((item: { modelName: string }) => item.modelName.toLowerCase().includes(filterValue));
	}

	_filterEngineData(arr: any, value: any): any {
		const filterValue = value.toLowerCase();
		return arr.filter((item: { fuelType: string }) => item.fuelType.toLowerCase().includes(filterValue));
	}

	_filterVariantData(arr: any, value: any): any {
		const filterValue = value.toLowerCase();
		return arr.filter((item: { variantName: string }) => item.variantName.toLowerCase().includes(filterValue));
	}

	_filterRto(opt: any, value: string): string[] {
		const filterValue = value.toLowerCase();
		return opt.filter((item: { Name: string }) => item.Name.toLowerCase().includes(filterValue));
	};

	public storeValue(key: any, value: any) :void {
		sessionStorage.setItem(key, value);
	}

	public fetchSessionValue(key) {
		return sessionStorage.getItem(key);
	}

	public clearValue(value: string) :void {
		sessionStorage.removeItem(value);
	}

	public clearAll() :void {
		encryptStorage.removeItem('loggedUser');
		sessionStorage.clear();
	}

	public logoutUser(): void {
		this.clearAll();
		window.location.href = "/posp/login";
	}

}
