<app-loader *ngIf="isLoading"></app-loader>
<div class="container container-real-insurance">
	<div class="row" *ngIf="crmObj && crmObj.commonFields.productType != 'health'">
		<div class="col-12 col-md-12 col-xs-12 mb-2" *ngIf="crmObj">
			<app-quote-summary [minIDV]="minIDV" [maxIDV]="maxIDV" [crmObj]="crmObj" [quoteResponseCount]="quoteResponseCount"
				(newPolicyChanged)="onUpdateNewPolicy($event)" (idvUpdated)="onUpdateIdv($event)" (ownedByChange)="onOwnedByUpdated($event)"
				></app-quote-summary>
		</div>
		<!-- <app-new-proposal [crmObj]="crmObj" [steps]="steps"></app-new-proposal> -->
		<div class="col-12 col-md-4 col-lg-3 mb-3" *ngIf="crmObj">
			<app-quote-filter-responsive-sidebar (crmUpdated)="onCrmUpdated($event)" [crmObj]="crmObj"></app-quote-filter-responsive-sidebar>
		</div>
		<div class="col-12 col-md-8 col-lg-9">
			<app-quote-list [quoteList]="quoteList" (QuoteSelected)="onQuoteSelected($event)"></app-quote-list>
		</div>
	</div>
  <div class="row" *ngIf="crmObj && crmObj.commonFields.productType == 'health'">

  </div>
</div>
