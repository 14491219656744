import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of, Subscription, throwError } from "rxjs";
import { LoggerService } from 'src/app/core/logger/logger.service';
import { CrmFlowService } from 'src/app/features/services/crm-flow.service';
import { leadStatus, paymentStatus, KYCSTATUS } from 'src/app/features/policy-config';
import { ConfirmationQuerystring } from 'src/app/features/interfaces/confirmation-querystring';
import { InsuranceService } from 'src/app/features/services/insurance.service';
import { LeadResponse } from 'src/app/features/interfaces/leadResponse';
import { HelperService } from 'src/app/features/services/helper.service';
import { ApiService } from 'src/app/core/services/api.service';
import { OrderResponseModel } from "src/app/features/interfaces/order-response-model"
import { Location } from '@angular/common';
import { retryWhen, delay, take, mergeMap } from "rxjs/operators";
import { encryptStorage } from '../../../../core/services/encryptStorage';

@Component({
	selector: 'app-payment-confirmation',
	templateUrl: './payment-confirmation.component.html',
	styleUrls: ['./payment-confirmation.component.scss']
})

export class PaymentConfirmationComponent implements OnInit {
	public params: ConfirmationQuerystring = {
		systemId: null,
		providerName: null,
		status: null,
		proposalNo: null,
		policyNo: null,
		transactionNo: null,
		transactionDate: null,
		transactionAmount: null,
		paymentGateway: null,
		policyApiFlag: null,
		pospInfoApiFlag: null,
		transactionApiFlag: null,
		others: null
	};

	public orderResp: OrderResponseModel = {
		message: null,
		error: null
	}

	public isLoading: boolean = true;
	public crmId: string;
	public crmObj: any;
	public leadStatusObj: any = leadStatus;
	public paymentStatusObj: any = paymentStatus;
  public kycStatus: any = KYCSTATUS;
	public generatedCrmSub = new Subscription();
	public isPolicyGenerated: boolean = false;

	constructor(
		private route: ActivatedRoute,
		private apiService: ApiService,
		private helperService: HelperService,
		private logger: LoggerService,
		private crmFlow: CrmFlowService,
		private insuranceService: InsuranceService,
		private router: Router,
		private location: Location
	) { }

	ngOnInit(): void {
		this.getQueryParameter();
	}

	public getQueryParameter(): void {
		this.route.queryParams.subscribe((params) => {
			const paramArr = params.Output.split('|');
      this.params.systemId = paramArr[0]
			this.params.providerName = paramArr[1];
			this.params.status = parseInt(paramArr[2]);
			this.params.proposalNo = paramArr[3];
			this.params.policyNo = paramArr[4];
			this.params.transactionNo = paramArr[5];
			this.params.transactionDate = paramArr[6];
			this.params.transactionAmount = paramArr[7];
			this.params.paymentGateway = paramArr[8];
			this.params.policyApiFlag = parseInt(paramArr[9]);
			this.params.pospInfoApiFlag = parseInt(paramArr[10]);
			this.params.transactionApiFlag = paramArr[11] ? parseInt(paramArr[11]) : 0;
			this.params.others = paramArr[11] ? paramArr[12] : 'NA';
			this.getLeadId()
		});
	}

	public getLeadId(): void {
		const qStringleadId = this.params.systemId.replace(/\s/g, "+");
		this.params['systemId'] = qStringleadId;
		if (this.params.systemId != "NA") {
			this.crmId = this.params.systemId;
		} else {
			this.crmId = this.insuranceService.fetchSessionValue("leadReqId");
			this.params["systemId"] = this.crmId;
			this.changeBrowserUrl()
		}
		if (!this.crmId) {
			this.router.navigate(['/']);
		}
		setTimeout(() => {
			this.getLeadDetailsFromCrm();
		}, 800);
	}

	public changeBrowserUrl(): void {
		let result = "";
		for (const key in this.params) {
			result += this.params[key] + "|";
		}

		this.location.replaceState('/confirmation?Output=' + result);
	}

	public getLeadDetailsFromCrm(): void {
		this.generatedCrmSub = this.crmFlow.getLeadDetails(this.crmId).subscribe((leadResponse: LeadResponse) => {
			this.crmObj = leadResponse[0];
      this.params["kycStatus"] = (this.crmObj.kyc.isKycCompleted)? KYCSTATUS.complete:KYCSTATUS.inProgress
			this.validateProposalNoAndGetInput();
		},
			(error) => {

			})
	}

	public validateProposalNoAndGetInput(): void {
    if (this.params.status) {
      if (this.crmObj.commonFields.leadStatus != leadStatus.closed) {
				this.getPolicyNo();
			} else {
				this.isLoading = false;
				this.isPolicyGenerated = true;
			}
		} else {
			const error = {}
			this.generateQuoteFailed(error);
		}
	}

	public getPolicyNo(): void {
    if (this.params.policyApiFlag) {
      this.generatePolicyNo();
		} else if (this.params.transactionApiFlag) {
      this.searchPolicyByDetails();
		} else if (Object.values(this.crmObj.newPolicyInfo).indexOf(this.params.proposalNo) == -1) {
      this.crmObj["newPolicyInfo"]["proposalNo"] = this.params.proposalNo;
			this.crmObj["newPolicyInfo"]["policyNo"] = this.params.policyNo;
			this.crmObj["commonFields"]["leadStatus"] = leadStatus.closed;
			this.callUpdateLeadApi(this.crmObj);
		} else {
      this.fetchDetailsByProposalNo();
		}
		this.clearSessionObj();
	}

	public searchPolicyByDetails(): void {
		const apiEndPoint = this.helperService.searchPolicyApiEndPoint(this.params.providerName, this.crmObj.commonFields.productType)
		const reqSignature = this.helperService.payloadForSearchPolicy(this.crmObj.newPolicyInfo.policyNo);
		this.apiService
			.postAPICall(`${apiEndPoint}`, reqSignature)
			.subscribe({
				next: (result) => {
          this.generateSearchPolicyResponse(result);
				},
				error: (error) => {
					this.generateQuoteFailed(error);
				},
				complete: () => {
					this.isLoading = false;
					this.logger.info("complete");
				},
			});
	}

	public generatePolicyNo(): void {
		const apiEndPoint = this.helperService.generatePolicyApiEndPoint(this.params.providerName, "car")
		const reqSignature = this.generateRequestBody();
		this.apiService
			.postAPICall(`${apiEndPoint}`, reqSignature)
			.pipe(
				retryWhen(
					err => {
						return err.pipe(
							mergeMap((response) => {
								if (response.status === 504) {
									return of(response).pipe(
										delay(500),
										take(3)
									);
								}
								return throwError({ error: response });
							}),
						)
					}
				)
			)
			.subscribe({
				next: (result) => {
					this.generateQuoteResponse(result, reqSignature);
				},
				error: (error) => {
					this.generateQuoteFailed(error);
				},
				complete: () => {
					this.isLoading = false;
					this.logger.info("complete");
				},
			});
	}

	public generateSearchPolicyResponse(searchResp): void {
    if(searchResp.kycStatus == this.kycStatus.complete){
      this.crmObj["newPolicyInfo"]["proposalNo"] = searchResp.proposalNo;
      this.crmObj["newPolicyInfo"]["policyNo"] = searchResp.policyNo;
      this.crmObj["paymentInfo"]["paymentStatus"] = paymentStatus.completed;
      this.crmObj["paymentInfo"]["paymentResponse"] = JSON.stringify(this.params);
      this.crmObj["commonFields"]["leadStatus"] = leadStatus.closed;
      this.crmObj["paymentInfo"]["paymentStatus"] = paymentStatus.completed;
      this.crmObj["kyc"]["isKycCompleted"] = true;
    }else {
      this.params["status"] = 9
      this.crmObj["newPolicyInfo"]["proposalNo"] = searchResp.proposalNo;
      this.crmObj["newPolicyInfo"]["policyNo"] = searchResp.policyNo;
      this.crmObj["paymentInfo"]["paymentStatus"] = paymentStatus.completed;
      this.crmObj["paymentInfo"]["paymentResponse"] = JSON.stringify(this.params);
      this.crmObj["commonFields"]["leadStatus"] = leadStatus.paymentReceivedKycPending;
      this.crmObj["paymentInfo"]["paymentStatus"] = paymentStatus.completed;
      this.crmObj["kyc"]["kycUrl"] = searchResp.kycUrl;
    }
		this.callUpdateLeadApi(this.crmObj);
	}

  public validateKycStatus(): void {
    window.location.reload();
  }

	public generateQuoteResponse(response, inputObj): void {
		this.crmObj["commonFields"]["leadStatus"] = leadStatus.closed;
		this.crmObj["newPolicyInfo"]["proposalNo"] = response.proposalId;
		this.crmObj["newPolicyInfo"]["policyNo"] = response.policyNo;
		this.crmObj["paymentInfo"]["paymentStatus"] = paymentStatus.completed;
		this.crmObj["extra"]["reqPayload"] = inputObj;
		this.crmObj["extra"]["respPayload"] = response;
		if (response.premiumBreakup) {
			this.crmObj["paymentInfo"]["odPremium"] = response?.premiumBreakup.ownDamageCover;
			this.crmObj["paymentInfo"]["tpPremium"] = response?.premiumBreakup.thirdPartyCover;
			this.crmObj["paymentInfo"]["paPremium"] = response?.premiumBreakup.paCover;
			this.crmObj["paymentInfo"]["addonsPremium"] = "0.00";
			this.crmObj["paymentInfo"]["netPremium"] = response?.netPremium;
			this.crmObj["paymentInfo"]["taxAmount"] = response?.totalTax;
			this.crmObj["paymentInfo"]["grossPremium"] = response?.grossPremium;
		}
		this.crmObj["paymentInfo"]["paymentResponse"] = JSON.stringify(this.params);
		this.callUpdateLeadApi(this.crmObj);
	}

	public callUpdateLeadApi(leadPayload): void {
    leadPayload["modifiedOn"] = this.crmFlow.getCurrentTimestamp();
		this.crmFlow.updateApi(this.crmId, leadPayload).subscribe(
			{
				next: (leadResponse: LeadResponse) => {
          if(leadResponse.commonFields.leadStatus == leadStatus.closed){
            this.saveOrder();
          }
					this.isPolicyGenerated = true;
				},
				error: (error) => {
					this.logger.info(error);
					this.generateQuoteFailed(error);
				},
				complete: () => {
					this.isLoading = false;
					this.logger.info("complete");
				},
			}
		);
	}

	public saveOrder(): void {
		const orderPayload = this.crmFlow.createOrderPayload(this.crmObj);
    this.apiService.createOrder(orderPayload).subscribe(
			(resp: OrderResponseModel) => {
        this.logger.info(resp)
				// this.processApiSuccessResponse(leadResponse);
			},
			(error) => {
        this.logger.info(error)
				// this.processApiErrorResponse(error);
			}
		);
	}

	public generateQuoteFailed(error): void {
		this.logger.info(error)
		this.params.status = 0;
		this.isLoading = false
	}

	public generateRequestBody() {
		let payload = this.helperService.createQuoteApiPayload(this.crmObj);
    payload["proposalId"] = this.params.proposalNo;
		payload["paymentInfo"] = {
			"transactionRefNo": this.params.transactionNo,
			"transactionAmount": this.params.transactionAmount,
			"transactionDate": this.params.transactionDate.split(" ")[0]
		}
		return this.helperService.deepTrim(payload);
	}

	public downloadPolicy(): void {
	}

	public fetchDetailsByProposalNo(): void {
		this.generateSearchPolicyResponse(this.params);
	}

	public clearSessionObj(): void {
		this.insuranceService.clearValue("leadReqId");
		this.insuranceService.clearValue("productType");
		this.insuranceService.clearValue("isNewVehicle");
		this.insuranceService.clearValue("vehicleCC");
	}
}
