import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
	FormGroup,
	FormControl,
	Validators,
	FormBuilder,
} from '@angular/forms';
import { of, throwError } from 'rxjs';
import { retryWhen, mergeMap, delay, take } from 'rxjs/operators';
import { ownerTypeArr } from 'src/app/features/policy-config';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { ApiService } from 'src/app/core/services/api.service';
import { HelperService } from 'src/app/features/services/helper.service';

@Component({
	selector: 'app-kyc-details',
	templateUrl: './kyc-details.component.html',
	styleUrls: ['./kyc-details.component.scss'],
})
export class KycDetailsComponent implements OnInit {
	@Input() crmObj: any;
  @Input() kycApiFlag: any;
  @Output() nextButtonClicked: EventEmitter<any> = new EventEmitter<any>();
	kycFormGroup: FormGroup;
  public ownerTypeArr = ownerTypeArr;
  public isSubmitted: boolean = false;
  public kycUrl: string = "";
  public isButtonDisabled: boolean = true;
  public isKycUrlGenerated: boolean = false;
  public kycCheckboxLabel: string = "No, I don't have My KYC Completed with";
  public ownerDOBLimit = new Date();
  public isKycVerified:boolean = false;
  constructor(
		private _formBuilder: FormBuilder,
		private logger: LoggerService,
    private apiService: ApiService,
    private helperService: HelperService
	) { }

	ngOnInit(): void {
    this.kycFormGroup = this.createMotorFormGroup();
    this.isKycVerified = this.crmObj.kyc.isKycCompleted;
    this.setLabel(this.crmObj.kyc.isKycCompleted);
    this.setMinDob();
    this.setSubmitButtonVisibility()
    this.setFormValidity()
  }

  setFormValidity() : void {
    const panNo = this.kycFormGroup.get("panNo");
		const cin = this.kycFormGroup.get("cin");
    if(this.crmObj.policyHolderInfo.policyholderType == this.ownerTypeArr[0]){
      panNo.setValidators([Validators.required, Validators.pattern("^[A-Z]{5}[0-9]{4}[A-Z]$")]);
      cin.setValidators(null);
    }else{
      panNo.setValidators(null);
      cin.setValidators([Validators.required, Validators.pattern("^[A-Z]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{5}$")]);
    }
    panNo.updateValueAndValidity();
		cin.updateValueAndValidity();
  }

  public setSubmitButtonVisibility(): void {
    this.isButtonDisabled = !this.crmObj.kyc.isKycCompleted
  }

  public setLabel(isKycCompleted): void {
    if(isKycCompleted){
      this.kycCheckboxLabel = "yes, I have My KYC Completed with"
    }else{
      this.kycCheckboxLabel = "No, I don't have My KYC Completed with";
    }
  }

  public setMinDob(): void {
		this.ownerDOBLimit.setDate(this.ownerDOBLimit.getDate() - ((365 * 18) + 5));
	}

  onKycNotExist(e): void {
    this.kycFormGroup.patchValue({
      isKycCompleted: e.checked
    })
    this.setLabel(e.checked);
    this.isKycVerified = e.checked
    this.isButtonDisabled = !e.checked
  }


  public createMotorFormGroup() {
		return this._formBuilder.group({
      isKycCompleted:[this.crmObj.kyc.isKycCompleted, Validators.required],
      kycId: [this.crmObj.kyc.kycId],
      kycApiResponse: [this.crmObj.kyc.kycApiResponse],
      dob: [this.crmObj.policyHolderInfo.dob, Validators.required],
      panNo: [""],
      cin: [""],
      kycDocNumber:[this.crmObj.kyc.kycDocNumber],
      kycUrl:[this.crmObj.kyc.kycUrl],
		});
	}

  get form() {
		return this.kycFormGroup.controls;
	}

  generateKyc(): void {
    this.isSubmitted = true;
    this.logger.info(this.kycFormGroup)
		if (this.kycFormGroup.invalid) {
      this.isSubmitted = false;
			return;
		}
    const apiServiceUrl = this.helperService.generateVerifyKycUrl(this.crmObj.newPolicyInfo.insuranceProvider);
    const requestPayload = this.helperService.validateKycApiPayload(this.crmObj, this.kycFormGroup.value);
    this.apiService
			.postAPICall(apiServiceUrl, requestPayload)
			.pipe(
				retryWhen(
					err => {
						return err.pipe(
							mergeMap((response) => {
								if (response.status === 504) {
									return of(response).pipe(
										delay(500),
										take(3)
									);
								}
								return throwError({ error: response });
							}),
						)
					}
				)
			)
			.subscribe(
				(data) => {
          if(data.kycUrl !=""){
            this.isKycUrlGenerated = true;
            this.isButtonDisabled = false;
          }else{
            this.processKycResponse(data);
          }
          this.kycUrl = data.kycUrl;
				},
				(error) => {
					// this.isLoading = false;
				}
			);
  }

  processKycResponse(inputRes): void {
    this.logger.info(inputRes);
    inputRes["kycDocNumber"] = this.kycFormGroup.get("kycDocNumber").value
    this.logger.info(inputRes);
    this.nextButtonClicked.emit(inputRes);
    // this.kycFormGroup.patchValue({
    //   isKycCompleted: inputRes.isKycCompleted,
    //   kycDocNumber: (typeof inputRes["docNumber"] != "undefined")?inputRes.docNumber:"",
    //   kycUrl: inputRes.kycUrl,
    //   kycId:inputRes.docNumber,
    //   kycApiResponse: inputRes
    // })
    // if(inputRes["panNo"]) {
    //   this.kycFormGroup.patchValue({
    //     panNo: inputRes.panNo,
    //   })
    // }
    // if(inputRes["dob"]) {
    //   this.kycFormGroup.patchValue({
    //     dob: inputRes.dob,
    //   })
    // }
  }


  public onSubmit(): void {
		this.isSubmitted = true;
		if (this.kycFormGroup.invalid) {
			return;
		}
    this.nextButtonClicked.emit(this.kycFormGroup.value);
	}


}
