<footer class="footer">
    <div class="container">
        <div class="row pt-5 pb-4">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-2" ngClass.lt-sm="ms-4">
                        <h5 class="mb-3">GENERAL</h5>
                        <ul>
                            <li><a [routerLink]="['']" id="help-center">Articles</a></li>
                            <li><a [routerLink]="['']" id="press">Press</a></li>
                            <li><a [routerLink]="['']" id="about-us">About Us</a></li>
                            <li><a [routerLink]="['']" id="contact-us">Contact Us</a></li>
                            <li><a [routerLink]="['']" id="grievance-redressal">Grievance Redressal</a></li>
                        </ul>
                    </div>
                    <div class="col-md-3" ngClass.lt-sm="ms-4">
                        <h5 class="mb-3">USEFUL LINKS</h5>
                        <ul>
                            <li><a [routerLink]="['']" id="claim-assistance">Claim Assistance</a></li>
                            <li><a [routerLink]="['']" id="motor-insurance-claim">Motor Insurance Claim</a></li>
                            <li><a [routerLink]="['']" id="motor-insurance-faqs">Motor Insurance FAQs</a></li>
                            <li><a [routerLink]="['']" id="motor-insurance-calculator">Motor Insurance Calculator</a>
                            </li>
                            <li><a [routerLink]="['']" id="third-party-insurance">Third Party Insurance</a></li>
                        </ul>
                    </div>
                    <div class="col-md-3" ngClass.lt-sm="ms-4">
                        <h5 class="mb-3">HEALTH INSURANCE</h5>
                        <ul>
                            <li><a [routerLink]="['']" id="individual-health-insurance">Individual Health Insurance</a>
                            </li>
                            <li><a [routerLink]="['']" id="family-health-insurance">Family Health Insurance</a></li>
                            <li><a [routerLink]="['']" id="mediclaim-policy">Mediclaim Policy</a></li>
                            <li><a [routerLink]="['']" id="cashless-mediclaim-policy">Cashless Mediclaim Policy</a></li>
                            <li><a [routerLink]="['']" id="top-up-health-insurance">Top-up Health Insurance</a></li>
                        </ul>
                    </div>
                    <div class="col-md-4" ngClass.lt-sm="ms-4">
                        <h5 class="mb-3">CONNECT US</h5>
                        <ul>
                            <!--li><a href="#!">A:- 24, WallStreet, NY 200001</a></li-->
                            <li><a href="#!"><i class="fa fa-envelope"></i> realinsurancebrokerspvtltd@gmail.com</a>
                            </li>
                            <li><a href="#!"><i class="fa fa-phone"></i> +91 9431813129</a></li>
                        </ul>
                        <h5 class="mb-3">Point Of Sales Person</h5>
                        <ul>
                            <li>
                                <a href="https://associates.insurancekarlo.com/" target="_blank"
                                    class="btn btn-selected">POSP Access</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-4">
                <div class="footer-sub-text text-justify">Insurance is offered by <span class="text-white">Real Insurance
                        Brokers Private Limited</span> (CIN <span class="text-white">U67190BR2013PTC021710</span>),
                    Principal Place of Business: <span class="text-white">1st Floor, J.K.Complex, Near Petrol Pump,
                        Ramdayalu Nagar, Muzaffarpur - 842002, Bihar</span> ; Registered Office: <span
                        class="text-white">1st Floor, J.K.Complex, Near Petrol Pump, Ramdayalu Nagar, Muzaffarpur -
                        842002, Bihar</span>, IRDAI Broking License Code: <span class="text-white">IRDAI/DB
                        508/20</span>, Category: <span class="text-white">Direct Broker (General)</span>, valid till
                    <span class="text-white">23-12-2023</span>. Product information is solely based on the information
                    received from the insurers. For more details on risk factors, associated terms and conditions and
                    exclusions, please read the sales brochure carefully of respective insurer before concluding a sale.
                    The Point of Sales Person (POSP) is appointed by Real Insurance Brokers Private Limited as per the
                    Guidelines on Point of Sales Person issued by the IRDAI and amended from time to time.<br />
                <p class="text-center">
                    <a class="text-white me-2" href="#!"><i class="fa fa-facebook-square fa-2x"></i></a>
                    <a class="text-white me-2" href="#!"><i class="fa fa-twitter-square fa-2x"></i></a>
                    <a class="text-white me-2" href="#!"><i class="fa fa-youtube-square fa-2x"></i></a>
                </p>
            </div>
                <hr class="horizontal-line text-center">
                <p class="sub_footer__copyright mt-3 text-center">&copy; Copyright 2021
                    <a href="https://www.realinsurance.co.in/" target="_blank" class="text-decoration-none pl-5">
                        <span class="text-white">Real Insurance Brokers Private Limited.</span></a> All Rights Reserved.
                </p>
            </div>
        </div>
    </div>
</footer>