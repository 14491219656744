import { Component, OnInit, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PremiumBreakupComponent } from '../../compare-quotes/premium-breakup/premium-breakup.component';

@Component({
	selector: 'app-premium-details',
	templateUrl: './premium-details.component.html',
	styleUrls: ['./premium-details.component.scss']
})
export class PremiumDetailsComponent implements OnInit {
	@Input() premium: any;
	@Input() quote: any;
	@ViewChild('vc', { read: ViewContainerRef }) vc: ViewContainerRef;

	public premiumBreakup: any;
	// quote: any;
	constructor(private modalService: NgbModal) { }

	ngOnInit(): void {
		if (this.premium) {
			this.premiumBreakup = this.premium.premiumBreakup;
			delete this.premium.premiumBreakup
		}


	}

	public showPremiumBreakup(quote): void {
		// quote.productType = quote.commonFields.productType;
		// quote.vehicleIDV = { defaultValue: quote.newPolicyInfo.idv };
		// quote.premiumBreakup = quote.extra.addons;
		this.quote = quote;
		const modalRef = this.modalService.open(PremiumBreakupComponent, { size: 'lg' });
		modalRef.componentInstance.quote = quote;
	}

	closeDialog() {
		this.vc.clear()
	}

}
