import { Component, OnInit, Input } from '@angular/core';
import { productType } from "src/app/features/policy-config"

@Component({
	selector: 'app-quote-selection-summary',
	templateUrl: './quote-selection-summary.component.html',
	styleUrls: ['./quote-selection-summary.component.scss']
})
export class QuoteSelectionSummaryComponent implements OnInit {
	@Input() crmObj;
	public productTypeObj: Array<any> = productType;
	productTypeSelected: String;

	constructor() { }

	ngOnInit(): void {
		this.getProductTypeDisplayName();
	}

	public getProductTypeDisplayName(): void {
		let productObj = this.productTypeObj.filter((x) => x.key == this.crmObj.commonFields.productType);
		if(productObj.length > 0){
			this.productTypeSelected = productObj[0].displayText 
		}

	}

}