import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment'


@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
	@Output() public sidenavToggle = new EventEmitter();
	public b2cEndPoint:string = environment.b2cEndPoint

	constructor(
	) { }

	ngOnInit(): void {
	}

	public onToggleSidenav = () => {
		this.sidenavToggle.emit();
	}
}
