import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { LoggerService } from 'src/app/core/logger/logger.service';
import {ownerTypeArr} from "src/app/features/policy-config"

@Component({
  selector: 'app-owner-type-selection',
  templateUrl: './owner-type-selection.component.html',
  styleUrls: ['./owner-type-selection.component.scss']
})
export class OwnerTypeSelectionComponent implements OnInit {
  @Input() ownerType;
  @Output() onOwnerChange = new EventEmitter<string>()
  public ownerTypeList:Array<any> = ownerTypeArr;
  public selectedOwner:string;

  constructor(
    private logger:LoggerService
  ) {}

  ngOnInit(): void {
    this.selectedOwner = this.ownerType;
  }

  public onVehicleOwnerChange():void{
    this.onOwnerChange.emit(this.selectedOwner)
  }

}
