import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FeaturesRoutingModule } from "./features-routing.module";
import { CoreModule } from "../core/core.module";
import { CommonRealModule } from '../common/common-real.module';

import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';

import { MatNativeDateModule, MAT_DATE_LOCALE, DateAdapter } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';

import { MatSidenavModule } from '@angular/material/sidenav';
import { FlexLayoutModule } from '@angular/flex-layout';

import { InsuranceComponent } from './components/insurance/insurance.component';
import { InsuranceInfoComponent } from './components/insurance/insurance-info/insurance-info.component';
import { InsuranceTypeComponent } from './components/insurance/insurance-type/insurance-type.component';
import { CompareQuotesComponent } from './components/compare-quotes/compare-quotes.component';
import { QuoteListComponent } from './components/compare-quotes/quote-list/quote-list.component';
import { QuoteFilterSidebarComponent } from './components/compare-quotes/quote-filter-responsive-sidebar/quote-filter-sidebar/quote-filter-sidebar.component';
import { QuoteSummaryComponent } from './components/compare-quotes/quote-summary/quote-summary.component';
import { QuoteFilterResponsiveSidebarComponent } from './components/compare-quotes/quote-filter-responsive-sidebar/quote-filter-responsive-sidebar.component';
import { PaymentComponent } from './components/payment/payment.component';
import { ItemValueComponent } from './components/payment/item-value/item-value.component';
import { NewPolicySummaryComponent } from './components/payment/new-policy-summary/new-policy-summary.component';
import { PremiumDetailsComponent } from './components/payment/premium-details/premium-details.component';
import { PaymentFormComponent } from './components/payment/payment-form/payment-form.component';
import { InformationStepperComponent } from './components/information-stepper/information-stepper.component';
import { CommunicationInformationDetailsComponent } from './components/information-stepper/proposal-form/communication-information-details/communication-information-details.component';
import { CompanyInformationDetailsComponent } from './components/information-stepper/proposal-form/company-information-details/company-information-details.component';
import { MotorInformationDetailsComponent } from './components/information-stepper/proposal-form/motor-information-details/motor-information-details.component';
import { NomineeInformationDetailsComponent } from './components/information-stepper/proposal-form/nominee-information-details/nominee-information-details.component';
import { PolicyHolderDetailsComponent } from './components/information-stepper/proposal-form/policy-holder-details/policy-holder-details.component';
import { PreviousInsuranceDetailsComponent } from './components/information-stepper/proposal-form/previous-insurance-details/previous-insurance-details.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { QuotePlanDetailsComponent } from './components/compare-quotes/quote-list/quote-plan-details/quote-plan-details.component';
import { CategorySelectionDetailsComponent } from './components/compare-quotes/quote-summary/category-selection-details/category-selection-details.component';
import { StepperBoxHeaderComponent } from './components/information-stepper/stepper-box-header/stepper-box-header.component';
import { QuoteSelectionSummaryComponent } from './components/information-stepper/quote-selection-summary/quote-selection-summary.component';
import { PaymentConfirmationComponent } from './components/payment/payment-confirmation/payment-confirmation.component';
import { ProposalFormComponent } from './components/information-stepper/proposal-form/proposal-form.component';
import { PremiumBreakupComponent } from './components/compare-quotes/premium-breakup/premium-breakup.component';
import { OwnerTypeSelectionComponent } from './components/compare-quotes/quote-summary/owner-type-selection/owner-type-selection.component';
import { IdvSelectionComponent } from './components/compare-quotes/quote-summary/idv-selection/idv-selection.component';
import { NewPolicySelectionComponent } from './components/compare-quotes/quote-summary/new-policy-selection/new-policy-selection.component';
import { RegistrationDetailsComponent } from './components/information-stepper/proposal-form/registration-details/registration-details.component';
import { ThankYouComponent } from './components/thank-you/thank-you.component';
import { ProposalComponent } from './components/proposal/proposal.component';
import { NewProposalComponent } from './components/new-proposal/new-proposal.component';
import { KycDetailsComponent } from './components/information-stepper/proposal-form/kyc-details/kyc-details.component';
import { HealthInsuranceComponent } from "./components/insurance/insurance-info/health-insurance/health-insurance.component";
import { GenderSelectionComponent } from "./components/insurance/insurance-info/health-insurance/gender-selection/gender-selection.component";
import { InsuredSelectionComponent } from "./components/insurance/insurance-info/health-insurance/insured-selection/insured-selection.component";
import { MemberAgeComponent } from "./components/insurance/insurance-info/health-insurance/member-age/member-age.component";
import { LeadFormComponent } from "./components/insurance/insurance-info/health-insurance/lead-form/lead-form.component";
import { MotorInsuranceComponent } from './components/insurance/insurance-info/motor-insurance/motor-insurance.component';
import { KycConfirmationComponent } from './components/kyc-confirmation/kyc-confirmation.component';

@NgModule({
	declarations: [
		InsuranceComponent,
		InsuranceInfoComponent,
    InsuranceTypeComponent,
		CompareQuotesComponent,
		QuoteListComponent,
		QuoteFilterSidebarComponent,
		QuoteSummaryComponent,
		QuoteFilterResponsiveSidebarComponent,
		PaymentComponent,
		ItemValueComponent,
		NewPolicySummaryComponent,
		PremiumDetailsComponent,
		PaymentFormComponent,
		InformationStepperComponent,
		CommunicationInformationDetailsComponent,
		CompanyInformationDetailsComponent,
		MotorInformationDetailsComponent,
		NomineeInformationDetailsComponent,
		PolicyHolderDetailsComponent,
		PreviousInsuranceDetailsComponent,
		QuotePlanDetailsComponent,
		CategorySelectionDetailsComponent,
		StepperBoxHeaderComponent,
		QuoteSelectionSummaryComponent,
		PaymentConfirmationComponent,
		ProposalFormComponent,
		PremiumBreakupComponent,
		OwnerTypeSelectionComponent,
		IdvSelectionComponent,
		NewPolicySelectionComponent,
		RegistrationDetailsComponent,
		ThankYouComponent,
		ProposalComponent,
  NewProposalComponent,
  KycDetailsComponent,
  HealthInsuranceComponent,
  GenderSelectionComponent,
    InsuredSelectionComponent,
    MemberAgeComponent,
    LeadFormComponent,
    MotorInsuranceComponent,
    KycConfirmationComponent
  ],
	imports: [
		CommonModule,
		FeaturesRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		CoreModule,
		CommonRealModule,
		MatStepperModule,
		MatInputModule,
		MatButtonModule,
		MatFormFieldModule,
		MatCardModule,
		MatDialogModule,
		MatCheckboxModule,
		MatDatepickerModule,
		MatRadioModule,
		MatNativeDateModule,
		MatSlideToggleModule,
		MatButtonToggleModule,
		MatIconModule,
		MatSelectModule,
		MatDividerModule,
		MatExpansionModule,
		MatAutocompleteModule,
		MatInputModule,
		MatPaginatorModule,
		MatSidenavModule,
		FlexLayoutModule
	],
	exports: [],
	providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
	{
		provide: STEPPER_GLOBAL_OPTIONS,
		useValue: { displayDefaultIndicatorType: false }
	}],
	entryComponents: [],
})
export class FeaturesModule { }
