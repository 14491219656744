export const KycValidationApiFlag = {
	godigit: null,
	bajajgeneral: {
		isPOI: true,
		isPOA: true,
	},
	hdfcergo: {
		isPOI: true,
		isPOA: false,
	},
	reliancegeneral: {
		isPOI: true,
		isPOA: false,
	},
};

export const KycDocTypeList = [
	{
		docId: 'D01',
		docType: 'CUSTOMER_PHOTO',
		displayType: 'CUSTOMER PHOTO',
	},
	{
		docId: 'D02',
		docType: 'panNo',
		displayType: 'CKYC',
	},
	{
		docId: 'D03',
		docType: 'AADHAAR',
		displayType: 'AADHAAR',
	},
	{
		docId: 'D04',
		docType: 'DRIVING_LICENSE',
		displayType: 'DRIVING LICENSE',
	},
	{
		docId: 'D05',
		docType: 'VOTER_ID',
		displayType: 'VOTER ID',
	},
	{
		docId: 'D06',
		docType: 'PASSPORT',
		displayType: 'PASSPORT',
	},
	{
		docId: 'D07',
		docType: 'PAN',
		displayType: 'PAN',
	},
	{
		docId: 'D08',
		docType: 'GST',
		displayType: 'GST',
	},
	{
		docId: 'D09',
		docType: 'COI',
		displayType: 'COI',
	},
	{
		docId: 'D10',
		docType: 'RENT_AGREEMENT',
		displayType: 'RENT AGREEMENT',
	},
	{
		docId: 'D11',
		docType: 'JOB_CARD',
		displayType: 'JOB CARD',
	},
	{
		docId: 'D12',
		docType: 'NOTARTY_PROOF',
		displayType: 'NOTARTY PROOF',
	},
	{
		docId: 'D13',
		docType: 'UTILITY_BILL',
		displayType: 'UTILITY BILL',
	},
	{
		docId: 'D14',
		docType: 'RATION_CARD',
		displayType: 'RATION CARD',
	},
	{
		docId: 'D15',
		docType: 'BANK_PASSBOOK',
		displayType: 'BANK PASSBOOK',
	},
	{
		docId: 'D16',
		docType: 'POSTOFFICE_PASSBOOK',
		displayType: 'POSTOFFICE PASSBOOK',
	},
	{
		docId: 'D17',
		docType: 'ARMS_LICENSE',
		displayType: 'ARMS LICENSE',
	},
	{
		docId: 'D18',
		docType: 'ADDRESS_CARD',
		displayType: 'ADDRESS CARD',
	},
	{
		docId: 'D19',
		docType: 'KISSAN_PASSBOOK',
		displayType: 'KISSAN PASSBOOK',
	},
	{
		docId: 'D20',
		docType: 'CGHS_ECHS_CARD',
		displayType: 'CGHS ECHS CARD',
	},
	{
		docId: 'D21',
		docType: 'PHOTO_CREDIT_CARD',
		displayType: 'PHOTO CREDIT CARD',
	},
	{
		docId: 'D22',
		docType: 'IT_PROOF',
		displayType: 'IT PROOF',
	},
	{
		docId: 'D23',
		docType: 'PHOTO_PENSIONER_CARD',
		displayType: 'PHOTO PENSIONER CARD',
	},
	{
		docId: 'D24',
		docType: 'BANK_STATEMENT',
		displayType: 'BANK STATEMENT',
	},
	{
		docId: 'D25',
		docType: 'AADHAAR_IMAGE',
		displayType: 'AADHAAR IMAGE',
	},
	{
		docId: 'D26',
		docType: 'DRIVING_LICENSE_IMAGE',
		displayType: 'DRIVING LICENSE IMAGE',
	},
	{
		docId: 'D27',
		docType: 'VOTER_ID_IMAGE',
		displayType: 'VOTER ID IMAGE',
	},
	{
		docId: 'D28',
		docType: 'PASSPORT_IMAGE',
		displayType: 'PASSPORT IMAGE',
	},
	{
		docId: 'D99',
		docType: 'DUMMY',
		displayType: 'DUMMY',
	},
];
