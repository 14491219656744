import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { HealthInput } from 'src/app/features/interfaces/health-input';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-health-insurance',
	templateUrl: './health-insurance.component.html',
	styleUrls: ['./health-insurance.component.scss'],
	providers: [{
		provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
	}]
})
export class HealthInsuranceComponent implements OnInit {
	// public isLoading:boolean = true;
	public genderStepCompleted: boolean = false;
	public insurerStepCompleted: boolean = false;
	public ageStepCompleted: boolean = false;
	public leadStepCompleted: boolean = false;
	public isAllStepsCompleted: boolean = false;
	public lastStep: any;
	@ViewChild('stepper', { static: false }) stepper: MatStepper;
	selectionChanged: Subject<any> = new Subject<any>();
	@Output() crmLeadObj = new EventEmitter<Object>();

	public healthInsInput: HealthInput = {
		gender: null,
		insurerInfo: [],
		pincode: null,
		email: null,
		mobile: null,
		age: null,
    productType: "health"
	}

	constructor(
		private logger: LoggerService,
	) { }

	ngOnInit(): void {
		setTimeout(() => {
			this.selectionChanged.next(this.stepper);
		}, 1000);
	}

	genderSelectionStepNextClicked(e): void {
		debugger;
		this.healthInsInput["gender"] = e.gender;
		// setTimeout(() => {
		this.genderStepCompleted = true;
		this.goForward(this.stepper);
		// }, 500);

	}

	insuredStepNextClicked(e): void {
		this.healthInsInput["insurerInfo"] = e.insurerInfo;

		setTimeout(() => {
			this.insurerStepCompleted = true;
			this.goForward(this.stepper);
		}, 500);
	}

	ageStepNextClicked(e): void {
		this.ageStepCompleted = true;
		this.goForward(this.stepper);
	}

	leadStepNextClicked(e): void {
    this.healthInsInput["email"] = e.email;
    this.healthInsInput["mobile"] = e.mobile;
    this.healthInsInput["pincode"] = e.pincode;
    if (this.stepper.steps.length == this.stepper.selectedIndex + 1) {
			this.isAllStepsCompleted = true;
      this.crmLeadObj.emit(this.healthInsInput);
		}
		this.setLastStep();
	}

  public setLastStep(): void {
		this.lastStep = (this.stepper.steps.last);
	}

	public emitToBoxHeader(evt): void {
		this.selectionChanged.next(this.stepper);
	}

	goBack(stepper: MatStepper) {
		stepper.previous();
	}

	goForward(stepper: MatStepper) {
		stepper.next();
	}
}
