<form [formGroup]="commInfoForm" class="bg-white personal-info-form-section">
	<div class="card mt-3">
		<h3 class="card-header text-heading mb-0">Registered Address</h3>
		<div class="card-body">
			<div class="row pb-3">
				<div class="col-sm-12 col-lg-6">
					<mat-form-field class="w-100" appearance="outline">
						<input [readonly]="isReadOnly" matInput formControlName="addressLine1" placeholder="Enter Address Line 1 "
							autocomplete="new-addressLine1" />
						<mat-error *ngIf="
              commInfoForm['controls'].addressLine1.invalid &&
              commInfoForm['controls'].addressLine1.dirty
            ">
							Address line 1 is required
						</mat-error>
					</mat-form-field>
				</div>
				<div class="col-sm-12 col-lg-6">
					<mat-form-field class="w-100" appearance="outline">
						<input [readonly]="isReadOnly" matInput formControlName="addressLine2" placeholder="Enter Address Line 2"
							autocomplete="new-addressLine2" />
						<mat-error *ngIf="
              commInfoForm['controls'].addressLine2.invalid &&
              commInfoForm['controls'].addressLine2.dirty
            ">
							Address line 2 is required
						</mat-error>
					</mat-form-field>
				</div>
			</div>
			<div class="row pb-3">
				<div class="col-sm-12 col-lg-4">
					<mat-form-field class="w-100" appearance="outline">
						<input  (input)="callPincodeAPI('postalCode')" matInput formControlName="postalCode" type="text"
							maxlength="6" tabindex="5" placeholder="Enter Pincode" autocomplete="new-pinCode" />
						<mat-error *ngIf="
              commInfoForm['controls'].postalCode.invalid &&
              commInfoForm['controls'].postalCode.dirty
            ">
							Valid 6 digit Pincode is required
						</mat-error>
					</mat-form-field>
				</div>
				<div class="col-sm-12 col-lg-4">
					<mat-form-field class="w-100" appearance="outline">
						<mat-label *ngIf="
              commInfoForm['controls'].city.invalid &&
              !commInfoForm['controls'].city.touched
            ">Select City
						</mat-label>
						<mat-error *ngIf="
              commInfoForm['controls'].city.invalid &&
              commInfoForm['controls'].city.touched
            ">Please enter Pincode first to set city name
						</mat-error>
						<input  matInput formControlName="city" type="text" placeholder="Enter City Name" />
					</mat-form-field>
				</div>
				<div class="col-sm-12 col-lg-4">
					<mat-form-field class="w-100" appearance="outline">
						<mat-label *ngIf="
              commInfoForm['controls'].state.invalid &&
              commInfoForm['controls'].state.touched
            "></mat-label>
						<input [readonly]="isReadOnly" matInput formControlName="state" type="text" placeholder="Enter State Name" />
						<mat-error *ngIf="
              commInfoForm['controls'].state.invalid &&
              commInfoForm['controls'].state.dirty
            ">
							Please enter Pincode first to set state name
						</mat-error>
					</mat-form-field>
				</div>
			</div>
			<div class="row pb-3">
				<div class="col-sm-12 col-lg-6">
					<mat-slide-toggle class="form-control" (change)="onAddressFlagChange($event)" class="pr-5"
						formControlName="sameAsRegisteredAddress">
					</mat-slide-toggle><label for="sameAsRegisteredAddress">Enable if communication address is
						same</label>
				</div>
			</div>
		</div>
		<h3 class="card-header text-heading mb-0">Communication Address</h3>
		<div class="card-body">
			<div class="row pb-3">
				<div class="col-sm-12 col-lg-6">
					<mat-form-field class="w-100" appearance="outline">
						<input matInput formControlName="comAddressLine1" placeholder="Enter Address Line 1 "
							autocomplete="new-comAddressLine1" />
						<mat-error *ngIf="
              commInfoForm['controls'].comAddressLine1.invalid &&
              commInfoForm['controls'].comAddressLine1.dirty
            ">
							Address line 1 is required
						</mat-error>
					</mat-form-field>
				</div>
				<div class="col-sm-12 col-lg-6">
					<mat-form-field class="w-100" appearance="outline">
						<input matInput formControlName="comAddressLine2" placeholder="Enter Address Line 2"
							autocomplete="new-comAddressLine2" />
						<mat-error *ngIf="
              commInfoForm['controls'].comAddressLine2.invalid &&
              commInfoForm['controls'].comAddressLine2.dirty
            ">
							Address line 2 is required
						</mat-error>
					</mat-form-field>
				</div>
			</div>
			<div class="row pb-3">
				<div class="col-sm-12 col-lg-4">
					<mat-form-field class="w-100" appearance="outline">
						<input (input)="callPincodeAPI('comPostalCode')" matInput formControlName="comPostalCode" type="text"
							maxlength="6" tabindex="5" placeholder="Enter Pincode" autocomplete="new-pinCode" />
						<mat-error *ngIf="
              commInfoForm['controls'].comPostalCode.invalid &&
              commInfoForm['controls'].comPostalCode.dirty
            ">
							Valid 6 digit Pincode is required
						</mat-error>
					</mat-form-field>
				</div>
				<div class="col-sm-12 col-lg-4">
					<mat-form-field class="w-100" appearance="outline">
						<mat-label *ngIf="
              commInfoForm['controls'].comCity.invalid &&
              !commInfoForm['controls'].comCity.touched
            ">Select City
						</mat-label>
						<mat-error *ngIf="
              commInfoForm['controls'].comCity.invalid &&
              commInfoForm['controls'].comCity.touched
            ">Please enter Pincode first to set city name
						</mat-error>
						<input matInput formControlName="comCity" type="text" placeholder="Enter City Name" />
					</mat-form-field>
				</div>
				<div class="col-sm-12 col-lg-4">
					<mat-form-field class="w-100" appearance="outline">
						<mat-label *ngIf="
              commInfoForm['controls'].comState.invalid &&
              commInfoForm['controls'].comState.touched
            "></mat-label>
						<input matInput formControlName="comState" type="text" placeholder="Enter State Name" />
						<mat-error *ngIf="
              commInfoForm['controls'].comState.invalid &&
              commInfoForm['controls'].comState.dirty
            ">
							Please enter Pincode first to set state name
						</mat-error>
					</mat-form-field>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<div class="text-center mt-4 pb-1">
					<button class="btn btn-default btn-real" matStepperPrevious>Back</button>
					<button class="btn btn-default btn-real btn-selected ms-4" (click)="nextStep($event)">Next</button>
				</div>
			</div>
		</div>
	</div>
</form>
