<app-loader *ngIf="isLoading"></app-loader><!--[loaderText]="loadingText"-->
<div class="container container-real-insurance">
	<div class="row">
		<div class="col-12 col-md-4 col-lg-3">
			<app-quote-selection-summary *ngIf="crmObj" [crmObj]="crmObj"></app-quote-selection-summary>
		</div>
		<div class="col-12 col-md-8 col-lg-9">
			<app-proposal-form *ngIf="crmObj" [crmObj]="crmObj" (proposalFromPurchaseButtonClicked)="onProposalFormSubmitted($event)"></app-proposal-form>
		</div>
	</div>

</div>