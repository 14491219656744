<div class="card mt-3">
	<h3 class="card-header text-heading mb-0">Company Info</h3>
	<div class="card-body">
		<form [formGroup]="companyInfoFormGroup" class="bg-white personal-info-form-section">
			<div class="row pb-3">
				<div class="col-sm-12 col-lg-6">
					<mat-form-field class="w-90" appearance="outline">
						<input matInput placeholder="Enter Company Name" formControlName="companyName" maxlength="35"
							autocomplete="new-companyName" />
						<mat-error *ngIf="
              companyInfoFormGroup['controls'].companyName.invalid &&
              companyInfoFormGroup['controls'].companyName.dirty
            ">
							Company Name is Required
						</mat-error>
					</mat-form-field>
				</div>
				<div class="col-sm-12 col-lg-6">
					<mat-form-field class="w-90" appearance="outline">
						<input matInput placeholder="Enter Company GST Number" formControlName="companyGstNo"
							maxlength="18" autocomplete="new-companyGstNo" />
					</mat-form-field>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<div class="text-center pb-1">
						<button class="btn btn-default btn-real" matStepperPrevious>Back</button>
						<button class="btn btn-default btn-real btn-selected ms-4" (click)="nextStep($event)">Next</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>