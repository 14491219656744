import { Component, OnInit, Output, EventEmitter, Input, SimpleChange } from '@angular/core';
import { LoggerService } from 'src/app/core/logger/logger.service';

@Component({
	selector: 'app-idv-selection',
	templateUrl: './idv-selection.component.html',
	styleUrls: ['./idv-selection.component.scss']
})
export class IdvSelectionComponent implements OnInit {
	public idvOption: string = "automatic";
	public isTextBox: boolean = false
	public idvValue: number;
	public minIdv: number;
	public maxIdv: number
	@Input() minIDV: any;
	@Input() maxIDV: any;
	@Input() crmObj;
	@Output() onIdvUpdate = new EventEmitter<any>()

	constructor(
		private logger: LoggerService
	) { }

	ngOnInit(): void {
		this.setIdvOptions();
		this.setMinIdv();
		this.setMaxIdv();
	}

	public setIdvOptions(): void {
		if (this.crmObj.newPolicyInfo.idvType) {
			this.idvOption = this.crmObj.newPolicyInfo.idvType;
		}
	}

	public setMinIdv(): void {
		this.minIdv = this.minIDV;
	}

	public setMaxIdv(): void {
		this.maxIdv = this.maxIDV;
	}

	ngOnChanges(changes: { [property: string]: SimpleChange }) {
		let minChange: SimpleChange = changes['minIDV'];
		if (minChange) {
			this.minIdv = minChange.currentValue;
		}
		let maxChange: SimpleChange = changes['maxIDV'];
		if (maxChange) {
			this.maxIdv = maxChange.currentValue;
		}
	}

	public onIdvSelectionChange(): void {
		if (this.idvOption == "set") {
			this.isTextBox = true;
		} else {
			this.isTextBox = false;
			this.idvValue = 0;
			this.onUpdateIdv();
		}
	}

	public onUpdateIdv(): void {
		this.isTextBox = false;
		const idvIpt = {
			idvType: this.idvOption,
			idv: this.idvValue
		}
		this.onIdvUpdate.emit(idvIpt)
	}
}
