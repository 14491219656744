import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {  FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoggerService } from 'src/app/core/logger/logger.service';

@Component({
  selector: 'app-lead-form',
  templateUrl: './lead-form.component.html',
  styleUrls: ['./lead-form.component.scss']
})
export class LeadFormComponent implements OnInit {
  public isSubmitted = false;
  public isLoading: boolean = true;
  @Input() inputObj
  leadForm: FormGroup;
  @Output() nextButtonClicked =new EventEmitter<object>();

  constructor(
    private logger: LoggerService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.leadForm = this.createFormGroup();
    this.isLoading = false;
  }

  onSubmit(){
    this.isSubmitted = true;
		if (this.leadForm.invalid) {
			return;
		}
    this.nextButtonClicked.emit(this.leadForm.value);
  }

  createFormGroup() {
    return this.formBuilder.group({
			mobile: ["", [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
			email: ["", [Validators.required, Validators.email]],
      pincode: ["", [Validators.required, Validators.pattern(/^[1-9]\d{5}$/)]],
		});
  }

  get form() {
    return this.leadForm.controls;
  }

}
