<div class="row">
    <div class="col-12 col-md-12">
        <div class="ms-md-2" ngClass.lt-sm="mt-2">
            <h4 class="text-heading mb-1">IDV</h4>
            <mat-button-toggle-group (change)="onIdvSelectionChange()" [(ngModel)]="idvOption" appearance="legacy"
                aria-label="IDV">
                <mat-button-toggle value="automatic">Automatic</mat-button-toggle>
                <mat-button-toggle value="set">Set IDV</mat-button-toggle>
            </mat-button-toggle-group>
            <div class="mt-2" *ngIf="isTextBox">
                <div class="input-group">
                    <input type="number" step="1000" [min]="minIdv" [max]="maxIdv" [(ngModel)]="idvValue"
                        class="form-control" placeholder="Enter IDV Value" aria-label="Enter IDV Value"
                        aria-describedby="button-addon2">
                    <button class="btn btn-primary btn-real btn-selected" (click)="onUpdateIdv()" type="button"
                        id="button-addon2">Update</button>
                </div>
                <span class="text-body--sm mt-2 mt-2 d-block">Range {{minIdv | currency: "INR":"symbol"}} and {{maxIdv |
                    currency:
                    "INR":"symbol"}}</span>
            </div>
            <div class="mt-2" *ngIf="!isTextBox && idvOption == 'set'">
                Selected IDV : {{crmObj.newPolicyInfo.idv | currency: "INR":"symbol"}}
                <button class="btn btn-link p-0" (click)="onIdvSelectionChange()">Change IDV</button>
            </div>
        </div>
    </div>
</div>
