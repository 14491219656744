import { Injectable } from '@angular/core';
import { ApiService } from "src/app/core/services/api.service";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: 'root'
})
export class NotificationService {
	private apiBaseUrl = environment.apiBaseUrl;
	private serviceName = environment.serviceName

	constructor(
		private apiService: ApiService
	) { }

	public emailUrl() {
		return `${this.apiBaseUrl}${this.serviceName.sendEmail}`;
	}

	public triggerNotification(payload) {
		return this.apiService.postAPICall(this.emailUrl(), payload);
	}
}
