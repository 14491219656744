<mat-nav-list>
    <a mat-list-item [routerLink]="['/']" (click)="onSidenavClose()">
        <i class="fa fa-home fa-lg" style="padding-right:10px;"></i> <span class="nav-caption">Home</span>
    </a>
    <a mat-list-item routerLink="#" (click)="onSidenavClose()">
        <a><i class="fa fa-phone fa-lg" style="padding-right:10px;"></i></a>
        <span class="nav-caption">
            <a href="callto:+91 9431813129">+91 9431813129</a></span>
    </a>
    <a href="{{b2cEndPoint}}" target="_blank"  mat-list-item (click)="onSidenavClose()">
        <i class="fa fa-sign-in fa-lg pr-10"  aria-hidden="true"></i>
        <span class="nav-caption">POSP Access</span>
    </a>
    <!-- <ng-container *ngIf="user">
        <a [routerLink]="['/']"  mat-list-item (click)="onSidenavClose()">
            <i aria-hidden="true" class="fa fa-user fa-lg pr-10"></i><span class="nav-caption">{{ user.name }}</span>
        </a>
        <a [routerLink]="['/admin']"  mat-list-item (click)="onSidenavClose()">
            <i aria-hidden="true" class="fa fa-tachometer fa-lg pr-10"></i><span class="nav-caption">Dashboard</span>
        </a>
        <a mat-list-item (click)="logout(); onSidenavClose()">
            <i aria-hidden="true" class="fa fa-sign-out fa-lg pr-10"></i><span class="nav-caption">Logout</span>
        </a>
    </ng-container> -->
</mat-nav-list>