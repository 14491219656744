<div class="row">
	<div class="col">
		<div class="card">
			<h3 class="card-header mb-0">Premium Summary</h3>
			<div class="card-body">
				<p class="card-text">
					<span><span class="pr-5">Net Premium: </span>
						<strong>{{ premium.netPremium | currency: "INR":"symbol"}}</strong></span>
				</p>
				<p class="card-text">
					<span><span class="pr-5">Total Tax: </span>
						<strong>{{ premium.totalTax | currency: "INR":"symbol"}}</strong></span>
				</p>
				<p class="card-text">
					<span><span class="pr-5">Payable: </span>
						<strong>{{ premium.grossPremium | currency: "INR":"symbol"}}</strong></span>
				</p>
				<a (click)="showPremiumBreakup(quote)" class="card-link text-cursor">Premium Breakup</a>
			</div>
		</div>
	</div>
</div>
<ng-container #vc></ng-container>
<ng-template #modal>
	<app-real-modal id="ncbModal" class="hhidden">
		<!-- [quote]="premiumBreakUp" -->
		<app-premium-breakup [quote]="quote"></app-premium-breakup>
		<button (click)="closeDialog()" class="close-dialog">x</button>
	</app-real-modal>
</ng-template>