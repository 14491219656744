<app-loader *ngIf="isLoading"></app-loader>
<div class="card">
  <h3 class="card-header text-heading mb-0">Enter Member's Age to Insure</h3>
  <div class="card-body">
    <form (ngSubmit)="onSubmit()" [formGroup]="ageForm">
      <div class="row pb-3">
        <div class="offset-md-2 col-md-8 offset-lg-3 col-lg-6 pb-4 text-center">
          <ul class="list-group list-group-flush" formArrayName="age">
            <li class="list-group-item" *ngFor="let item of getControls();  let ind = index">
              <div class="form-group row" [formGroupName]="ind">
                <label for="select_{{ind}}" class="col-sm-6 text-bold col-form-label">
                  {{item.value.relationship}}
                  Age</label>
                <div class="col-sm-6">
                  <select id="select_{{ind}}" formControlName="age" class="form-select form-control">
                    <option value=null>Select Age</option>
                    <option value="{{ option }}" *ngFor="let option of numberSeq; let j = index">
                      {{option}} years</option>
                  </select>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-12 text-center">
          <p *ngIf="isSubmitted" class="text-bold text-danger">
            Please Enter age for all the members</p>
        </div>
        <div class="col-12 py-4 text-center">
          <!-- <button (click)="goBack()" class="btn btn-default btn-real mx-2">Previous</button> -->
          <button matStepperPrevious class="btn btn-default btn-real mx-2">Previous</button>
          <button class="btn btn-default btn-real btn-selected">Next</button>
        </div>
      </div>
    </form>
  </div>
</div>