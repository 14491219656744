import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { CrmFlowService } from 'src/app/features/services/crm-flow.service';
import { environment } from 'src/environments/environment.default';
import { LeadResponse } from 'src/app/features/interfaces/leadResponse';
import { InsuranceService } from 'src/app/features/services/insurance.service';
import { NotificationService } from 'src/app/features/services/notification.service';

@Component({
	selector: 'app-thank-you',
	templateUrl: './thank-you.component.html',
	styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {

	public isLoading: boolean = true;
	public crmId: string;
	public source: string;
	public crmObj: any;
	public generatedCrmSub = new Subscription();
	public isEMailSent: boolean = false;

	constructor(
		private router: Router,
		private logger: LoggerService,
		private crmFlow: CrmFlowService,
		private insuranceService: InsuranceService,
		private notification: NotificationService,
		private activatedRoute: ActivatedRoute
	) { }

	ngOnInit(): void {
		this.getLeadId();

	}

	public getLeadId(): void {
		this.crmId = this.activatedRoute.snapshot.paramMap.get('leadId');
		this.source = this.activatedRoute.snapshot.paramMap.get('source');
		this.getLeadDetailsFromCrm();
	}

	public getLeadDetailsFromCrm(): void {
		this.generatedCrmSub = this.crmFlow.getLeadDetails(this.crmId).subscribe({
			next: (leadResponse: LeadResponse) => {
        this.crmObj = leadResponse[0];
			},
			error: (error) => {
				this.logger.info(error);
			},
			complete: () => {
				this.isLoading = false;
				this.logger.info("complete");
			}

		})
	}

	public sendEmail(): void {
		this.isLoading = true;

		const requestPayload = {
			crmObj: {
				firstName: this.crmObj.policyHolderInfo.firstName,
				lastName: this.crmObj.policyHolderInfo.lastName,
				productType: this.crmObj.commonFields.productType,
				proposalNo: this.crmObj.newPolicyInfo.proposalNo,
				grossPremium: this.crmObj.paymentInfo.grossPremium,
				netPremium: this.crmObj.paymentInfo.netPremium,
				paymentLink: this.crmObj.extra.paymentLink,
				insuranceProvider: this.crmObj.newPolicyInfo.insuranceProvider
			},
			templateName: environment.emailTemplateType.paymentLink,
			email: this.crmObj.policyHolderInfo.email
		}
		this.notification.triggerNotification(requestPayload)
			.subscribe({
				next: (data) => {
					this.isEMailSent = true;
					this.logger.info(data);
					setTimeout(() => {
						this.clearSessionObj();
					}, 10000)
					this.isLoading = false;
				},
				error: (error) => {
					this.isEMailSent = true;
					this.logger.info(error);
					this.isLoading = false;
					// this.generateQuoteFailed(error, "danger");
				},
				complete: () => {
					this.logger.info("complete");
				}
			});
	}

	ngOnDestroy(): void {
		this.generatedCrmSub.unsubscribe();
	}

	public clearSessionObj(): void {
		this.insuranceService.clearValue("leadReqId");
		this.insuranceService.clearValue("productType");
		this.insuranceService.clearValue("isNewVehicle");
		this.insuranceService.clearValue("vehicleCC");
		this.insuranceService.clearValue("premiumBreakup");
		this.insuranceService.clearValue("vehicleIDV");
		this.insuranceService.clearValue("productSubType");
	}
}
