import { Component, Input, EventEmitter, Output, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { of, throwError } from 'rxjs';
// import { retryWhen, mergeMap, delay, take } from 'rxjs/operators';
import { LoggerService } from 'src/app/core/logger/logger.service';
// import { ApiService } from 'src/app/core/services/api.service';
import {
	policyHolderGenderArr,
	policyHolderMaritalStatusArr
} from "src/app/features/policy-config";
// import { HelperService } from 'src/app/features/services/helper.service';
// import { environment } from 'src/environments/environment.default';

@Component({
	selector: 'app-policy-holder-details',
	templateUrl: './policy-holder-details.component.html',
	styleUrls: ['./policy-holder-details.component.scss']
})
export class PolicyHolderDetailsComponent implements OnInit {
	@Input() crmObj: any;
	personalInfoFormGroup: FormGroup;
	public genderArr: Array<any> = policyHolderGenderArr;
	public maritalStatusArr: Array<any> = policyHolderMaritalStatusArr;
	public ownerDOBLimit = new Date();
  public isReadOnly: boolean = false;
	@Output() nextButtonClicked: EventEmitter<any> = new EventEmitter<any>();

	constructor(
    private _formBuilder: FormBuilder,
    private logger: LoggerService,
    // private apiService: ApiService,
    // private helperService: HelperService
  ) { }

	ngOnInit(): void {
    this.createPolicyHolderForm();
		this.setMinDob();
    this.setReadOnlyFlag();
	}

  setReadOnlyFlag(): void {
    this.isReadOnly = this.crmObj.kyc.isKycCompleted
  }

	ngOnChanges(changes: SimpleChanges) {
      this.crmObj = changes.crmObj.currentValue;
		  this.createPolicyHolderForm();
	}

	public setMinDob(): void {
		this.ownerDOBLimit.setDate(this.ownerDOBLimit.getDate() - ((365 * 18) + 5));
	}

	private createPolicyHolderForm(): void {
		this.personalInfoFormGroup = this._formBuilder.group({
			firstName: [
				this.crmObj.policyHolderInfo.firstName,
				[Validators.required, Validators.pattern("^[a-zA-Z ]{2,30}$")],
			],
			lastName: [
				(this.crmObj.policyHolderInfo.lastName == "dummy") ? "" : this.crmObj.policyHolderInfo.lastName,
				[Validators.required, Validators.pattern("^[a-zA-Z ]{2,30}$")],
			],
			email: [this.crmObj.policyHolderInfo.email, [Validators.required, Validators.email]],
			mobile: [this.crmObj.policyHolderInfo.mobile, [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],
			dob: [this.crmObj.policyHolderInfo.dob, [Validators.required]],
			maritalStatus: [this.crmObj.policyHolderInfo.maritalStatus, [Validators.required]],
			gender: [this.crmObj.policyHolderInfo.gender, Validators.required],
			panNo: [this.crmObj.policyHolderInfo.panNo, [Validators.pattern("^[A-Z]{5}[0-9]{4}[A-Z]$")]],
		});
	}


	public nextStep(evt) {
    if (this.personalInfoFormGroup.invalid) {
			return;
		}
		this.nextButtonClicked.emit(this.personalInfoFormGroup.value);
	}
}
