import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-category-selection-details',
  templateUrl: './category-selection-details.component.html',
  styleUrls: ['./category-selection-details.component.scss']
})
export class CategorySelectionDetailsComponent implements OnInit {
  @Input() motorProfile;
  isEditMode:boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  openEditModalDialog() {

  }

}
