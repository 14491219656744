import { Component, OnInit, ViewChild } from '@angular/core';
import { of, Subscription, throwError } from "rxjs";
import { ApiService } from "src/app/core/services/api.service";
import { Router } from "@angular/router";
import { CrmFlowService } from '../../services/crm-flow.service';
import { LeadResponse } from '../../interfaces/leadResponse';
import { InsuranceService } from '../../services/insurance.service';
import { HelperService } from '../../services/helper.service';
import { policyTypeArr, KYCSTATUS, ownerTypeArr, reviewSection, leadStatus, paymentStatus, PaymentGatewayType } from 'src/app/features/policy-config';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { ErrorMessage } from "../../interfaces/error-message";
import { retryWhen, delay, take, mergeMap } from "rxjs/operators";

@Component({
	selector: 'app-payment',
	templateUrl: './payment.component.html',
	styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
	@ViewChild("paymentInformation", { static: false }) paymentInformation;
	public crmId: string;
	public crmObj: any;
	public pTypeArr: Array<any> = policyTypeArr;
	public oTypeArr: Array<any> = ownerTypeArr;
  public paymentGatewayType:any = PaymentGatewayType;
  public kycStatus: any = KYCSTATUS;
	public isLoading: boolean = true;
	public isPreviousPolicyExists: boolean = true;
	public isTpPolicyFlag: boolean = false;
	public isNomineeFlag: boolean = false;
	public isSubmitted: boolean = false;
	public isEmail: boolean = false;
	public apiReq: any;
	public apiResponse: any;
	public paymentOptions: any;
	disabledAgreement: boolean = true;
	public errorMessage: ErrorMessage = {
		message: null,
		link: null,
		buttonText: null,
		type: null
	};
	public paymentLink: string;
	public paymentForm: any;
	public paymentProcessingType: string;
	public premiumObj: any;
	public newPolicyObj: any;
	public addonsObj: any = {};
	public generatedProposalSub = new Subscription();
	public reviewSectionObj: any = reviewSection;
	public extraObj: any;
	public loadingText: String;
	public shortUrl: string;

	constructor(
		private apiService: ApiService,
		private router: Router,
		private crmFlow: CrmFlowService,
		private insuranceService: InsuranceService,
		private helperService: HelperService,
		private logger: LoggerService,

	) { }

	ngOnInit(): void {
		this.getLeadDetailsFromCrm();
	}

	public getLeadId(): void {
		this.crmId = this.insuranceService.fetchSessionValue("leadReqId");
	}

	public getLeadDetailsFromCrm(): void {
		if (!this.crmId) {
			this.getLeadId();
		}
		this.generatedProposalSub = this.crmFlow.getLeadDetails(this.crmId).subscribe({
			next: (leadResponse: LeadResponse) => {
				this.crmObj = leadResponse[0];
				this.generateProposal();
			},
			error: (error) => {
				this.generateQuoteFailed(error, "warning");
			},
			complete: () => {
				this.logger.info("complete");
			}
		})
	}

	public generateProposal(): void {
		const apiEndPoint = this.helperService.createProposalApiEndPoint(this.crmObj.newPolicyInfo.insuranceProvider, this.crmObj.commonFields.productType);
		// const apiEndPoint = "http://localhost:3000/dev/generate-quote";
		this.apiReq = this.helperService.createQuoteApiPayload(this.crmObj);
		this.apiService
			.postAPICall(apiEndPoint, this.apiReq)
			.pipe(
				retryWhen(
					err => {
						return err.pipe(
							mergeMap((response) => {
								if (response.status === 504) {
									return of(response).pipe(
										delay(500),
										take(3)
									);
								}
								return throwError({ error: response });
							}),
						)
					}
				)
			)
			.subscribe({
				next: (res) => {
					this.generateQuoteResponse(res);
				},
				error: (error) => {
					this.generateQuoteFailed(error, "danger");
				},
				complete: () => {
					// this.isLoading = false;
					this.logger.info("complete");
				}
			});
	}

	public generateQuoteFailed(error, type = "danger") {
		this.isLoading = false;
		this.errorMessage = {
			message: error.error.error.error_message,
			link: '/feature/personal-info',
			buttonText: "Change",
			type: type
		}

	}

	public generateQuoteResponse(apiResponse) {
		this.isLoading = false;
		this.apiResponse = apiResponse;
		this.setPreviousPolicy()
		this.setTPFlag();
		this.setNomineeFlag();
		this.setPremiumBreakupObj();
		this.setNewPolicyInfoObj();
		this.setAddonsObj()
		const requestPayload = {
			proposalId: apiResponse.proposalId,
			systemId: apiResponse.systemId,
			proposalAmount: apiResponse.grossPremium,
			email: apiResponse.personalInfo.email,
			firstName: apiResponse.personalInfo.firstName,
			lastName: apiResponse.personalInfo.lastName,
			mobile: apiResponse.personalInfo.mobile,
			enquiryId: apiResponse.enquiryId,
      panNo: apiResponse.personalInfo.panNo,
      kycId: apiResponse.kyc.kycId
		}
		this.makePayment(requestPayload);
	}

	public setAddonsObj(): void {
		for (const [key, value] of Object.entries(this.apiReq.addons)) {
			if (this.apiResponse.premiumBreakup && typeof this.apiResponse.premiumBreakup[key] != "undefined" && this.apiResponse.premiumBreakup[key] != "0.00") {
				this.addonsObj[key] = this.apiResponse.premiumBreakup[key];
			}
		}
	}

	public setNewPolicyInfoObj(): void {
		this.newPolicyObj = {
			providerName: this.apiResponse.providerName,
			proposalId: this.apiResponse.proposalId,
			enquiryId: this.apiResponse.enquiryId,
			proposalDate: this.apiResponse.proposalDate,
			riskStartDate: this.apiResponse.riskStartDate,
			riskEndDate: this.apiResponse.riskEndDate,
			newPolicyType: this.apiResponse.insuranceType,
			productType: this.apiResponse.productType,
			idv: this.apiResponse.selectedIDV,
			policyNo: this.apiResponse.policyNo,
			policyStatus: paymentStatus.pending
		}
	}

	public setPremiumBreakupObj() {
		this.logger.info(this.apiResponse);
		this.premiumObj = {
			netPremium: this.apiResponse.netPremium ? this.apiResponse.netPremium : this.crmObj.paymentInfo.netPremium,
			totalTax: this.apiResponse.totalTax ? this.apiResponse.totalTax : this.crmObj.paymentInfo.taxAmount,
			grossPremium: this.apiResponse.grossPremium ? this.apiResponse.grossPremium : this.crmObj.paymentInfo.grossPremium,
		}
	}

	public setPreviousPolicy(): void {
		if (this.apiResponse.motorProfile.isNew) {
			this.isPreviousPolicyExists = false;
		} else if (!this.apiResponse.prePolicyInfo.isPreviousPolicy) {
			this.isPreviousPolicyExists = false;
		}
	}

	public setTPFlag(): void {
		if (this.apiResponse.insuranceType == this.pTypeArr[1]) {
			this.isTpPolicyFlag = true;
		}
	}

	public setNomineeFlag(): void {
		if (this.apiResponse.personalInfo.policyholderType == this.oTypeArr[0]) {
			this.isNomineeFlag = true;
		}
	}

	public makePayment(requestPayload: any): void {
		const apiEndPoint = this.helperService.makePaymentApiEndPoint(this.apiResponse.providerName, this.crmObj.commonFields.productType);
		// const apiEndPoint = "http://localhost:3000/dev/make-payment";
		this.apiService
			.postAPICall(apiEndPoint, requestPayload)
			.subscribe({
				next: (data) => {
					this.paymentOptions = data;
					this.generatePaymentButton();
				},
				error: (error) => {
					this.generateQuoteFailed(error, "danger");
				},
				complete: () => {
					this.isLoading = false;
					this.logger.info("complete");
				}
			});
	}

	public generatePaymentButton(): void {
		this.apiResponse["paymentResponse"] = this.paymentOptions;
		this.paymentLink = this.paymentOptions.paymentUrl;
		this.paymentProcessingType = this.paymentOptions.paymentGatewayType;
		this.paymentForm = this.paymentOptions.inputPayload;
	}

	ngOnDestroy(): void {
		this.generatedProposalSub.unsubscribe();
	}

	public changeCheck(event): void {
		this.disabledAgreement = !event.checked;
	}

	onBuyNow(e): void {
		this.logger.info(this.premiumObj);
		setTimeout(() => {
			this.updateCrmObj()
			this.callUpdateLeadApi(this.crmObj, e);
		}, 400);
	}

	updateCrmObj(): void {
		this.crmObj["commonFields"]["leadStatus"] = leadStatus.proposalGenerated;
		this.crmObj["newPolicyInfo"]["proposalNo"] = this.newPolicyObj.proposalId;
		this.crmObj["newPolicyInfo"]["quoteId"] = this.apiResponse.quotationId;
		if (this.newPolicyObj.policyNo) {
			this.crmObj["newPolicyInfo"]["policyNo"] = this.newPolicyObj.policyNo;
		}
		this.crmObj["paymentInfo"]["paymentStatus"] = this.newPolicyObj.policyStatus;
		this.crmObj["extra"]["reqPayload"] = this.apiReq;
		this.crmObj["extra"]["respPayload"] = this.apiResponse;
		if (this.apiResponse.premiumBreakup) {
			this.crmObj["extra"]["premiumBreakup"] = this.apiResponse.premiumBreakup;
			this.crmObj["paymentInfo"]["odPremium"] = this.apiResponse.premiumBreakup.ownDamageCover;
			this.crmObj["paymentInfo"]["tpPremium"] = this.apiResponse.premiumBreakup.thirdPartyCover;
			this.crmObj["paymentInfo"]["paPremium"] = this.apiResponse.premiumBreakup.paCover;
			this.crmObj["paymentInfo"]["addonsPremium"] = "0.00";
			this.crmObj["paymentInfo"]["netPremium"] = this.apiResponse.netPremium;
			this.crmObj["paymentInfo"]["taxAmount"] = this.apiResponse.totalTax;
			this.crmObj["paymentInfo"]["grossPremium"] = this.apiResponse.grossPremium;
		}

		if (this.isEmail) {
			this.crmObj["extra"]["paymentLink"] = this.shortUrl;
			this.crmObj["extra"]["expiryTime"] = "23:59:59";
			this.crmObj["extra"]["proposalExpiryDate"] = this.apiResponse.proposalDate;
			this.crmObj["commonFields"]["leadStatus"] = leadStatus.proposalLinkSent;
			this.crmObj["paymentInfo"]["paymentResponse"] = this.paymentOptions;
		}
	}

	public callUpdateLeadApi(leadPayload, e): void {
		this.isLoading = true;
    leadPayload["modifiedOn"] = this.crmFlow.getCurrentTimestamp();
		this.crmFlow.updateApi(this.crmId, leadPayload)
			.subscribe({
				next: (leadResponse: LeadResponse) => {
					if (!this.isEmail) {
						this.triggerPaymentButton(e);
					} else {
						this.redirectToPaymentThankYou();
					}
				},
				error: (error) => {
					this.generateQuoteFailed(error, "warning");
				},
				complete: () => {
					this.isLoading = false;
					this.logger.info("complete");
				}
			})
	}

	public triggerPaymentButton(e): void {
		setTimeout(() => {
			if (this.paymentProcessingType != this.paymentGatewayType.form) {
				window.open(this.paymentLink, "_self");
			} else {
				this.paymentInformation.submit()
			}
		}, 1000);
		this.isLoading = false;
	}

	public redirectToPaymentThankYou(): void {
		setTimeout(() => {
			this.router.navigate(["/thank-you/" + this.crmId]);
		}, 1000);
	}

	public modifyDetails() {
		// this.router.navigate(this.errorMessage.link);
	}

	public onSendEmail(e): void {
		this.isLoading = true;
		const sortUrlPayload = this.helperService.generateSortUrl(this.crmObj);
		this.apiService.getQuotationLink(sortUrlPayload)
			.subscribe({
				next: (resp) => {
					this.isEmail = true;
					this.shortUrl = resp.sortUrl;
					this.updateCrmObj();
					this.callUpdateLeadApi(this.crmObj, e);
				},
				error: (error) => {
					this.generateQuoteFailed(error, "warning");
				},
				complete: () => {
					this.logger.info("complete");
				}
			})
	}
}
